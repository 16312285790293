/* eslint-disable react/jsx-key */
import React, { useState } from 'react';
import _ from 'lodash';

import {
  Grid,
  Paper,
  Typography,
  Button,
  Divider,
  TextField,
  Menu,
  Box,
  CircularProgress,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { usePhrases } from '@frontend/utils/usePhrases';
import LOADING_PROPS from '@frontend/modules/orders/constants';

const useStyles = makeStyles((theme) => ({
  warningMenu: {
    padding: theme.spacing(2),
    maxWidth: '350px',
  },
  itemBox: {
    height: '100%',
  },
  textFieldWrapper: {
    display: 'flex',
  },
  buttonProgress: {
    color: theme.palette.primary[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const JobHandCount = (props) => {
  const classes = useStyles();
  const { jobTracker, updateHandCount, loadingPropsObject, isTerminal, machineId } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [handCount, setHandCount] = useState();
  const phrases = usePhrases().phrases();

  const handleCloseHandCountWarning = () => {
    setAnchorEl(null);
  };

  const handleChangeHandCount = (e) => {
    if (Number.isNaN(Number(e.target.value))) {
      setHandCount('');
      return;
    }
    setHandCount(e.target.value);
  };

  const handleFocus = (event) => event.target.select();

  const handleUpdateJob = () => {    
    updateHandCount(jobTracker, handCount, machineId);
    setAnchorEl(null);
    setHandCount('');
  };

  const handleSubmitHandCount = (e) => {
    
    if (isTerminal) {
      updateHandCount(jobTracker, handCount, machineId);
      setHandCount('');
    } else {
      setAnchorEl(e.target);
    }
  };

  return (
    <>
      <Box
        component={Paper}
        variant="outlined"
        square
        className={classes.itemBox}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography align="center">
              {phrases.modules.orders.handCount}
            </Typography>
          </Grid>
          <Grid item xs={12} alignContent="center">
            <Grid container>
              <Grid item xs={3} />
              <Grid item xs={6}>
                <TextField
                  disabled={_.isEmpty(jobTracker)}
                  margin="dense"
                  fullWidth
                  inputProps={{ min: 0, style: { textAlign: 'center' } }}
                  id="outlined-basic"
                  label=""
                  onFocus={handleFocus}
                  variant="outlined"
                  value={handCount}
                  onChange={handleChangeHandCount}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={4} />
              <Grid item xs={4}>
                <Button
                  onClick={handleSubmitHandCount}
                  disabled={
                    _.isEmpty(jobTracker) ||
                    loadingPropsObject[LOADING_PROPS.UPDATING_JOB]
                  }
                  size="small"
                  fullWidth
                  style={{ marginBottom: 8 }}
                  color="primary"
                  variant="contained"
                >
                  {isTerminal ? phrases.forms.shared.buttons.add : phrases.forms.shared.buttons.submit}
                  {loadingPropsObject[LOADING_PROPS.UPDATING_JOB] && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {/* Do not show this on Terminal View since the Handcount will just be added */}
      {!isTerminal && <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseHandCountWarning}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          square: true,
          variant: 'outlined',
        }}
      >
        <Grid container spacing={2} className={classes.warningMenu}>
          <Grid item xs={12}>
            <Typography variant="h4" align="center">
              {phrases.modules.orders.warning}
            </Typography>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography>{`${phrases.modules.orders.byClickingSubmit} ${jobTracker.handCount} ${phrases.modules.orders.willBeDeleted}`}</Typography>
            <Typography>
              {phrases.modules.orders.areYouSureOverwriteHandcount}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  onClick={handleUpdateJob}
                >
                  {phrases.modules.orders.yes}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  color="secondary"
                  variant="outlined"
                  onClick={handleCloseHandCountWarning}
                >
                  {phrases.modules.orders.no}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Menu>
      }
    </>
  );
};

export default JobHandCount;
