import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  setMachineInUse,
  setStructureInUse,
  setStructureViewInUse,
  setMachineViewInUse,
} from '@frontend/utils/UIActions';
import { Typography } from '@material-ui/core';

/**
 * Wrapper for the Link component from `react-router-dom`.
 * This component will persist the accessed route as well the objectId that is accessed
 * trought this component into the redux store.
 *
 * Point of this component is to persist the last accessed route and object.
 *
 * This component can be used as a regular Link component, in that case the `to` prop is required.
 */
const CustomLink = (props) => {
  const dispatch = useDispatch();
  const structureViewInUse = useSelector((state) => state.structureViewInUse);
  const machineViewInUse = useSelector((state) => state.machineViewInUse);
  const structureInUse = useSelector((state) => state.structureInUse);
  // const [redirectRoute, setRedirectRoute] = useState();
  const user = useSelector((state) => state.user);
  const {
    history, to, object, id, onClick, staticContext, ...rest
  } = props;
  const getRouteName = () => {
    switch (object) {
      case 'machine': return machineViewInUse || '/machine-overview';
      case 'structure': return structureViewInUse || '/structure-overview';
      default: return user.views.homeView;
    }
  };

  // const getRoute = (route, _id) => {
  //   switch (object) {
  //     case 'machine': return `${route}/${_id}` || `/machine-overview/${_id}`;
  //     case 'structure': return `${route}/${_id}` || `/structure-overview/${_id}`;
  //     default: return user.views.homeView;
  //   }
  // };

  const persistMachineInUse = (_id) => {
    if (_id) {
      dispatch(setMachineInUse(_id));
    } else {
      dispatch(setMachineInUse(user.views.machine.id));
    }
  };

  const persistStructureInuse = (_id) => {
    if (_id) {
      dispatch(setStructureInUse(_id));
    } else {
      dispatch(setStructureInUse(user.views.structure.id));
    }
  };

  const persistObject = (_id) => {
    switch (object) {
      default: return;
      case 'machine': persistMachineInUse(_id); return;
      case 'structure': persistStructureInuse(_id);
    }
  };

  const persistRoute = (route) => {
    switch (object) {
      default: return;
      case 'machine': dispatch(setMachineViewInUse(route));
        return;
      case 'structure': dispatch(setStructureViewInUse(route));
    }
  };

  return (
    <Typography
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      style={{ ...rest.style, cursor: 'pointer' }}
      align="center"
      display="inline"
      onClick={(event) => {
        if (to.startsWith('mailto:')) {
          event.preventDefault();
          window.location = to;
        } 
        else {
          if (id) persistObject(id);
          if (onClick) onClick(event);
          const route = to || getRouteName();
          persistRoute(route);
          let myid = id
          if(object === "structureID"){
            myid = structureInUse;
          }// Open Links using the HTTP Object
          else if(object === "http"){
            window.open(to, '_blank');
            return;
          }
          persistRoute(route);
          // setRedirectRoute(`${route}/${myid}`);
          history.push(`${route}/${myid}`);

          // l if (id) persistObject(id);
          // persistRoute(route);
          // setRedirectRoute(`${route}/${myid}`);
          // history.push(`${route}/${myid}`);
        }
      }}
      onContextMenu={(event) => {
        if (object === 'structure') {
          if (event.button === 2) {
            event.preventDefault();
          }
        }
      }}
      onAuxClick={(event) => {
        if (object === 'structure') {
          if (event.button === 1) {
            event.preventDefault();
          }
        }
      }}
    />
  );
};

CustomLink.propTypes = {
  /**
   * In case that this component is used as regular Link,
   * `to` prop is the same as `to` prop of Link component.
   */
  to: PropTypes.string,
  children: PropTypes.node.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  onClick: PropTypes.func,
  /**
   * Name of the object that is persisted:
   * machine |
   * structure |
   */
  object: PropTypes.string,
  /**
   * Id of the object that is being accessed/persisted.
   */
  id: PropTypes.string,
};

CustomLink.defaultProps = {
  onClick: () => { },
  to: '',
  object: {},
  id: '',
};
export default withRouter(CustomLink);
