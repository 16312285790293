import { usePhrases } from '@frontend/utils/usePhrases';

const useValidateSensorForm = () => {
  const phrases = usePhrases().phrases();
  const validateSensorForm = (values) => {
    const errors = {};
    const sensorType = values.type;

    if (values.sensorId.trim() === '') {
      errors.sensorId = phrases.forms.sensor.errors.sensorIdRequired;
    }

    if (values.alias.trim() === '') {
      errors.alias = phrases.forms.sensor.errors.aliasRequired;
    }

    if (typeof values.isActive === 'undefined') {
      errors.isActive = 'I\'m sorry user, I\'m afraid you can\'t do that.';
    }

    if (!sensorType) {
      errors.sensorType = phrases.forms.sensor.errors.sensorTypeRequired;
    }

    return errors;
  };

  return {
    validateSensorForm,
  };
};

export default useValidateSensorForm;
