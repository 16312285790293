/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid';
import moment from 'moment-timezone';

import { makeStyles } from '@material-ui/core/styles';
import {
  TextField,
  Typography,
  Checkbox,
  useTheme
} from '@material-ui/core';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import useStructureDropdown from './hooks/useStructureDropdown';

const useStyles = makeStyles((theme) => ({
  root: {
    '& div.Mui-Autocomplete-endAdornment': {
      color: 'primary',
    },
    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
      // Default transform is "translate(14px, 20px) scale(1)""
      // This lines up the label with the initial cursor position in the input
      // after changing its padding-left.
      transform: "translate(14px, 11px) scale(1);"
    },
  },
  inputRoot: {
    color: "primary",
    // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      // Default left padding is 6px
      paddingLeft: 26
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "primary"
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "primary"
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.grey
    }
  },
  inputField: {
    width: '100%',
    color: 'primary'
  },
  endAdornment: {
    right: 0,
    top: 'calc(50% - 14px)',
    position: 'absolute',
    fill: "primary",
    color: "primary",
    "& .MuiAutocomplete-popupIndicator": {
      color: "primary",
      fill: "primary"
    },
  },
  whiteColor: {
    color: '#FFFFFF'
  },
  structureName: {
    fontWeight: 550,
  },
  indented: {
    marginLeft: 25,
  },
}));

const StructuresDropdown = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const {
    options,
    value,
    onChange,
    label,
    error,
    required,
    showTimezone,
    customClasses
  } = props;
  const { dropdownOptions } = useStructureDropdown(options);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const structureTitleFormat = (structure) => {
    const structureName = structure.path;
    const arr = structureName.split('/');
    const path = arr.splice(0, arr.length - 1);
    const name = arr[0];
    return (
      <>
        <Typography display="inline" className={classes.indented} color="textSecondary">
          {`${path.join('/')}${path.length < arr.length ? '' : '/'}`}
          <Typography variant="span" color="primary">
            {name}
          </Typography>
          {showTimezone && (
            <Typography style={{ marginLeft: theme.spacing(2)}} variant="span">
              (
              {moment().tz(structure.timezone).format('Z z')}
              )
            </Typography>
          )}
        </Typography>
      </>
    );
  };

  return (
    <div>
      <Autocomplete
        className=''
        id="structure"
        name="structure"
        classes={customClasses ?? classes}
        options={dropdownOptions}
        onChange={onChange}
        value={value}
        disableCloseOnSelect
        groupBy={(option) => option.path.split('/')[0]}
        getOptionLabel={(option) => option.path}
        // renderOption={(option) => structureTitleFormat(option)}
        renderOption={(option, { selected }) => (
          <div key={uuid()}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
              color="primary"
            />
            {structureTitleFormat(option)}
          </div>
        )}
        {...props}
        renderInput={(params) => {
          let inputLabelProps = params.InputLabelProps;
          if(customClasses){
            inputLabelProps.className = inputLabelProps.className + " " + customClasses.whiteColor;
          }
                      
          return (
          <>
            <TextField
              {...params}
              {...props}
              label={label}
              name="structure"
              // className={classes.whiteColor}
              error={!!error}
              helperText={error}
              variant="outlined"
              margin="dense"
              required={required}
            />
          </>
        )}}
      />
    </div>
  );
};

StructuresDropdown.propTypes = {
  options: PropTypes.instanceOf(Array).isRequired,
  value: PropTypes.instanceOf(Object).isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
};

export default StructuresDropdown;
