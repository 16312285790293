import moment from 'moment';
import useSecurity from '@frontend/utils/useSecurity';
import 'moment/locale/de';
import { de } from 'date-fns/locale';

const useDateTimeFormat = () => {
  const { getProfile } = useSecurity();

  const toLocaleNumber = (number) => {
    if(isNaN(number)){
      return "N/A";
    }
    let lang = "de-DE"
    switch(getProfile()?.lang){
      case 'DE': lang = "de-DE"; break;
      case 'EN': lang = "en-GB"; break;
      default: lang = "de-DE"; break;
    }
    if(typeof number === 'string'){
      const parsedNumber = parseFloat(number);
      if(isNaN(parsedNumber)){
        return "--";
      }
      return parsedNumber.toLocaleString(lang);
    }
    return number?.toLocaleString(lang);
  }
  
  const formatTimeWithSeconds = (date) => {
    moment.locale(getProfile().lang);
    const localeTime = moment(date);
    return `${localeTime.format('HH:mm:ss')}`;
  };

  const formatTime = (date) => {
    moment.locale(getProfile().lang);
    const localeTime = moment(date);
    return `${localeTime.format('HH:mm')}`;
  };

  const formatDate = (date, showTime = true) => {
    moment.locale(getProfile().lang);
    const localeDate = moment(date);
    return `${localeDate.format('DD MMMM yyyy')} ${showTime ? localeDate.format('HH:mm') : ''}`;
  };

  const formatHoursMinutesDuration = (minutes) => {
    let h = Math.floor(minutes / 60);
    let m = minutes % 60;
    let days = Math.floor(minutes/1440);
    if(days > 0){
      h = h % 24;
    } 

    if (isNaN(h) || isNaN(m)) return '00:00';

    h = h < 10 ? `0${h}h` : `${h}h`;
    m = Math.round(m) < 10 ? `0${Math.round(m)}m` : `${Math.round(m)}m`;

    return days > 0 ? `${days}d:${h}:${m}`: `${h}:${m}`;
  };

  const getLocale = () => {
    switch(getProfile().lang){
      case "DE": return de;
      default: return null;
    }
  }

  return {
    formatTime,
    formatTimeWithSeconds,
    formatDate,
    formatHoursMinutesDuration,
    getLocale,
    toLocaleNumber,
  };
};

export default useDateTimeFormat;
