import React, { createRef } from 'react';
import { useSelector } from 'react-redux';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { SnackbarProvider } from 'notistack';
import PrivateRoute from './components/PrivateRoute';
import Login from './modules/user/Login';
import Main from './components/Main';
import ErrorBoundary from '@frontend/utils/ErrorBoundary';

const notistackRef = createRef();
const onClickDismiss = (key) => () => {
  notistackRef.current.closeSnackbar(key);
};

export default function App() {
  const paletteType = useSelector((state) => state.paletteType) || 'dark';

  const theme = createMuiTheme({
    palette: {
      type: paletteType,
      primary: {
        // main: '#f67030',
        main: paletteType === 'dark' ? '#f67030' : '#51597B',
        contrastText: '#ffffff',
      },
      secondary: {
        main: '#FE5F55',
        contrastText: '#ffffff',
      },
      error: {
        main: '#e63b2e',
        contrastText: '#ffffff',
      },
      warning: {
        main: '#f3a712',
        contrastText: '#2e343b',
      },
      success: {
        main: '#33673b',
        contrastText: '#ffffff',
      },
      info: {
        main: '#274690',
        contrastText: '#ffffff',
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        maxSnack={5}
        preventDuplicate
        ref={notistackRef}
        action={(key) => (
          <Tooltip title="Dismiss">
            <IconButton
              onClick={onClickDismiss(key)}
            >
              <HighlightOffIcon />
            </IconButton>
          </Tooltip>
        )}
      >
        <ErrorBoundary>
          <DndProvider backend={HTML5Backend}>
            <Router>
              <Switch>
                <Route path="/sign-in"><Login tab={0} /></Route>
                <Route path="/sign-in-terminal"><Login tab={1} /></Route>
                <PrivateRoute path="/" component={Main} />
              </Switch>
            </Router>
          </DndProvider>
        </ErrorBoundary>
      </SnackbarProvider>
    </ThemeProvider>
  );
}
