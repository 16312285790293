/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import {
  Button,
  ListItem,
  Divider,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { usePhrases } from '@frontend/utils/usePhrases';
import QualityReportEditModal from '@frontend/modules/quality/QualityReportEditModal';
import useQualityReports from '@frontend/modules/quality/hooks/useQualityReports';
import AddBoxIcon from '@material-ui/icons/AddBox';

const useStyles = makeStyles((theme) => ({
  popupListItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  listItemButton: {
    float: 'right',
  },
  popupButton: {
    marginTop: theme.spacing(1),
  },
}));

const QualityReport = (props) => {
  const {
    refreshPulse,
    machine,
    mb,
    actualCount,
    popup,
    selectedState,
    setParentModalOpen
  } = props;
  const [qualityReportModalOpen, setQualityReportModalOpen] = useState(false);
  const theme = useTheme();
  const {
    qualityReports,
  } = useQualityReports(machine.id, qualityReportModalOpen);
  const phrases = usePhrases().phrases();
  const classes = useStyles();
  useEffect(() => {
    if (setParentModalOpen) setParentModalOpen(qualityReportModalOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qualityReportModalOpen]);

  return (
    <>
      {!popup ? (
        <Button
          size="small"
          onClick={() => setQualityReportModalOpen(true)}
          className={classes.listItemButton}
          style={mb ? { marginBottom: theme.spacing(1) } : {}}
          variant="outlined"
          disableElevation
          startIcon={<AddBoxIcon />}
        >
          {phrases.modules.quality.qualityReport}
        </Button>
      ) : (
          <>
            <Divider variant="middle" />
            <ListItem dense className={classes.popupListItem}>
              <Button
                size="small"
                onClick={() => {
                  setQualityReportModalOpen(true);
                }}
                className={classes.popupButton}
                fullWidth
                variant="contained"
                disableElevation
              >
                {phrases.modules.quality.qualityReport}
              </Button>
            </ListItem>
          </>
        )}
      {/* Very stupid check. But to explain, for props chaning purposes, first modal is open
      when there are previous quality reports. And the second one when there arent any. */}
      {qualityReports.length ? (
        <QualityReportEditModal
          machine={machine}
          actualCount={actualCount}
          selectedState={selectedState}
          open={qualityReportModalOpen}
          handleCloseModal={() => {
            setQualityReportModalOpen(false);
            if (setParentModalOpen) setParentModalOpen(false);
          }}
          refreshPulse={refreshPulse}
          allQualityReports={qualityReports}
        />
      ) : (
          <QualityReportEditModal
            machine={machine}
            actualCount={actualCount}
            selectedState={selectedState}
            open={qualityReportModalOpen}
            handleCloseModal={() => {
              setQualityReportModalOpen(false);
              if (setParentModalOpen) setParentModalOpen(false);
            }}
            refreshPulse={refreshPulse}
            allQualityReports={qualityReports}
          />
        )}
    </>
  );
};

export default QualityReport;
