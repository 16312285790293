import { useState, useEffect, useCallback } from 'react';
import useApi from '@frontend/utils/useApi';
import socket from '@frontend/utils/useSocket';
import useSecurity from '@frontend/utils/useSecurity';
import _ from 'lodash';
import useAlert from '@frontend/hooks/useAlert';
import LOADING_PROPS from '@frontend/modules/orders/constants';
import {DEFAULT_JOB_ID_FORMAT} from '@frontend/constants';
import { useSelector } from 'react-redux';
import axios from 'axios';

/**
 * This is more or less a state refresh mechanism. Since the Scrap Count in the JobTracker object is determined
 * by the total scrap of all QualityReports in the given timeframe, it needs to update whenever the number of Reports
 * changes. Therefore, the parameter totalReports is handed in.
 * @param {int} totalReports 
 * @returns 
 */
const useJobTracker = (totalReports) => {
  const api = useApi();
  const { getProfile } = useSecurity();
  const { createAlert } = useAlert();
  const [jobTracker, updateJobTracker] = useState({});
  const [machineToEngage, setMachineToEngage] = useState({});
  const [addedHandCount, setAddedHandCount] = useState(0);
  const [loadingPropsObject, setLoadingPropsObject] = useState({});
  const [machineToFetch, setMachineToFetch] = useState({});
  const [structure, setStructure] = useState({});
  const structureInUse = useSelector((state) => state.structureInUse);
  const handleSetLoadingProps = (prop, isLoading) => {
    setLoadingPropsObject({
      ...loadingPropsObject,
      [prop]: isLoading,
    });
  };
  useEffect(() => {
    const cancelAxios = axios.CancelToken.source();
    api(`/api/structures/${structureInUse}?populate=machines structure`, {
      method: 'get',
      cancelToken: cancelAxios.token,
    })
      .then((response) => {
        setStructure(response.data);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [structureInUse]);

  useEffect(
    useCallback(() => {
      socket.on('connect_failed', () => {
        window.location.reload(false);
      });
      socket.on('error', () => {
        window.location.reload(false);
      });
      socket.on('reconnect_failed', () => {
        window.location.reload(false);
      });
      if (socket._callbacks.$JOB_UPDATE === undefined) {
        socket.on('JOB_UPDATE', (data) => {
          updateJobTracker({ ...jobTracker, ...data });
        });
      }
      if (machineToEngage && machineToEngage.id) {
        socket.emit('JOB_SET_LISTENER', {
          job: null,
          machineId: machineToEngage.id,
        });
      }
      return () => {
        socket.emit('JOB_REMOVE_LISTENER', jobTracker);
        socket._callbacks.$JOB_UPDATE = undefined;
      };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socket, machineToEngage, jobTracker]),
    [machineToEngage],
  );

  const _refreshJobTracker = (machineToEngage) =>{
    if (!_.isEmpty(jobTracker.businessJobId)) {
      handleSetLoadingProps(LOADING_PROPS.ALREADY_ENGAGED_JOB_LOADING, true);
      handleSetLoadingProps(LOADING_PROPS.UPDATING_JOB, true)
      api(
        `/api/orders/jobs/business-job-id/${jobTracker.businessJobId}?populate=currentlyEngagedMachines previouslyEngagedMachines order structrue notes intendedMachine product&sensorData=true&qualityReports=true&activationsData=true`,
        {
          method: 'get',
        },
      )
        .then((response) => {
          if (response.status === 200 || response.status === 304) {
            socket.emit('JOB_SET_LISTENER', { 
              job: response.data,
              machineId: machineToEngage.id,
            });
            updateJobTracker(response.data);
            setAddedHandCount(_.last(response.data.activations[machineToEngage.id])?.handCount || 0)
            handleSetLoadingProps(
              LOADING_PROPS.ALREADY_ENGAGED_JOB_LOADING,
              false,
            );
            handleSetLoadingProps(LOADING_PROPS.UPDATING_JOB, false);
          }
        })
        .catch(() => {
          handleSetLoadingProps(
            LOADING_PROPS.ALREADY_ENGAGED_JOB_LOADING,
            false,
          );

          handleSetLoadingProps(LOADING_PROPS.UPDATING_JOB, false);
        });
    } else if (!_.isEmpty(machineToEngage)){
      handleSetLoadingProps(LOADING_PROPS.ALREADY_ENGAGED_JOB_LOADING, true);
      handleSetLoadingProps(LOADING_PROPS.UPDATING_JOB, true);
      api(
        `/api/orders/jobs/intendedMachine/${machineToEngage.id}?populate=currentlyEngagedMachines previouslyEngagedMachines order structrue notes intendedMachine product&sensorData=true&qualityReports=true&activationsData=true`,
        {
          method: 'get',
        },
      )
        .then((response) => {
          if (response.status === 200 || response.status === 304) {
            socket.emit('JOB_SET_LISTENER', { 
              job: response.data,
              machineId: machineToEngage.id,
            });
            updateJobTracker(response.data);
            setAddedHandCount(_.last(response.data.activations[machineToEngage.id])?.handCount || 0)
            handleSetLoadingProps(LOADING_PROPS.UPDATING_JOB, false);
            handleSetLoadingProps(
              LOADING_PROPS.ALREADY_ENGAGED_JOB_LOADING,
              false,
            );
          }
        })
        .catch(() => {
          handleSetLoadingProps(LOADING_PROPS.UPDATING_JOB, false);
          handleSetLoadingProps(
            LOADING_PROPS.ALREADY_ENGAGED_JOB_LOADING,
            false,
          );
        });
    }
  }

  /**
   * Refresh the JobTracker whenever the machine info changes or the number of reports changes.
   */
  useEffect(
    useCallback(() => {
      _refreshJobTracker(machineToEngage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [machineToEngage]),
    [machineToEngage, totalReports],
  );
  
  function canClearScreen() {
    let can = true;
    if (
      jobTracker &&
      jobTracker.currentlyEngagedMachines.find(
        (_machine) => machineToEngage.id === _machine.id,
      )
    )
      can = false;

    return can;
  }

  function canStartJob() {
    let can = true;
    if (
      !_.isEmpty(jobTracker) &&
      jobTracker.currentlyEngagedMachines.find(
        (_machine) => machineToEngage.id === _machine.id,
      )
    ){
      can = false;
    }
    else if (!jobTracker.id) {
      can = false;
    } else if (!machineToEngage.id) {
      can = false;
    } else if (!getProfile().id) {
      can = false;
    }

    return can;
  }
  function canStopJob() {
    let can = true;
    if (
      !_.isEmpty(jobTracker) &&
      !jobTracker.currentlyEngagedMachines.find(
        (_machine) => machineToEngage.id === _machine.id,
      )
    ){
      can = false;
    }
    else if (!jobTracker.id) {
      can = false;
    } else if (!machineToEngage.id) {
      can = false;
    } else if (!getProfile().id) {
      can = false;
    }
    return can;
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const startJob = useCallback(() => {
    handleSetLoadingProps(LOADING_PROPS.STARTING_JOB, true);
    socket.emit('JOB_REMOVE_LISTENER', jobTracker);
    if (!canStartJob()) {
      return;
    }
    api('/api/orders/jobs/start', {
      method: 'post',
      data: {
        machineId: machineToEngage.id,
        jobId: jobTracker.id,
        userId: getProfile().id,
      },
    })
      .then((response) => {
        if (response.status === 200 || response.status === 304) {
          updateJobTracker(response.data);
          socket.emit('JOB_SET_LISTENER', {
            job: response.data,
            machineId: machineToEngage.id,
          });
          _refreshJobTracker(machineToEngage);
          handleSetLoadingProps(LOADING_PROPS.STARTING_JOB, false);
        }
      })
      .catch(() => {
        handleSetLoadingProps(LOADING_PROPS.STARTING_JOB, false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobTracker, socket, machineToEngage, LOADING_PROPS]);

  const stopJob = useCallback((status) => {
    handleSetLoadingProps(LOADING_PROPS.STOPPING_JOB, true);
    if (!canStopJob()) {
      return;
    }
    api('/api/orders/jobs/stop', {
      method: 'post',
      data: {
        machineId: machineToEngage.id,
        jobId: jobTracker.id,
        userId: getProfile().id,
        handCount: addedHandCount,
        completionStatus: status,
      },
    })
      .then((response) => {
        if (response.status === 200 || response.status === 304) {
          if(status === 'COMPLETED'){
            socket.emit('JOB_UPDATE_LISTENER', response.data);
            updateJobTracker(response.data);
          }

          _refreshJobTracker(machineToEngage);
          handleSetLoadingProps(LOADING_PROPS.STOPPING_JOB, false);
        }
      })
      .catch(() => {
        handleSetLoadingProps(LOADING_PROPS.STOPPING_JOB, false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, jobTracker, machineToEngage]);

  /**
   * This function is used to prepend the structure ID to the business job ID in order to make it unique.
   * @param {String} businessJobId Job ID that is not unique
   * @returns Structure ID prepended Business Job ID
   */
  const generateJobID = (businessJobId) => {
    let defaultString = DEFAULT_JOB_ID_FORMAT;
    if (structure?.businessId) {
      defaultString = defaultString.replace("[SID]", structure?.businessId);
      defaultString = defaultString.replace("[JID]", businessJobId);
    } else {
      defaultString = businessJobId;
    }
    return defaultString;
  }

  const fetchJobByBusinessId = useCallback((businessJobId) => {
    handleSetLoadingProps(LOADING_PROPS.FETCHING_JOB, true);
    // Case insensitive
    // nittel-614658002
    
    const structruePrependedBusinessID = generateJobID(businessJobId);
    const lcJob = structruePrependedBusinessID?.toLowerCase();
    api(
      `/api/orders/jobs/business-job-id/${lcJob}?machineId=${machineToFetch.id}&populate=currentlyEngagedMachines previouslyEngagedMachines order structrue notes intendedMachine product&sensorData=true&qualityReports=true&activationsData=true`,
      {
        method: 'get',
      },
    )
      .then((response) => {
        if (response.status === 200 || response.status === 304) {
          if (!_.isEmpty(response.data)) {
            updateJobTracker(response.data);
          }
        }
        handleSetLoadingProps(LOADING_PROPS.FETCHING_JOB, false);
      })
      .catch(() => {
        // Now lets try the lower case job with an capitalized first letter
        const fcJob = capitalizeFirstLetter(lcJob);
        api(
          `/api/orders/jobs/business-job-id/${fcJob}?machineId=${machineToFetch.id}&populate=currentlyEngagedMachines previouslyEngagedMachines order structrue notes intendedMachine product&sensorData=true&qualityReports=true&activationsData=true`,
          {
            method: 'get',
          },
        ).then((response) => {
          if (response.status === 200 || response.status === 304) {
            if (!_.isEmpty(response.data)) {
              updateJobTracker(response.data);
            }
          }
          handleSetLoadingProps(LOADING_PROPS.FETCHING_JOB, false);
        }).catch(() => {
          // Not found for any case sensitivity
          createAlert(
            `Job with ID:${structruePrependedBusinessID} does not exist in the database!`,
            'error',
          );
          handleSetLoadingProps(LOADING_PROPS.FETCHING_JOB, false);
        })
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [machineToFetch, structure]);

  const updateHandCount = useCallback((job, handCount, machineId) => {
    handleSetLoadingProps(LOADING_PROPS.UPDATING_JOB, true);

    job.handCount = parseInt(job.handCount) + parseInt(handCount);
    api(
      '/api/orders/jobs/handCount?populate=currentlyEngagedMachines previouslyEngagedMachines order structrue notes intendedMachine product&activationsData=true',
      {
        method: 'put',
        data: {
          machineId,
          job,
          handCount,
        },
      },
    )
      .then((response) => {
        if (response.status === 200 || response.status === 304) {
          _refreshJobTracker({id: machineId});
        }
      })
      .catch(() => {
        handleSetLoadingProps(LOADING_PROPS.UPDATING_JOB, false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);


  const updateJob = useCallback((job) => {
    handleSetLoadingProps(LOADING_PROPS.UPDATING_JOB, true);
    api(
      '/api/orders/jobs?populate=currentlyEngagedMachines previouslyEngagedMachines order structrue notes intendedMachine product&activationsData=true',
      {
        method: 'put',
        data: {
          ...job,
        },
      },
    )
      .then((response) => {
        if (response.status === 200 || response.status === 304) {
          updateJobTracker({ ...jobTracker, ...response.data });
        }
        handleSetLoadingProps(LOADING_PROPS.UPDATING_JOB, false);
      })
      .catch(() => {
        handleSetLoadingProps(LOADING_PROPS.UPDATING_JOB, false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobTracker]);

  const addNote = useCallback((note) => {
    handleSetLoadingProps(LOADING_PROPS.ADDING_NOTE, true);
    api(
      `/api/orders/jobs/${jobTracker.id}/notes?populate=currentlyEngagedMachines previouslyEngagedMachines order structrue notes intendedMachine product&activationsData=true`,
      {
        method: 'post',
        data: {
          note,
        },
      },
    )
      .then((response) => {
        if (response.status === 200 || response.status === 304) {
          updateJobTracker({ ...jobTracker, ...response.data });
        }
        handleSetLoadingProps(LOADING_PROPS.ADDING_NOTE, false);
      })
      .catch(() => {
        handleSetLoadingProps(LOADING_PROPS.ADDING_NOTE, false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobTracker]);

  const isLastEngagement = useCallback(() => {
    return (
      jobTracker.currentlyEngagedMachines.length === 1 &&
      jobTracker.currentlyEngagedMachines.find(
        (_machine) => _machine.id === machineToEngage.id,
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobTracker, machineToEngage]);

  const clearScreen = () => {
    socket.emit('JOB_REMOVE_LISTENER', jobTracker);
    updateJobTracker({});
  };

  return {
    jobTracker,
    updateJobTracker,
    startJob,
    stopJob,
    machineToEngage,
    setMachineToEngage,
    fetchJobByBusinessId,
    setMachineToFetch,
    canStartJob,
    canStopJob,
    canClearScreen,
    updateJob,
    addNote,
    loadingPropsObject,
    isLastEngagement,
    clearScreen,
    updateHandCount,
    addedHandCount,
  };
};

export default useJobTracker;
