import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import App from './App';
import * as serviceWorker from './serviceWorker';

import allReducres from './utils/allReducers';

import { loadState, saveState } from './utils/localStorage';

const persistedState = loadState();
const store = createStore(
  allReducres,
  persistedState,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);

store.subscribe(() => {
  saveState({
    user: store.getState().user,
    timespanStart: store.getState().timespanStart,
    timespanEnd: store.getState().timespanEnd,
    timespanDuration: store.getState().timespanDuration,
    isRelativeTimespan: store.getState().isRelativeTimespan,
    selectedRelativeTimespan: store.getState().selectedRelativeTimespan,
    selectedTimespanText: store.getState().selectedTimespanText,
    lastPulseUpdate: store.getState().lastPulseUpdate,
    structureInUse: store.getState().structureInUse,
    structureViewInUse: store.getState().structureViewInUse,
    machineInUse: store.getState().machineInUse,
    machineViewInUse: store.getState().machineViewInUse,
    dataLayer: store.getState().dataLayer,
  });
});

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
