import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
} from '@material-ui/core';

import { CUSTOMER_LOGO } from '@frontend/constants';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMore from '@material-ui/icons/ExpandMore';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { toggleDrawer, toggleSelectionDrawer } from '@frontend/utils/UIActions';
import useSecurity from '@frontend/utils/useSecurity';
import UserMenu from '@frontend/modules/user/UserMenu';
import logo from '@frontend/static/logo126_orange.png';
import customerLogo from '@frontend/static/customerLogo.png';
import ThemeToggle from '@frontend/components/ThemeToggle';


const useStyles = makeStyles((theme) => ({
  appBar: {
    background: 'linear-gradient(#2F3759, #51597B)',
    zIndex: theme.zIndex.drawer,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  title: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  homeLink: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textDecoration: 'none',
    color: theme.palette.primary.contrastText,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: theme.spacing(0, 0.5),
    ...theme.mixins.toolbar,
  },
  appbarToolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    ...theme.mixins.toolbar,
  },
  userMenuBtn: {
    borderRadius: '10%',
    padding: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  hamburgerMenuBtn: {
    borderRadius: '10%',
    padding: theme.spacing(1),
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  titleText: {
    fontSize: 23,
    marginLeft: theme.spacing(1),
    color: '#FF7D40',
  },
  boldText: {
    fontWeight: 800,
  },
  lightText: {
    fontWeight: 100,
  },
  selectionDrawerHambMenu: {
    margin: theme.spacing(0),
  },
  customerLogo: {
    margin: 'auto',
    marginLeft: theme.spacing(2),
    alignContent: 'center',
  },
}));

const MainAppbar = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { loggedIn } = useSecurity();
  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState(null);
  const user = useSelector((state) => state.user);
  const { setUser, location, minified } = props;

  const handleToggleNavigationDrawer = (event) => {
    event.stopPropagation();
    dispatch(toggleDrawer());
  };

  const handleToggleSelectionDrawer = (event) => {
    event.stopPropagation();
    dispatch(toggleSelectionDrawer());
  };

  const isUserMenuOpen = () => !!userMenuAnchorEl;

  const handleUserMenu = (event) => {
    setUserMenuAnchorEl(event.currentTarget);
  };

  if (location.pathname.match(/terminal-overview/)) {
    return null;
  }

  // Import the customerLogo dynamically
  
  return (
    <AppBar
      position="fixed"
      className={classes.appBar}
    >
      <Toolbar className={classes.appbarToolbar}>
        {!minified && (
        <IconButton
          aria-label="account of the current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          className={classes.selectionDrawerHambMenu}
          onClick={handleToggleSelectionDrawer}
          color="inherit"
        >
          <MenuIcon />
        </IconButton>)}

        {loggedIn() && (
          <Typography variant="h6" noWrap className={classes.title}>
            <Typography className={classes.homeLink} component={Link} to={minified? window.location.pathname: `${user.views.homeView}`}>
              
              <img src={logo} width="35" alt="lf logo" />
              <span className={classes.titleText}>
                <span className={classes.lightText}>LEAN</span>
                <span className={classes.boldText}>FOCUS</span>
              </span>
              {customerLogo && CUSTOMER_LOGO.enabled &&
                <img src={customerLogo} width="50" alt={CUSTOMER_LOGO.altText} className={classes.customerLogo} />
              }
            </Typography>
          </Typography>
              
        )}

        <ThemeToggle />

        {/* <div>
          <img src={customerLogo} alt="hanwha logo" className={classes.userMenuBtn}/>
        </div> */}
        {/* <div> */}
        {!minified && (<>
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            className={classes.userMenuBtn}
            onClick={handleUserMenu}
            color="inherit"
          >
            <AccountCircle />
            <ExpandMore />
          </IconButton>
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            className={classes.hamburgerMenuBtn}
            onClick={handleToggleNavigationDrawer}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          </>)}
          {loggedIn() && !minified && (
            <UserMenu
              setUser={setUser}
              open={isUserMenuOpen()}
              userMenuAnchorEl={userMenuAnchorEl}
              setUserMenuAnchorEl={setUserMenuAnchorEl}
            />
          )}

        {/* </div> */}

      </Toolbar>

    </AppBar>
  );
};

export default withRouter(MainAppbar);
