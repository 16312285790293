/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import _ from 'lodash';

import {
  Breadcrumbs,
  Link,
  MenuItem,
  Divider,
  Grid,
  Button,
  Popover,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { mdiRobotIndustrial } from '@mdi/js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIndustry } from '@fortawesome/free-solid-svg-icons';
import useSecurity from '@frontend/utils/useSecurity';

import useIcon from '@frontend/hooks/useIcon';
import { MATERIAL_DESIGN_ICON, ROUTES } from '@frontend/constants';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import CustomLink from '@frontend/components/CustomLink';
import { usePhrases } from '@frontend/utils/usePhrases';
import loader from '@frontend/loader/loader';

const useStyles = makeStyles((theme) => ({
  activeBreadcrumb: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 15,
  },
  navBtn: {
    marginLeft: theme.spacing(1.25),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    paddingTop: theme.spacing(0.25),
    paddingBottom: theme.spacing(0),
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.text.secondary,
  },
  divider: {
    marginTop: theme.spacing(0.5),
  },
  container: {
    displat: 'absolute',
  },
  highlighted: {
    fontWeight: 900,
    color: theme.palette.primary.main,
  },
}));

const BreadcrumbsNavigation = (props) => {
  const { selectedStructure, selectedMachine } = props;
  const classes = useStyles();
  const phrases = usePhrases().phrases();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState();
  const [structures, setStructures] = useState([]);
  const [popupStructures, setPopupStructures] = useState([]);
  const [highlightedRoute, setHighlightedRoute] = useState('');
  const { moduleLoaded } = loader();
  const loggedInUser = useSelector((state) => state.user); 
  const { isSystemAdmin } = useSecurity();
  const selectionStructures = useSelector((state) => state.selectionStructures);
  const renderIcon = useIcon();

  useEffect(() => {
    setHighlightedRoute(location.pathname);
  }, [location]);

  const isRouteSame = (_highlightedRoute, route) => {
    const re = new RegExp(route, 'g');
    return _highlightedRoute.match(re);
  };

  const handleClick = (event, structure) => {
    setPopupStructures(structure.structures ? [structure, ...structure.structures] : [structure]);
    event.preventDefault();
    setAnchorEl(event.target);
  };

  const closePopup = () => {
    setAnchorEl(null);
  };

  // First child structure
  const getStructureChildStructures = (structureId, _structures) => {
    let arr = [];
    for (let i = 0; i < _structures.length; i++) {
      if (_structures[i].structure != null && _structures[i].structure.id === structureId) {
        arr = arr.concat(_structures[i]);
      }
    }

    return arr;
  };

  const getStructuresRecursive = (structure, _structures) => {
    const childStructures = getStructureChildStructures(structure.id, _structures);
    if (childStructures && childStructures.length) {
      return {
        ...structure,
        structures: [..._.flatten(childStructures
          .map((s) => getStructuresRecursive(s, _structures)))],
      };
    }
    return structure;
  };

  const prepStructures = (_structures) => {
    if (!_structures) return [];
    return _structures
      .map((structure) => getStructuresRecursive(structure, _structures))
      .filter((structure) => structure);
  };

  useEffect(() => {
    setStructures(prepStructures(selectionStructures));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionStructures]);

  const findParentStructures = (_structures, _selectedStructure) => {
    if (!_selectedStructure.structure) return [];
    return _.flatten(_structures.map((str) => {
      if (str.id === _selectedStructure.structure || str.id === _selectedStructure.structure.id) {
        return [
          ...findParentStructures(_structures, str),
          str,
        ];
      }

      return [];
    })).filter((e) => e);
  };

  const filterStructures = (_structures, _selectedStructure) => {
    if (!_selectedStructure) return [];
    const parentStructures = findParentStructures(_structures, _selectedStructure);
    return [
      ...parentStructures,
      _selectedStructure,
      ...(selectedMachine ? [selectedMachine] : []),
    ];
  };

  const printStructurePath = (_structures, _selectedStructure) => {
    const filteredStructures = filterStructures(_structures, _selectedStructure);
    return filteredStructures.map((structure) => (selectedMachine && selectedMachine.id === structure.id ? (
      <Link
        key={structure.id}
        color="inherit"
        style={{ cursor: 'default' }}
        className={structure.id === selectedMachine.id ? classes.activeBreadcrumb : ''}
      >
        {renderIcon(MATERIAL_DESIGN_ICON, mdiRobotIndustrial, {
          size: 0.5,
          className: classes.icon,
        })}

        {structure.name}
      </Link>
    ) : (
        <Link
          key={structure.id}
          color="inherit"
          style={{ cursor: 'pointer' }}
          className={!selectedMachine && structure.id === selectedStructure.id ? classes.activeBreadcrumb : ''}
          onClick={(e) => handleClick(e, structure)}
        >

          <FontAwesomeIcon icon={faIndustry} className={classes.icon} />
          {structure.name}
        </Link>
      )));
  };

  return (
    <Grid container spacing={0} className={classes.container}>
      <Grid item container direction="row" justify="flex-start" alignContent="flex-end" align-items="flex-end" xs={6}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          maxItems={3}
          itemsAfterCollapse={2}
          aria-label="breadcrumb"
        >
          {printStructurePath(structures, structures.find((_structure) => selectedStructure.id === _structure.id))}
        </Breadcrumbs>
      </Grid>
      <Grid item container direction="row" justify="flex-end" alignContent="flex-end" align-items="flex-end" xs={6}>
        <Button
          disableElevation
          component={CustomLink}
          object="structure"
          id={selectedStructure ? selectedStructure.id : ''}
          to={ROUTES.STRUCTURE_OVERVIEW}
          variant="outlined"
          color={isRouteSame(highlightedRoute, ROUTES.STRUCTURE_OVERVIEW) || isRouteSame(highlightedRoute, ROUTES.MACHINE_OVERVIEW) ? 'primary' : ''}
          size="small"
          className={clsx({
            [classes.navBtn]: true,
            [classes.highlighted]: isRouteSame(highlightedRoute, ROUTES.STRUCTURE_OVERVIEW) || isRouteSame(highlightedRoute, ROUTES.MACHINE_OVERVIEW),
          })}
        >
          {phrases.nav.oee}
        </Button>
        {/* <Button disableElevation component={CustomLink} object="structure" id={selectedStructure ? selectedStructure.id : ''} to={ROUTES.COMPARISON} variant={"outlined"} color={structureViewInUse === ROUTES.COMPARISON ? "primary" : ""} size="small" className={clsx({
          [classes.navBtn]: true,
          [classes.highlighted]: structureViewInUse === ROUTES.COMPARISON
        })}>Comparison</Button> */}
        {moduleLoaded('reporting') && (
          <Button
            disableElevation
            component={CustomLink}
            object="structure"
            id={selectedStructure ? selectedStructure.id : ''}
            to={ROUTES.REPORTING_VIEW}
            variant="outlined"
            color={isRouteSame(highlightedRoute, ROUTES.REPORTING_VIEW) ? 'primary' : ''}
            size="small"
            className={clsx({
              [classes.navBtn]: true,
              [classes.highlighted]: isRouteSame(highlightedRoute, ROUTES.REPORTING_VIEW),
            })}
          >
            {phrases.nav.report}
          </Button>
        )}
        {/* {moduleLoaded('machine_performance') && (
          <Button
            disableElevation
            component={CustomLink}
            object="machine"
            id={selectedStructure ? selectedStructure.id : ''}
            to={ROUTES.MACHINE_PERFORMANCE}
            variant="outlined"
            color={isRouteSame(highlightedRoute, ROUTES.MACHINE_PERFORMANCE) ? 'primary' : ''}
            size="small"
            className={clsx({
              [classes.navBtn]: true,
              [classes.highlighted]: isRouteSame(highlightedRoute, ROUTES.MACHINE_PERFORMANCE),
            })}
          >
            {phrases.nav.machinePerformance}
          </Button>
        )} */}
        {moduleLoaded('advanced_reporting') && isSystemAdmin(loggedInUser) && (
          <Button
            disableElevation
            component={CustomLink}
            object="structure"
            id={selectedStructure ? selectedStructure.id : ''}
            to={ROUTES.ADVANCED_REPORTING_VIEW}
            variant="outlined"
            color={isRouteSame(highlightedRoute, ROUTES.ADVANCED_REPORTING_VIEW) ? 'primary' : ''}
            size="small"
            className={clsx({
              [classes.navBtn]: true,
              [classes.highlighted]: isRouteSame(highlightedRoute, ROUTES.ADVANCED_REPORTING_VIEW),
            })}
          >
            {phrases.nav.advanced_report}
          </Button>
        )}
        {moduleLoaded('schedule') && (
          <Button
            disableElevation
            component={CustomLink}
            object="structure"
            id={selectedStructure ? selectedStructure.id : ''}
            to={ROUTES.SCHEDULES}
            variant="outlined"
            color={isRouteSame(highlightedRoute, ROUTES.SCHEDULES) ? 'primary' : ''}
            size="small"
            className={clsx({
              [classes.navBtn]: true,
              [classes.highlighted]: isRouteSame(highlightedRoute, ROUTES.SCHEDULES),
            })}
          >
            {phrases.nav.schedule}
          </Button>
        )}

        {moduleLoaded('andon-board') && (
          <Button
            disableElevation
            component={CustomLink}
            object="structure"
            id={selectedStructure ? selectedStructure.id : ''}
            to={ROUTES.ANDON_BOARD}
            variant="outlined"
            color={isRouteSame(highlightedRoute, ROUTES.ANDON_BOARD) ? 'primary' : ''}
            size="small"
            className={clsx({
              [classes.navBtn]: true,
              [classes.highlighted]: isRouteSame(highlightedRoute, ROUTES.ANDON_BOARD),
            })}
          >
            {phrases.nav.andon}
          </Button>
        )}

        {moduleLoaded('orders') && (
          <Button
            disableElevation
            component={CustomLink}
            object="structure"
            id={selectedStructure ? selectedStructure.id : ''}
            to={ROUTES.ORDERS}
            variant="outlined"
            color={isRouteSame(highlightedRoute, ROUTES.ORDERS) || isRouteSame(highlightedRoute, ROUTES.JOB) ? 'primary' : ''}
            size="small"
            className={clsx({
              [classes.navBtn]: true,
              [classes.highlighted]: isRouteSame(highlightedRoute, ROUTES.ORDERS) || isRouteSame(highlightedRoute, ROUTES.JOB),
            })}
          >
            {phrases.nav.orders}
          </Button>
        )}

        {moduleLoaded('terminal') && (
          <Button
            disableElevation
            component={CustomLink}
            object="machine"
            id={selectedMachine ? selectedMachine.id : ''}
            to={ROUTES.APP_TERMINAL_OVERVIEW}
            variant="outlined"
            color={isRouteSame(highlightedRoute, ROUTES.APP_TERMINAL_OVERVIEW) ? 'primary' : ''}
            size="small"
            className={clsx({
              [classes.navBtn]: true,
              [classes.highlighted]: isRouteSame(highlightedRoute, ROUTES.APP_TERMINAL_OVERVIEW),
            })}
          >
            {phrases.nav.terminal}
          </Button>
        )}
      </Grid>
      <Grid item xs={12}>
        <Divider className={classes.divider} />
      </Grid>
      <Popover
        id="simple-menu"
        anchorEl={anchorEl}
        MenuListProps={{
          disablePadding: true,
        }}
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        onClose={closePopup}
        PaperProps={{
          square: true,
          variant: 'outlined',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {popupStructures.map((structure, i) => (
          <>
            <MenuItem
              dense
              component={CustomLink}
              id={structure.id}
              object="structure"
              key={structure.id}
              onClick={closePopup}
            >
              {structure.name}
            </MenuItem>
            {i === 0 && <Divider />}
          </>
        ))}
      </Popover>
    </Grid>
  );
};

export default BreadcrumbsNavigation;
