import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import Slidedown from '@frontend/components/Slidedown';
import useIcon from '@frontend/hooks/useIcon';
import CustomLink from '@frontend/components/CustomLink';

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
  activeListItem: {
    color: theme.palette.primary.main,
    fontWeight: 900,
  },
}));

const NavigationDrawerItem = (props) => {
  const {
    id,
    name,
    icon,
    iconType,
    data,
    openListDropdown,
    handleSideMenu,
    handleCloseSideMenu,
    toggleSidebarDropdown,
    sideMenuOpen,
    hadleCloseDrawer,
  } = props;

  const classes = useStyles();

  const drawerOpen = useSelector((state) => state.drawerOpen);
  const renderIcon = useIcon();

  return (
    <>
      <ListItem
        id={id}
        button
        className={clsx({ [classes.activeListItem]: openListDropdown[id] })}
        onMouseEnter={handleSideMenu}
        onMouseLeave={handleCloseSideMenu}
        onClick={toggleSidebarDropdown}
      >
        <ListItemIcon>
          {renderIcon(iconType, icon)}
        </ListItemIcon>
        <ListItemText primary={drawerOpen ? name : ''} />
        {openListDropdown[id] ? <ExpandLess /> : <ExpandMore />}
        <Slidedown
          data={data}
          close={handleCloseSideMenu}
          open={sideMenuOpen[id]}
        />
      </ListItem>
      <Collapse in={openListDropdown[id]} timeout="auto" unmountOnExit>
        <List component="div" className={classes.nestedMenu} disablePadding>
          {data.map((item) => (
            <ListItem
              key={item.text}
              button
              component={drawerOpen ? CustomLink : undefined}
              to={item.to}
              object={item.object}
              className={classes.nested}
              onClick={hadleCloseDrawer}
            >
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  );
};

NavigationDrawerItem.propTypes = {
  id: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Object)
  ]).isRequired,
  data: PropTypes.instanceOf(Array).isRequired,
  openListDropdown: PropTypes.instanceOf(Object).isRequired,
  handleSideMenu: PropTypes.func.isRequired,
  handleCloseSideMenu: PropTypes.func.isRequired,
  toggleSidebarDropdown: PropTypes.func.isRequired,
  sideMenuOpen: PropTypes.instanceOf(Object).isRequired,
  hadleCloseDrawer: PropTypes.func.isRequired,
};

export default NavigationDrawerItem;
