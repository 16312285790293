import React from 'react';
import {
  makeStyles,
} from '@material-ui/core/styles';

import Modal                  from '@frontend/components/Modal';
import StyledSwitch           from '@frontend/components/StyledSwitch'; 
import { usePhrases }         from '@frontend/utils/usePhrases';
import PartsChartModalContent from '@frontend/modules/machine/PartsChartModalContent';
const useStyles = makeStyles((theme) => ({
    
  }));
const PartsChartModal = (props) => {

    const {
        machineId,
        modalOpen,
        targetCycleTime,
        setModalOpen 
    } = props;
    
    const [isPartsPerMinute, setIsPartsPerMinute] = React.useState(true);
    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleSetIsPartsPerMinute = (event) => {
        setIsPartsPerMinute(event.target.checked);
    }
    const classes = useStyles();
    const phrases = usePhrases().phrases();
    return ( 
        <Modal
            modalTitle={phrases.modules.performance.chartPartsTitle}
            open={
                modalOpen
            }
            className={classes.statusesModal}
            handleClose={handleCloseModal}
            maxWidth="lg"
            content={(
                
                <>
                <StyledSwitch
                    name="isPartsPerHour"
                    checked={isPartsPerMinute}
                    onChange={handleSetIsPartsPerMinute}
                    value={isPartsPerMinute}
                    onLabel={phrases.modules.performance.chartPartsPartsMin}
                    offLabel={phrases.modules.performance.chartPartsSecPart}
                />
                <PartsChartModalContent
                    machineId = {machineId}
                    fullyFeatured = {2}
                    isPartsPerMinute = {isPartsPerMinute}
                    targetCycleTime = {targetCycleTime}
                />
                </>
                               
            )}
        />);
}
 
export default PartsChartModal;