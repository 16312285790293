import { useEffect, useState } from 'react';
import socket from '@frontend/utils/useSocket';
import axios from 'axios';
import useApi from '@frontend/utils/useApi';
import _ from 'lodash';

const useSimpleMachine = (machineId) => {
    const [shiftKPIs, setShiftKPIs] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [partsProduced, setPartsProduced] = useState(0);      // Total number of parts produced in the current shift
    const [currentSpeed, setCurrentSpeed] = useState(0);        // Last value of the aggregated Results in the Chart
    const [machineConfig, setMachineConfig] = useState({});
    const [aggregatedCurrentSpeed, setAggregatedCurrentSpeed] = useState(0);  // Last mean speed value aggregated over the last x minutes
    const api = useApi();  

    const refreshPartsInfo = (from, to, aggregate=5) => {
      setIsLoading(true);
      const cancelAxios = axios.CancelToken.source();
      api(`/api/performance/parts-detailed?machine=${machineId}&from=${from}&to=${to}&noAdapt=true&aggregate=${aggregate}`, {
        method: 'get',
        cancelToken: cancelAxios.token,
      })
        .then((response) => {
          setCurrentSpeed(_.last(response.data.aggregatedResults).actualCount);   // Last value of the aggregated Results in the Chart
          setPartsProduced(response.data.totalParts);
          setAggregatedCurrentSpeed(response.data.currentSpeed);
          setIsLoading(false);
        })
        .catch((error) => {
          if (!axios.isCancel(error)) console.log(error);
          setIsLoading(false);
        });
    };  

    const refreshData = () => {
      setIsLoading(true);
      const cancelAxios = axios.CancelToken.source();
      api(`/api/machine_shift/currentShiftKPIs?machineId=${machineId}`, {
        method: 'get',
        cancelToken: cancelAxios.token,
      })
        .then((response) => {
          setMachineConfig(response.data.config);
          setShiftKPIs(response.data);
          refreshPartsInfo(response.data.startTimeMoment, response.data.endTimeMoment, response.data.config?.sm2Aggregate);
          setIsLoading(false);
        })
        .catch((error) => {
          if (!axios.isCancel(error)) console.log(error);
          setIsLoading(false);
        });
    };
    
    useEffect(() => { 
      if(socket._callbacks["$PARTS_UPDATE"] === undefined) {
        socket.emit("PARTS_SET_LISTENER", {machines: [machineId]});
        socket.on('PARTS_UPDATE', (data) => {
          refreshData();
        });
      };
      refreshData();
      return () =>{
        socket._callbacks["$PARTS_UPDATE"] = undefined;
        socket.emit("PARTS_REMOVE_LISTENER");

      }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return {
        shiftKPIs,
        partsProduced,
        isLoading,
        currentSpeed,
        machineConfig,
        aggregatedCurrentSpeed,
        // shiftTargetParts
        
    };
}
 
export default useSimpleMachine;