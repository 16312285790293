const placeholderMachine = {
  name: '',
  businessId: '',
  placeNumber: '',
  manufacturer: '',
  alias: '',
  description: '',
  isActive: false,
  config: {},
  machineKPI: {
    availability: '0%',
    runtime: 0,
    downtime: 0,
    running: false,
  },
  structure: null,
  type: null,
};

export const machinesReducer = (state = [], action) => {
  switch (action.type) {
    case 'STORE_LIST_MACHINES':
      return action.machines;
    default:
      return state;
  }
};

export const singleMachineReducer = (state = placeholderMachine, action) => {
  switch (action.type) {
    case 'STORE_SINGLE_MACHINE':
      return action.machine;
    default:
      return state;
  }
};

export const machineStatesReducer = (state = [], action) => {
  switch (action.type) {
    case 'STORE_MACHINE_STATES':
      return action.machineStates;
    default:
      return state;
  }
};
