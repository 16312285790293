/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  Drawer,
  List,
  Typography,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faIndustry,
  faHome,
  faMicrochip,
  faUsers,
  faSignOutAlt,
  faCog,
  faColumns,
  faFileExport,
  faExclamationTriangle,
  faBusinessTime,
  faCalendarAlt,
  faEye,
  faTrashAlt,
  faReceipt,
  faTabletAlt,
  faQuestionCircle,
  faEnvelopeOpenText,
  faGlobe,
  faCodeBranch
} from '@fortawesome/free-solid-svg-icons';
import Slidedown from '@frontend/components/Slidedown';
import { ROUTES } from '@frontend/constants';
import loader from '@frontend/loader/loader';
import { removeUser } from '@frontend/modules/user/actions';
import useSecurity from '@frontend/utils/useSecurity';
import { closeDrawer, toggleDrawer } from '@frontend/utils/UIActions';
import { usePhrases } from '@frontend/utils/usePhrases';
import useConditionalArrayElement from '@frontend/utils/useConditionalArrayElement';
import NavigationDrawerItem from '@frontend/components/NavigationDrawerItem';

const drawerWidth = 240;
const closedDrawerWidth = 56;

const useStyles = makeStyles((theme) => ({
  drawer: {
    minWidth: drawerWidth,
    flexShrink: 0,
    position: 'absolute',
    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
    },
  },
  drawerOpen: {
    zIndex: theme.zIndex.appBar - 1,
    marginTop: theme.spacing(8),
    boxShadow: '-5px 8px 19px 0px rgba(0,0,0,0.22)',
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(7),
      overflowY: 'visible',
    },
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: 150,
    }),
  },
  drawerClose: {
    zIndex: theme.zIndex.appBar - 1,
    marginTop: theme.spacing(8),
    boxShadow: '-5px 1px 10px 0px rgba(0,0,0,0.05)',
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(7),
      width: 0,
    },
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: 150,
    }),
    overflow: 'visible',
    width: closedDrawerWidth,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    justifyContent: 'flex-start',
    padding: theme.spacing(0, 0.5),
    ...theme.mixins.toolbar,
  },
  collapseDrawerBtn: {
    borderRadius: '10%',
    padding: theme.spacing(1),
  },
  activeListItem: {
    color: theme.palette.primary.main,
    fontWeight: 900,
  },
  blackened: {
    width: 'calc(100% - 240px)',
    zIndex: theme.zIndex.appBar - 1,
    background: 'rgba(0,0,0,0.3)',
  },
}));

const NavigationDrawer = (props) => {
  const classes = useStyles();
  const phrases = usePhrases().phrases();
  const dispatch = useDispatch();
  const loggedInUser = useSelector((state) => state.user); 
  const selectedStructure = useSelector((state) => state.structure);
  const [openListDropdown, setOpenListDropdown] = React.useState({});
  const [sideMenuOpen, setSideMenuOpen] = React.useState({});
  const { moduleLoaded } = loader();
  const { logout, getProfile, canAccessResource, isSystemAdmin } = useSecurity();
  const { conditionalElement } = useConditionalArrayElement();
  const open = useSelector((state) => state.drawerOpen);
  const { location } = props;

  const handleSideMenu = (event) => {
    const target = event.currentTarget.id;
    const temp = {};
    if (!open) {
      temp[target] = !sideMenuOpen[target];
      setSideMenuOpen(temp);
    }
  };

  useEffect(() => {
    if (!open) setOpenListDropdown({});
  }, [open]);

  const handleCloseSideMenu = () => {
    setSideMenuOpen({});
  };

  const handleToggleDrawer = (e) => {
    e.stopPropagation();
    setOpenListDropdown({});
    dispatch(toggleDrawer());
  };

  const hadleCloseDrawer = (e) => {
    e.stopPropagation();
    setOpenListDropdown({});
    dispatch(closeDrawer());
  };

  const handleLogout = () => {
    logout();
    dispatch(removeUser());
  };

  const toggleSidebarDropdown = (e) => {
    const target = e.currentTarget.id;
    const temp = {};
    if (open) {
      temp[target] = !openListDropdown[target];
      setOpenListDropdown(temp);
    } else {
      handleCloseSideMenu();
    }
  };

  if (location.pathname.match(/terminal-overview/)) {
    return null;
  }

  return (

    <Drawer
      variant="permanent"
      anchor="right"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >

      <List disablePadding>
        <ListItem button onClick={handleToggleDrawer} className={classes.toolbar}>
          <IconButton onClick={handleToggleDrawer}>
            {open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
          <Typography noWrap color="textSecondary">
            {phrases.nav.collapseSidebar}
          </Typography>
        </ListItem>
        <Divider />
        <ListItem button component={Link} to={`${getProfile().views.homeView}`}>
          <ListItemIcon>
            <FontAwesomeIcon
              icon={faHome}
              size="lg"
            />
          </ListItemIcon>
          <ListItemText primary={phrases.nav.home} />
        </ListItem>
        {}
        <NavigationDrawerItem
          id="views-dropdown"
          name={phrases.nav.views}
          icon={faEye}
          data={[
              { text: phrases.nav.oee, to: ROUTES.STRUCTURE_OVERVIEW, icon: faEye, object: 'structure' },
            ...conditionalElement(moduleLoaded('reporting'), { text: phrases.nav.report, to: ROUTES.REPORTING_VIEW, 
              id: selectedStructure ? selectedStructure.id : '', icon: faFileExport, object: 'structureID' }),
            ...conditionalElement(isSystemAdmin(loggedInUser), { text: phrases.nav.advanced_report, to: ROUTES.ADVANCED_REPORTING_VIEW, icon: faEye, object: 'structure' }),
            ...conditionalElement(moduleLoaded('schedule'), { text: phrases.nav.schedule, to: ROUTES.SCHEDULES, icon: faCalendarAlt, object: 'structure' }),
            ...conditionalElement(moduleLoaded('andon-board'), { text: phrases.nav.andon, to: ROUTES.ANDON_BOARD, icon: faColumns, object: 'structure' }),
            ...conditionalElement(moduleLoaded('orders'), { text: phrases.nav.orders, to: ROUTES.ORDERS, icon: faReceipt, object: 'structure' }),
            ...conditionalElement(moduleLoaded('terminal'), { text: 'Terminal', to: ROUTES.APP_TERMINAL_OVERVIEW, icon: faTabletAlt, object: 'machine' }),
            // ...conditionalElement(moduleLoaded('machine_performance'), { text: phrases.nav.machinePerformance, to: ROUTES.MACHINE_PERFORMANCE, id: selectedStructure ? selectedStructure.id : '', icon: faTabletAlt, object: 'structureID' }),
            // { text: 'Comparison', to: ROUTES.COMPARISON, icon: faChartLine, object: 'structure' },
          ]}
          openListDropdown={openListDropdown}
          handleSideMenu={handleSideMenu}
          handleCloseSideMenu={handleCloseSideMenu}
          toggleSidebarDropdown={toggleSidebarDropdown}
          sideMenuOpen={sideMenuOpen}
          hadleCloseDrawer={hadleCloseDrawer}
        />

        <NavigationDrawerItem
          id="management-dropdown"
          name={phrases.nav.management}
          icon={faCog}
          data={[
            {
              text: `${phrases.nav.manageStructures} - ${phrases.nav.manageMachines}`, to: ROUTES.STRUCTURES_MANAGEMENT, icon: faIndustry,
            },
            ...conditionalElement(canAccessResource('reasons') || canAccessResource('machine_statuses'),
              {
                text: `${phrases.nav.machineStatusesManagement}${moduleLoaded('reasons') ? ` - ${phrases.nav.reasonsManagement}` : ''}`, to: moduleLoaded('reasons') ? ROUTES.REASONS_MANAGEMENT : ROUTES.MACHINE_STATUSES_MANAGEMENT, icon: faExclamationTriangle,
              }),
            ...conditionalElement(moduleLoaded('schedule') && canAccessResource('schedules'), {
              text: phrases.modules.schedule.schedules, to: ROUTES.SCHEDULE_MANAGEMENT, icon: faCalendarAlt,
            }),
            ...conditionalElement(moduleLoaded('machine_shift') && canAccessResource('machine_shift'), {
              text: phrases.modules.machine_shift.shifts, to: ROUTES.MACHINE_SHIFT_MANAGEMENT, icon: faBusinessTime,
            }),
            ...conditionalElement(moduleLoaded('quality') && canAccessResource('scrap-types'), {
              text: phrases.modules.quality.scrapTypes, to: ROUTES.SCRAP_TYPES_MANAGEMENT, icon: faTrashAlt,
            }),
            ...conditionalElement(moduleLoaded('orders') && canAccessResource('orders'), {
              text: phrases.nav.jobs, to: ROUTES.JOB_MANAGEMENT, icon: faReceipt,
            }),
            ...conditionalElement(canAccessResource('sensors') || canAccessResource('sensor-types'), {
              text: phrases.nav.sensors, to: ROUTES.SENSORS_MANAGEMENT, icon: faMicrochip,
            }),
            ...conditionalElement(canAccessResource('users') || canAccessResource('roles'), {
              text: phrases.nav.users, to: ROUTES.USERS_MANAGEMENT, icon: faUsers,
            }),
            ...conditionalElement(moduleLoaded('info'), { text: 'System Info', to: ROUTES.INFO, icon: faCodeBranch, object: 'machine' }),
            
          ]}
          openListDropdown={openListDropdown}
          handleSideMenu={handleSideMenu}
          handleCloseSideMenu={handleCloseSideMenu}
          toggleSidebarDropdown={toggleSidebarDropdown}
          sideMenuOpen={sideMenuOpen}
          hadleCloseDrawer={hadleCloseDrawer}
        />

        <NavigationDrawerItem
          id="help-dropdown"
          name={phrases.nav.help}
          icon={faQuestionCircle}
          data={[
            {
              text: phrases.nav.supportTicket, to: 'mailto:support@leanfocus.de?subject=LEANFOCUS%20Support%20Ticket', icon: faEnvelopeOpenText,
            },
            {
              text: phrases.nav.supportPage, to: 'https://support.leanfocus.de/help', icon: faGlobe, object: "http"
            },
          ]}
          openListDropdown={openListDropdown}
          handleSideMenu={handleSideMenu}
          handleCloseSideMenu={handleCloseSideMenu}
          toggleSidebarDropdown={toggleSidebarDropdown}
          sideMenuOpen={sideMenuOpen}
          hadleCloseDrawer={hadleCloseDrawer}
        />

      </List>
      <Divider />
      <List disablePadding>
        <ListItem
          button
          id="logout"
          onClick={handleLogout}
          onMouseEnter={handleSideMenu}
          onMouseLeave={handleCloseSideMenu}
        >
          <ListItemIcon>
            <FontAwesomeIcon
              icon={faSignOutAlt}
              size="lg"
            />
          </ListItemIcon>
          <ListItemText primary={phrases.nav.logout} />
          <Slidedown
            data={[
              { text: phrases.nav.logout, to: '', icon: faSignOutAlt },
            ]}
            close={handleCloseSideMenu}
            open={sideMenuOpen.logout}
          />
        </ListItem>
      </List>
    </Drawer>
  );
};

export default withRouter(NavigationDrawer);
