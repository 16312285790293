import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useApi from '@frontend/utils/useApi';
import { setMachineStatuses, setReasonsObject, setDataLayer, setScrapTypesObject } from '@frontend/utils/UIActions';
import loader from '@frontend/loader/loader';
import { usePhrases } from '@frontend/utils/usePhrases';

import useDateTimeFormat from '@frontend/utils/useDateTimeFormat';

let cancel1;
let cancel2;
let cancel3;

const useStatesFilter = () => {
  const machineStatusesObject = useSelector((state) => state.machineStatuses);
  const reasonsObject = useSelector((state) => state.reasons);
  const scrapTypes = useSelector((state) => state.scrapTypes);
  const api = useApi();
  const dispatch = useDispatch();
  const { moduleLoaded } = loader();
  const phrases = usePhrases().phrases();
  const { formatDate } = useDateTimeFormat();

  const getGroupedStateColor = (state) => {
    if(state.oee > 80){
      return "#388e3c";      
    } else if(state.oee > 60){
      return "#889e4f";
    } else if(state.oee > 40){
      return "#e7b416";
    } else if(state.oee > 20){
      return "#db7b2b";
    } else if(state.oee > 0){
      return "#d32f2f";
    } else {
      return "#999999";
    }
  }

  const setDefaultDataLayer = () => {
    dispatch(setDataLayer({
      module: 'machines',
      dataLayer: 'presentation',
    }));
  };

  useEffect(() => {
    return () => {
      cancel1 && cancel1();
      cancel2 && cancel3();
      cancel3 && cancel2();
      cancel1 = undefined;
      cancel2 = undefined;
      cancel3 = undefined;
    }
  }, []);

  const loadMachineStatuses = () => {
    // cancel1 && cancel1();
    api('/api/machines/machine-statuses/object?mongoQuery={"dataLayer":"presentation"}', {
      method: 'get',
      // cancelToken: new CancelToken(function executor(c) {
      //   cancel1 = c;
      // }),
    })
      .then((response) => {
        dispatch(setMachineStatuses(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const loadReasons = () => {
    // cancel2 && cancel2();
    api('/api/reasons/object', {
      method: 'get',
      // cancelToken: new CancelToken(function executor(c) {
      //   cancel2 = c;
      // }),
    })
      .then((response) => {
        dispatch(setReasonsObject(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const loadScrapTypes = () => {
    // cancel3 && cancel3();
    api('/api/quality/scrap-types/object', {
      method: 'get',
      // cancelToken: new CancelToken(function executor(c) {
      //   cancel3 = c;
      // }),
    })
      .then((response) => {
        dispatch(setScrapTypesObject(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const initialSetup = () => {
    setDefaultDataLayer();
    loadMachineStatuses();
    loadReasons();
    loadScrapTypes();
  };

  const stateHasReason = (state) => {
    if (!moduleLoaded('reasons')) return false;
    if (state['presentation.reason']) return true;

    return false;
  };

  const hasAnyWaste = (state) => {
    if (!state.state || !scrapTypes) return false;
    const scrapTypesArr = Object.keys(scrapTypes);
    for (let i = 0; i < scrapTypesArr.length; i++) {
      if (state.state[`presentation.quality.${scrapTypesArr[i]}.wasteCount`] ||
        state.state[`presentation.quality.${scrapTypesArr[i]}.wasteCount`] === 0) return true;
    }
    return false;
  };

  const hasScrapType = (state, scrapType) => {
    if (state.state[`presentation.quality.${scrapType}.wasteCount`] === 0) return true;

    return !!state.state[`presentation.quality.${scrapType}.wasteCount`];
  };

  const wasteCount = (state) => {
    if (!hasAnyWaste(state)) return 0;
    const scrapTypesArr = Object.keys(scrapTypes);
    const count = scrapTypesArr.reduce((accumulator, scrapType) => {
      const value = hasScrapType(state, scrapType) ? state.state[`presentation.quality.${scrapType}.wasteCount`] : 0;

      if (value)
        return accumulator + value;

      return accumulator;
    }, 0);

    return count;
  };

  const totalWasteCount = (state) => {
    return state?.state[`presentation.quality.wasteCount`] || 0;

  };

  const wasteCountForType = (state, scrapType) => {
    if (!hasAnyWaste(state)) return 0;

    return state.state[`presentation.quality.${scrapType}.wasteCount`];
  };

  const scrapTypeNote = (state, scrapType) => {
    return state.state[`presentation.quality.${scrapType}.note`];
  };

  const scrapTypeProductionPeriod = (state, scrapType) => {
    return `${formatDate(state.state[`presentation.quality.${scrapType}.startTime`])} - ${formatDate(state.state[`presentation.quality.${scrapType}.endTime`])}`;
  };

  const scrapTypeText = (identifier) => {
    return phrases.scrapTypesText[identifier];
  };

  /**
   * Returns correct color to represent passed in state.
   *
   * state param can be either State object or state short name(String)
   *
   * @param {String || Object} reason
   */
  const reasonColor = (state) => {
    if (!state) return '';
    if (typeof state === 'string') {
      if (!reasonsObject[state]) return '#AAAAAA';
      return reasonsObject[state].color;
    }
    if (state['presentation.reason']) {
      const reasonIdentifier = state['presentation.reason'];
      if (!reasonsObject[reasonIdentifier]) return '#AAAAAA';
      return reasonsObject[reasonIdentifier].color;
    }

    return '';
  };

  /**
   * Returns correct text to represent passed in state.
   *
   * state param can be either State object or state short name(String)
   *
   * @param {String || Object} state
   */
  const reasonText = (state) => {
    if (!state) return '';
    if (typeof state === 'string') {
      if (!reasonsObject[state]) return '';
      if (phrases.reasonsText[state]) return phrases.reasonsText[state];
      return reasonsObject[state].name;
    }
    if (state['presentation.reason']) {
      const reasonIdentifier = state['presentation.reason'];
      if (!reasonsObject[reasonIdentifier]) return '';
      if (phrases.reasonsText[reasonIdentifier]) return phrases.reasonsText[reasonIdentifier];
      return reasonsObject[reasonIdentifier].name;
    }

    return '';
  };

  /**
   * Returns correct color to represent passed in state.
   *
   * state param can be either State object or state short name(String)
   *
   * @param {String || Object} state
   */
  const stateColor = (state, includeReason) => {
    if (!state) return '';
    if (typeof state === 'string') {
      if (!machineStatusesObject[state]) return '#AAAAAA';
      return machineStatusesObject[state].color;
    }
    if (stateHasReason(state) && includeReason) return reasonColor(state);
    if (state['presentation.status']) {
      const stateName = state['presentation.status'];
      if (!machineStatusesObject[stateName]) return '#AAAAAA';
      return machineStatusesObject[stateName].color;
    }
    if (state['availability.status']) {
      const stateName = state['availability.status'];
      if (!machineStatusesObject[stateName]) return '#AAAAAA';
      return machineStatusesObject[stateName].color;
    }
    if (state['performance.status']) {
      const stateName = state['performance.status'];

      if (!machineStatusesObject[stateName]) return '#AAAAAA';
      return machineStatusesObject[stateName].color;
    }
    if (state.determiner) {
      const stateName = state.determiner;
      if (!machineStatusesObject[stateName]) return '#AAAAAA';
      return machineStatusesObject[stateName].color;
    }

    if(state.overview){
      // Use the groupedStatuses
      return getGroupedStateColor(state)
    }
    return '#FFFFFF';
  };

  /**
   * Returns correct text to represent passed in state.
   *
   * state param can be either State object or state short name(String)
   *
   * @param {String || Object} state
   */
  const stateText = (state) => {
    if (!state) return '';
    if (typeof state === 'string') {
      if (!machineStatusesObject[state]) return '';
      return phrases.machineStatusesText[machineStatusesObject[state].activity];
    }
    if (state['presentation.status']) {
      const stateName = state['presentation.status'];
      if (!machineStatusesObject[stateName]) return '';
      return phrases.machineStatusesText[machineStatusesObject[stateName].activity];
    }
    if (state['availability.status']) {
      const stateName = state['availability.status'];
      if (!machineStatusesObject[stateName]) return '';
      return phrases.machineStatusesText[machineStatusesObject[stateName].activity];
    }
    if (state['performance.status']) {
      const stateName = state['performance.status'];
      if (!machineStatusesObject[stateName]) return '';
      return phrases.machineStatusesText[machineStatusesObject[stateName].activity];
    }
    if (state.determiner) {
      const stateName = state.determiner;
      if (!machineStatusesObject[stateName]) return '';
      return phrases.machineStatusesText[machineStatusesObject[stateName].activity];
    }

    return '';
  };

  return {
    stateColor,
    stateText,
    reasonColor,
    reasonText,
    loadMachineStatuses,
    loadReasons,
    setDefaultDataLayer,
    initialSetup,
    hasAnyWaste,
    hasScrapType,
    wasteCount,
    totalWasteCount,
    wasteCountForType,
    scrapTypeProductionPeriod,
    scrapTypeNote,
    scrapTypeText,
  };
};

export default useStatesFilter;
