import moment from 'moment';
import { DEFAULT_TIMESPAN_START } from '../constants';

export const drawerReducer = (state = false, action) => {
  switch (action.type) {
    case 'OPEN_DRAWER':
      return true;
    case 'CLOSE_DRAWER':
      return false;
    case 'TOGGLE_DRAWER':
      return !state;
    default:
      return state;
  }
};

export const selectionDrawerReducer = (state = false, action) => {
  switch (action.type) {
    case 'OPEN_SELECTION_DRAWER':
      return true;
    case 'CLOSE_SELECTION_DRAWER':
      return false;
    case 'TOGGLE_SELECTION_DRAWER':
      return !state;
    default:
      return state;
  }
};

export const machineDrawerReducer = (state = false, action) => {
  switch (action.type) {
    case 'OPEN_MACHINE_DRAWER':
      return true;
    case 'CLOSE_MACHINE_DRAWER':
      return false;
    case 'TOGGLE_MACHINE_DRAWER':
      return !state;
    default:
      return state;
  }
};

export const timespanStartReducer = (state = DEFAULT_TIMESPAN_START, action) => {
  switch (action.type) {
    case 'SET_TIMESPAN_START':
      return action.startTime;
    default:
      return state;
  }
};

export const timespanEndReducer = (state = moment(), action) => {
  switch (action.type) {
    case 'SET_TIMESPAN_END':
      return action.endTime;
    default:
      return state;
  }
};

export const timespanDurationReducer = (state = 12, action) => {
  switch (action.type) {
    case 'SET_TIMESPAN_DURATION':
      return action.duration;
    default:
      return state;
  }
};

export const lastPulseUpdateReducer = (state = new Date(), action) => {
  switch (action.type) {
    case 'LAST_PULSE_UPDATE':
      return action.lastPulseUpdate;
    default:
      return state;
  }
};

export const isRelativeTimespanReducer = (state = true, action) => {
  switch (action.type) {
    case 'SET_IS_RELATIVE_TIMESPAN':
      return action.isRelativeTimespan;
    default:
      return state;
  }
};

export const selectedRelativeTimespanReducer = (state = { amount: 12, unit: 'hours' }, action) => {
  switch (action.type) {
    case 'SET_SELECTED_RELATIVE_TIMESPAN':
      return action.relativeTimespan;
    default:
      return state;
  }
};

export const selectedTimespanTextReducer = (state = 'Last 12 hours', action) => {
  switch (action.type) {
    case 'SET_SELECTED_TIMESPAN_TEXT':
      return action.text;
    default:
      return state;
  }
};

export const isCustomTimePresetReducer = (state = false, action) => {
  switch (action.type) {
    case 'SET_CUSTOM_TIME_PRESET':
      return action.customPreset;
    default:
      return state;
  }
};

export const structureInUseReducer = (state = '', action) => {
  switch (action.type) {
    case 'SET_STRUCTURE_IN_USE':
      return action.structure;
    default:
      return state;
  }
};

export const structureViewInUseReducer = (state = '', action) => {
  switch (action.type) {
    case 'SET_STRUCTURE_VIEW_IN_USE':
      return action.structureView;
    default:
      return state;
  }
};

export const machineInUseReducer = (state = '', action) => {
  switch (action.type) {
    case 'SET_MACHINE_IN_USE':
      return action.machine;
    default:
      return state;
  }
};

export const machineViewInUseReducer = (state = '', action) => {
  switch (action.type) {
    case 'SET_MACHINE_VIEW_IN_USE':
      return action.machineView;
    default:
      return state;
  }
};

export const tableStateReducer = (state = {}, action) => {
  switch (action.type) {
    case 'PERSIST_TABLE_STATE':
      return action.tableState;
    default:
      return state;
  }
};

export const machineStatusesReducer = (state = {}, action) => {
  switch (action.type) {
    case 'SET_MACHINE_STATUSES':
      return action.machineStatuses;
    default:
      return state;
  }
};

export const reasonsReducer = (state = {}, action) => {
  switch (action.type) {
    case 'SET_REASONS_OBJECT':
      return action.reasons;
    default:
      return state;
  }
};

export const scrapTypesReducer = (state = {}, action) => {
  switch (action.type) {
    case 'SET_SCRAP_TYPES_OBJECT':
      return action.scrapTypes;
    default:
      return state;
  }
};

export const dataLayerReducer = (state = {}, action) => {
  switch (action.type) {
    case 'SET_DATA_LAYER':
      return action.dataLayer;
    default:
      return state;
  }
};

export const paletteTypeReducer = (state = '', action) => {
  switch (action.type) {
    case 'SET_PALETTE_TYPE':
      return action.palette;
    default:
      return state;
  }
};

export const productionPulseFiltersReducer = (state = {searchQuery: '', filterList: {}, sortType: "default"}, action) => {
  switch (action.type) {
    case 'SET_PRODUCTION_PULSE_FILTERS':
      return action.productionPulseFilters;
    default:
      return state;
  }
};
