import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Backdrop,
  Button,
  Divider,
  Grid,
  Hidden,
  IconButton,
  Menu,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import { DataGrid }   from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon       from '@material-ui/icons/Edit';
import ArrowBackIcon  from '@material-ui/icons/ArrowBack';
import AutorenewIcon  from '@material-ui/icons/Autorenew';

import BreadcrumbsNavigation  from '@frontend/components/BreadcrumbsNavigation';
import CustomLink             from '@frontend/components/CustomLink';
import LoadingIndicator       from '@frontend/components/LoadingIndicator';
import { ROUTES }         from '@frontend/constants'; 
import useJob         from '@frontend/modules/orders/hooks/useJob';
import NotesAccordion from '@frontend/modules/orders/NotesAccordion';
import NotesForm      from '@frontend/modules/orders/NotesForm';
import JobHandCount   from '@frontend/modules/terminal/components/JobHandCount';
import JobStatus      from '@frontend/modules/terminal/components/JobStatus';
import utils              from '@frontend/utils/utils'
import useDateTimeFormat  from '@frontend/utils/useDateTimeFormat';
import { usePhrases }     from '@frontend/utils/usePhrases';

const useStyles = makeStyles((theme) => ({
  topCardsContainer: {
    margin: theme.spacing(0),
    padding: theme.spacing(0),
  },
  cardCell: {
    padding: theme.spacing(1.5),
  },
  machinePulsesContainer: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  editing: {
    // margin: theme.spacing(0),
    paddingRight: theme.spacing(3),
  },
  editingBtn: {
    margin: theme.spacing(0),
    padding: theme.spacing(0),
  },
  backdrop: {
    zIndex: 1,
    position: 'fixed',
    margin: 'auto',
  },
  orange: {
    color: theme.palette.primary.main,
  },
}));

export default function JobView() {

  // ===== State Objects =====
  const [anchorElHandCount, setAnchorElHandCount] = useState(false);
  const [anchorElJobStatus, setAnchorElJobStatus] = useState(false);

  // ===== Redux Selectors =====
  const structureInUse = useSelector((state) => state.structureInUse);

  // ===== Imports =====
  const { jobId } = useParams();
  const phrases = usePhrases().phrases();
  const {
    jobData,
    activations,
    notes,
    structure,
    isLoadingJob,
    updateJob,
    updateHandCount,
    loadingPropsObject,
    addNote,
  } = useJob(jobId, structureInUse);
  const classes = useStyles();
  const { formatDate } = useDateTimeFormat();

  // ===== Local Variables =====
  const columns = [
    { field: 'id', hide: true },
    { field: 'status', headerName: phrases.modules.orders.status, flex: 1 },
    { field: 'machine', headerName: phrases.modules.orders.machine, flex: 1 },
    { field: 'intended', headerName: phrases.modules.orders.intended, flex: 1 },
    { field: 'user', headerName: phrases.modules.orders.operator, flex: 1 },
    {
      field: 'startTime',
      headerName: phrases.modules.orders.startTime,
      flex: 1,
    },
    { field: 'endTime', headerName: phrases.modules.orders.endTime, flex: 1 },
    { field: 'duration', headerName: phrases.modules.orders.duration, flex: 1 },
    {
      field: 'sensorCount',
      headerName: phrases.modules.orders.sensorCount,
      flex: 1,
    },
    {
      field: 'handCount',
      headerName: phrases.modules.orders.handCount,
      flex: 1,
    },
  ];

  // ===== Helper Methods =====
  const handleOpenHandCountEdit = (e) => {
    setAnchorElHandCount(e.target);
  };

  const handleCloseHandCountEdit = () => {
    setAnchorElHandCount(null);
  };

  const handleOpenJobStatusEdit = (e) => {
    setAnchorElJobStatus(e.target);
  };

  const handleCloseJobStatusEdit = () => {
    setAnchorElJobStatus(null);
  };
  const handleJobHandCountUpdate = (job, handCount) => {
    updateHandCount(job, handCount);
    handleCloseHandCountEdit();
  };
  const handleUpdateJob = (job) => {
    updateJob(job);
    handleCloseHandCountEdit();
    handleCloseJobStatusEdit();
  };

  // ===== Return =====
  return (
    <Grid container spacing={2}>
      <Hidden xsDown>
        <Grid item xs={12}>
          <BreadcrumbsNavigation selectedStructure={structure} />
        </Grid>
      </Hidden>

      <Grid
        container
        spacing={2}
        className={classes.topCardsContainer}
        variant="outlined"
      >
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6}>
              <Typography align="left">
                <Button
                  startIcon={
                    <ArrowBackIcon style={{'vertical-align': "sub"}}>
                      {phrases.modules.orders.backToJobsList}
                    </ArrowBackIcon>
                  }
                  variant="contained"
                  component={CustomLink}
                  to={ROUTES.ORDERS}
                >
                  {phrases.modules.orders.backToJobsList}
                </Button>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper variant="outlined" square style={{ height: '100%' }}>
            <Table aria-label="simple table" style={{ height: '100%' }}>
              <TableBody>
                <TableRow>
                  <TableCell className={classes.cardCell} align="left">
                    {phrases.modules.orders.jobId}:
                  </TableCell>
                  <TableCell className={classes.cardCell} align="right">
                    {jobData?.businessJobId}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell className={classes.cardCell} align="left">
                    {phrases.modules.orders.product}:
                  </TableCell>
                  <TableCell className={classes.cardCell} align="right">
                    {jobData?.product?.name}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    className={`${classes.lastTableCell} ${classes.cardCell}`}
                    align="left"
                  >
                    {phrases.modules.orders.actualStartDate}:
                  </TableCell>

                  <TableCell
                    className={`${classes.lastTableCell} ${classes.cardCell}`}
                    align="right"
                  >
                    {jobData.actualStartDate
                      ? formatDate(jobData.actualStartDate)
                      : ''}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    className={`${classes.lastTableCell} ${classes.cardCell}`}
                    align="left"
                  >
                    {phrases.modules.orders.plannedStartDate}:
                  </TableCell>

                  <TableCell
                    className={`${classes.lastTableCell} ${classes.cardCell}`}
                    align="right"
                  >
                    {jobData.plannedExecutionDate
                      ? formatDate(jobData.plannedExecutionDate)
                      : ''}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.cardCell} align="left">
                    {phrases.modules.orders.description}:
                  </TableCell>
                  <TableCell className={classes.cardCell} align="right">
                    {jobData.description}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.cardCell} align="left">
                  {phrases.modules.orders.intendedMachine}:
                  </TableCell>
                  <TableCell className={classes.cardCell} align="right">
                  {jobData?.intendedMachine ? (
                    <Link to={`/machine-overview/${jobData?.intendedMachine.id}`} className={classes.orange}>
                        {jobData?.intendedMachine ? `${jobData?.intendedMachine?.name} (${jobData?.intendedMachine?.alias})` : '---'}
                    </Link>
                  ) : '---'}
                  </TableCell>
                </TableRow>                
              </TableBody>
            </Table>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper variant="outlined" square>
            <Table aria-label="simple table">
              <TableBody>
                <TableRow>
                  <TableCell className={classes.cardCell} align="left">
                    {phrases.modules.orders.sensorCount}:
                  </TableCell>
                  <TableCell className={classes.cardCell} align="right">
                    {jobData.sensorCount}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.cardCell} align="left">
                    {phrases.modules.orders.targetCycleTime}:
                  </TableCell>
                  <TableCell className={classes.cardCell} align="right">
                    {utils.cycleTimeToPartsPerMinute(jobData?.targetCycleTime)} {phrases.modules.machine.partsPerMinute}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.cardCell} align="left">
                    {phrases.modules.orders.targetSetupTime}:
                  </TableCell>
                  <TableCell className={classes.cardCell} align="right">
                    {jobData?.targetSetupTime} {phrases.timespanSelection.Minutes}
                  </TableCell>
                </TableRow>
                
                <TableRow>
                  <TableCell className={classes.cardCell} align="left">
                    {phrases.modules.orders.targetCount}:
                  </TableCell>
                  <TableCell className={classes.cardCell} align="right">
                    {jobData.targetQuantity}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    className={`${classes.lastTableCell} ${classes.cardCell}`}
                    align="left"
                  >
                    {phrases.modules.orders.handCount}:
                  </TableCell>

                  <TableCell
                    className={`${classes.lastTableCell} ${classes.cardCell}`}
                    align="right"
                  >
                    <span className={classes.editing}>{jobData.handCount}</span>{' '}
                    <IconButton
                      variant="contained"
                      size="small"
                      onClick={handleOpenHandCountEdit}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                    <Menu
                      id="simple-menu"
                      MenuListProps={{
                        disablePadding: true,
                        style: { overflow: 'hidden' },
                      }}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      anchorEl={anchorElHandCount}
                      keepMounted
                      open={Boolean(anchorElHandCount)}
                      onClose={handleCloseHandCountEdit}
                    >
                      <JobHandCount
                        jobTracker={jobData}
                        updateHandCount={handleJobHandCountUpdate}
                        loadingPropsObject={loadingPropsObject}
                      />
                    </Menu>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.cardCell} align="left">
                    {phrases.modules.orders.jobStatus}:
                  </TableCell>
                  <TableCell className={classes.cardCell} align="right">
                    <span className={classes.editing}>
                      {phrases.modules.orders[jobData.completionStatus]}
                    </span>{' '}
                    <IconButton
                      variant="contained"
                      size="small"
                      onClick={handleOpenJobStatusEdit}
                    >
                      <AutorenewIcon fontSize="small" />
                    </IconButton>
                    <Menu
                      id="simple-menu"
                      MenuListProps={{
                        style: { padding: '4px', overflow: 'hidden' },
                      }}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      anchorEl={anchorElJobStatus}
                      keepMounted
                      open={Boolean(anchorElJobStatus)}
                      onClose={handleCloseJobStatusEdit}
                    >
                      <JobStatus
                        jobTracker={jobData}
                        updateJob={handleUpdateJob}
                        loadingPropsObject={loadingPropsObject}
                      />
                    </Menu>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="button" display="block" color="textSecondary">
          {phrases.modules.orders.engagements}
        </Typography>
        <Divider />
      </Grid>

      <Grid
        item
        xs={12}
        component={Paper}
        variant="outlined"
        className={classes.machinePulsesContainer}
        square
      >
        {activations.length > 0 ? (
          <DataGrid
            autoHeight
            rows={activations}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
          />
        ) : (
          '     ...'
        )}
      </Grid>

      <Grid item xs={12}>
        <Typography variant="button" display="block" color="textSecondary">
          {phrases.modules.orders.operatorNotes}
        </Typography>
        <Divider />
      </Grid>

      <Grid
        item
        xs={12}
        component={Paper}
        variant="outlined"
        className={classes.machinePulsesContainer}
        square
      >
        {notes.length ? (
          <NotesAccordion
            notes={notes}
            loadingPropsObject={loadingPropsObject}
          />
        ) : (
          '     ...'
        )}
        <NotesForm addNote={addNote} loadingPropsObject={loadingPropsObject} />
      </Grid>
      <Backdrop classes={{ root: classes.backdrop }} open={isLoadingJob}>
        <LoadingIndicator light text={phrases.modules.orders.loadingJob} />
      </Backdrop>
    </Grid>
  );
}
