/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  FormHelperText,
} from '@material-ui/core';

// eslint-disable-next-line import/no-unresolved
import { usePhrases } from '@frontend/utils/usePhrases';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '100%',
    marginLeft: 'auto',
  },
}));

const StyledSelect = (props) => {
  const classes = useStyles();
  const phrases = usePhrases().phrases();
  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  const {
    required,
    label,
    value,
    data,
    helperText,
    plain
  } = props;

  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  return (
    <div>
      <FormControl {...props} margin="dense" required={!!required} variant="outlined" className={classes.formControl}>
        <InputLabel ref={inputLabel} id="demo-simple-select-outlined-label">
          {label}
        </InputLabel>
        <Select
          labelWidth={labelWidth}
          renderValue={() => {
            if (typeof value === 'object') return value.name;
            return value;
          }}
          {...props}
        >
          {!plain &&
          <MenuItem value={null}>
            <em>{phrases.forms.shared.fields.none}</em>
          </MenuItem>
          }
          {data
            .map((_data) => (
              <MenuItem key={_data.name ? _data.name : _data} value={_data}>
                {_data.name ? _data.name : _data}
              </MenuItem>
            ))}
        </Select>
        {helperText ? <FormHelperText className={classes.errorText}>{helperText}</FormHelperText> : ''}
      </FormControl>
    </div>
  );
};

StyledSelect.propTypes = {
  required: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  // Has to be any, because it might be a string, might be an object
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any.isRequired,
  data: PropTypes.instanceOf(Array).isRequired,
  helperText: PropTypes.string.isRequired,
};

export default StyledSelect;
