import moment from 'moment';

// The customer logo needs to be stored in the static directory
export const CUSTOMER_LOGO = {
  filename: "customerLogo.png",
  enabled: true,
  height: 1637,      // Not used yet
  width: 2253,       // Not used yet
  altText: "<DEFAULT TEXT CHANGE ME>"
}

export const DEFAULT_TIMESPAN_START = moment().subtract(12, 'hours');

export const ROUTES = {
  HOME: '/',
  STRUCTURE_OVERVIEW: '/structure-overview',
  STRUCTURE_OVERVIEW_ID: '/structure-overview/:structureId',
  STRUCTURES_MANAGEMENT: '/structures-management',
  MACHINE_OVERVIEW: '/machine-overview',
  MACHINE_OVERVIEW_ID: '/machine-overview/:machineId',
  MACHINES_MANAGEMENT: '/machines-management',
  MACHINE_TYPES_MANAGEMENT: '/machine-types-management',
  SENSORS_MANAGEMENT: '/sensors-management',
  SENSOR_TYPES_MANAGEMENT: '/sensor-types-management',
  USERS_MANAGEMENT: '/users-management',
  ROLES_MANAGEMENT: '/roles-management',
  ANDON_BOARD: '/andon-board',
  ANDON_BOARD_ID: '/andon-board/:structureId',
  REPORTING_VIEW: '/reporting',
  ADVANCED_REPORTING_VIEW: '/advanced_reporting',
  KNOWLEDGE: '/knowledge/:knowledgeName',
  INFO: '/info',
  REASONS_MANAGEMENT: '/reasons-management',
  REASONS_CATEGORIES_MANAGEMENT: '/reasons-categories-management',
  SCRAP_TYPES_CATEGORIES_MANAGEMENT: '/scrap-types-categories-management',
  MACHINE_STATUSES_MANAGEMENT: '/machine-statuses-management',
  TERMINAL_OVERVIEW: '/terminal-overview',
  TERMINAL_OVERVIEW_ID: '/terminal-overview/:machineId',
  SCHEDULES: '/schedules',
  SCHEDULE_MANAGEMENT: '/schedule-management',
  SHIFT_MANAGEMENT: '/shift-management',
  COMPARISON: '/comparison',
  SCRAP_TYPES_MANAGEMENT: '/scrap-types-management',
  ORDERS: '/orders',
  ORDERS_WITH_ID: '/orders/:structureId',
  JOB: '/orders/job/:jobId',
  JOB_MANAGEMENT: '/orders/jobs-management',
  APP_TERMINAL_OVERVIEW: '/app-terminal-view',
  APP_TERMINAL_OVERVIEW_ID: '/app-terminal-view/:machineId',
  MACHINE_PERFORMANCE: '/machine-performance',
  SIMPLE_MACHINE: '/simple-machine',
  SIMPLE_MACHINE2: '/simple-machine2',
  MACHINE_SHIFT_MANAGEMENT: '/machine-shift-management',
};

export const GROUP_STATE_LIMITS = {
  EXCELLENT: 80,
  GOOD: 60,
  MEDIUM: 40,
  BAD: 20,
  FATAL: 0
}
export const ORDERS_TIMEFRAME_VALUES = {
  TWOWEEKS: "14",
  MONTH: "30",
  SIXMONTHS: "183",
  YEAR: "366",
}
export const UNDEFINED_STATES = [
  'not_defined',
  'break_creep'
]
export const RUNNING_STATES = [
  'running',
  'slow_running',
]
export const ALLOW_MERGE_STATE = true;
export const DEFAULT_JOB_ID_LENGTH = 9;
export const MATERIAL_UI_ICON = 'material-ui';
export const MATERIAL_DESIGN_ICON = 'material-design';
export const FONTAWESOME_ICON = 'fontawesome';
export const ALL_ICON_TYPES = [MATERIAL_UI_ICON, MATERIAL_DESIGN_ICON, FONTAWESOME_ICON];
export const DEFAULT_JOB_ID_FORMAT = '[JID]';   // SID = Structure ID, JID = Job ID
