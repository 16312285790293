import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { 
    faCogs, 
    faPlusCircle 
} from '@fortawesome/free-solid-svg-icons';
import { makeStyles } from '@material-ui/core/styles';
import {
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Table,
  Typography,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import CustomLink         from '@frontend/components/CustomLink';
import ConfirmationModal  from '@frontend/components/ConfirmationModal';
import { ROUTES } from '@frontend/constants';
import Modal        from '@frontend/components/Modal';
import CTable       from '@frontend/components/Table';
import CustomTabs   from '@frontend/components/Tabs';
import useJobsManagement from '@frontend/modules/orders/hooks/useJobsManagement';
import useConditionalArrayElement   from '@frontend/utils/useConditionalArrayElement';
import useSecurity                  from '@frontend/utils/useSecurity';
import { usePhrases }               from '@frontend/utils/usePhrases';
import { persistTableState }        from '@frontend/utils/UIActions';
import utils                        from '@frontend/utils/utils';
import { useState } from 'react';
import JobEditForm from './JobEditForm';
const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    link: {
        color: theme.palette.primary.main,      
    }
}));

const JobsManagementView = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [jobToEdit, setJobToEdit] = useState(-1);
    const [jobToDelete, setJobToDelete] = useState(-1);
    
    const classes = useStyles();
    const phrases = usePhrases().phrases();
    const dispatch = useDispatch();
    const {
        isLoading,
        existingJobs,
        handleDeleteJob,
        setConfirmDeletionModalOpen,
        confirmDeletionModalOpen
    } = useJobsManagement(modalOpen, jobToDelete);
    const { isAuthorized } = useSecurity();
    const { conditionalElement } = useConditionalArrayElement();

    const editJob = (jobIndex) =>{
      setJobToEdit(jobIndex);
      setModalOpen(true);
    }
    

    const handleCloseModal = () => {
      setModalOpen(false);
      setJobToEdit({});
    }

    const prepareDeleteJobModalText = () => {
      
      return (
        <>
        <Typography>
          { phrases.modules.orders.areYouSureDeleteJobText}
          <Table>
              <TableRow>
                <TableCell>{phrases.forms.jobs.businessJobId}:</TableCell>
                <TableCell>{existingJobs[jobToDelete]?.businessJobId}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{phrases.forms.jobs.scanCode}:</TableCell>
                <TableCell>{existingJobs[jobToDelete]?.scanCode}</TableCell>
              </TableRow>
          </Table>
        </Typography>
        </>
      )
    }
    const tableState = useSelector((state) => state.tableState);
    const columns = [
        {
          label: phrases.forms.jobs.businessJobId,
          name: 'businessJobId',
          options: {
            filter: false,
            sortDirection: tableState.sortOrder && tableState.sortOrder.name === 'businessJobId' ? tableState.sortOrder.direction : 'none',
            customBodyRender: (value, tableMeta, updateValue) => (

                 <div>
                    <Link 
                    to={`/orders/job/${value}`} 
                    key={`${value}-lnk`} 
                    className={classes.link}
                    >
                    {value}           
                    </Link> 
                </div>    
            ),
          },
        },
        {
          label: phrases.forms.jobs.completionStatus,
          name: 'completionStatus',
          options: {
              filter: false,            
              sortDirection: tableState.sortOrder && tableState.sortOrder.name === 'completionStatus' ? tableState.sortOrder.direction : 'none',
              customBodyRender: (value, tableMeta, updateValue) => (
                  <div style={{textAlign: "center"}}>{value}</div>    
              ),
          },
        },
        {
          label: phrases.forms.jobs.handCount,
          name: 'handCount',
          options: {
            filter: false,
            sortDirection: tableState.sortOrder && tableState.sortOrder.name === 'handCount' ? tableState.sortOrder.direction : 'none',
            customBodyRender: (value, tableMeta, updateValue) => (
                <div>{value}</div>
            ),
          },
        },
        {
          label: phrases.forms.jobs.scanCode,
          name: 'scanCode',
          options: {
            filter: false,
            sortDirection: tableState.sortOrder && tableState.sortOrder.name === 'scanCode' ? tableState.sortOrder.direction : 'none',
            customBodyRender: (value, tableMeta, updateValue) => (
                 <div>{value}</div>    
            ),
          },
        },
        {
          label: phrases.forms.jobs.intendedMachine,
          name: 'intendedMachine',
          options: {
            sort: true,
            filter: false,            
            sortDirection: tableState.sortOrder && tableState.sortOrder.name === 'intendedMachine' ? tableState.sortOrder.direction : 'none',
            customBodyRender: (value, tableMeta, updateValue) => (
                <div>                    
                    <CustomLink
                        className={classes.link}
                        id={value?.id}
                        object="machine"
                        to={ROUTES.MACHINE_OVERVIEW}
                    >
                        {value?.name}
                    </CustomLink>
              </div>    
            ),
          },
        },
        {
          label: phrases.forms.jobs.description,
          name: 'description',
          options: {
            filter: false,            
            sortDirection: tableState.sortOrder && tableState.sortOrder.name === 'description' ? tableState.sortOrder.direction : 'none',
            customBodyRender: (value, tableMeta, updateValue) => (
                <div>{value}</div>    
            ),
          },
        },
        {
          label: phrases.forms.jobs.targetQuantity,
          name: 'targetQuantity',
          options: {
            filter: false,            
            sortDirection: tableState.sortOrder && tableState.sortOrder.name === 'targetQuantity' ? tableState.sortOrder.direction : 'none',
            customBodyRender: (value, tableMeta, updateValue) => (
                <div>{value}</div>    
            ),
          },
        },
        {
            label: phrases.forms.jobs.targetSetupTime,
            name: 'targetSetupTime',
            options: {
                filter: false,            
                sortDirection: tableState.sortOrder && tableState.sortOrder.name === 'targetSetupTime' ? tableState.sortOrder.direction : 'none',
                customBodyRender: (value, tableMeta, updateValue) => (
                    <div>{value}</div>    
                ),
            },
            },
        {
            label: phrases.forms.jobs.targetCycleTime,
            name: 'targetCycleTime',
            options: {
                filter: false,            
                sortDirection: tableState.sortOrder && tableState.sortOrder.name === 'targetCycleTime' ? tableState.sortOrder.direction : 'none',
                customBodyRender: (value, tableMeta, updateValue) => (
                    <div>{value}</div>    
                ),
            },
        },
        {
          label: phrases.forms.jobs.product,
          name: 'product',
          options: {
              filter: false,            
              sortDirection: tableState.sortOrder && tableState.sortOrder.name === 'product' ? tableState.sortOrder.direction : 'none',
              customBodyRender: (value, tableMeta, updateValue) => (
                  <div>{value?.businessProductId}</div>    
              ),
          },
        },
        ...conditionalElement(isAuthorized('machines', ['update:any']), {
          label: phrases.tables.shared.actions,
          name: 'businessJobId',
          options: {
            filter: false,
            sort: false,
            customBodyRender: (value, tableMeta, updateValue) => (
              <div style={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
              >
                <Tooltip placement="top" arrow title={phrases.modules.orders.edit}>
                  <IconButton
                    aria-label="edit-icon"
                    aria-haspopup="true"
                    color="primary"
                    size="medium"
                    onClick={() => editJob(existingJobs.findIndex((job) => job.businessJobId === value))}
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                <Tooltip placement="top" arrow title={phrases.modules.orders.delete}>
                  <IconButton
                    aria-label="edit icon for current row"
                    aria-haspopup="true"
                    color="primary"
                    size="small"
                    onClick={() => {
                      setJobToDelete(existingJobs.findIndex((job) => job.businessJobId === value));
                      setConfirmDeletionModalOpen(true);
                    }}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </div>
            ),
          },
        }),
      ];
    const customOptions = {
        filterType: 'checkbox',
        print: false,
        download: false,
        elevation: 0,
        selectableRows: isAuthorized('orders', ['delete:any']) ? 'multiple' : 'none',
        onFilterChange: (changedColumn, filterList) => {
        dispatch(persistTableState({
            ...tableState,
            filterList,
        }));
        },
        onChangePage: (page) => {
        dispatch(persistTableState({
            ...tableState,
            page,
        }));
        },
        onColumnSortChange: (column, direction) => {
        dispatch(persistTableState({
            ...tableState,
            sortOrder: {
            name: column,
            direction: utils.getDirection(direction),
            },
        }));
        },
        page: tableState.page,
        onChangeRowsPerPage: (number) => {
        dispatch(persistTableState({
            ...tableState,
            rowsPerPage: number,
        }));
        },
        rowsPerPage: tableState.rowsPerPage || 10,
    };
    return ( 
        <div className={classes.root}>
        <CustomTabs
          tabs={[
            ...conditionalElement(isAuthorized('jobs', ['read:any']), {
                label: phrases.modules.orders.existingJobs,
                icon: faCogs,
            }),
            ...conditionalElement(false, {
                label: phrases.modules.orders.createNewJob,
                icon: faPlusCircle,
            }),
          ]}
          panels={[
            ...conditionalElement(isAuthorized('jobs', ['read:any', 'read:own']), {
              content: <CTable
                modalTitle={phrases.modules.orders.existingJobs}
                data={existingJobs}
                resource="orders"
                columns={columns}
                customOptions={customOptions}
                isLoading={isLoading}
              />,
            }),
            ...conditionalElement(isAuthorized('jobs', ['update:any', 'update:own']), {
              content: (
              <JobEditForm
                existingJobs = {[]}
                jobIndexToEdit = {-1}
                isEdit={false}
              />
              )
            }),
            ]}
        />
        <Modal
          modalTitle={phrases.forms.jobs.modalTitle}
          open={modalOpen}
          handleClose={handleCloseModal}
          content={(
            <JobEditForm
              existingJobs = {existingJobs}
              jobIndexToEdit = {jobToEdit}
              handleCloseModal = {handleCloseModal}
              isEdit
            />
          )}
        />
        <ConfirmationModal
          title={phrases.modules.orders.areYouSureDeleteJob}
          text={prepareDeleteJobModalText(jobToDelete)}
          openModal={confirmDeletionModalOpen}
          handleClose={() => setConfirmDeletionModalOpen(false)}
          callback={() => handleDeleteJob()}
        />
      </div> );
}
 
export default JobsManagementView;