/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import {
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import { makeStyles }             from '@material-ui/core/styles';
import ExpandLess                 from '@material-ui/icons/ExpandLess';
import ExpandMore                 from '@material-ui/icons/ExpandMore';
import { FontAwesomeIcon }        from '@fortawesome/react-fontawesome';
import { faExclamationTriangle }  from '@fortawesome/free-solid-svg-icons';

import CustomLink         from '@frontend/components/CustomLink';
import LoadingIndicator   from '@frontend/components/LoadingIndicator';
import { ROUTES }         from '@frontend/constants';
import useStatesFilter    from '@frontend/hooks/useStatesFilter';
import useTopReasons      from '@frontend/modules/reasons/hooks/useTopReasons';
import useDateTimeFormat  from '@frontend/utils/useDateTimeFormat';
import { usePhrases }     from '@frontend/utils/usePhrases';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  link: {
    color: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      float: 'none',
      whiteSpace: 'pre-line',
      paddingTop: theme.spacing(1),
    },
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  loadingReasonsIndicator: {
    marginLeft: theme.spacing(1),
  },
  collapseCell: {
    cursor: 'pointer',
  },
}));

export default function TopReasons(props) {
  const classes = useStyles();
  const phrases = usePhrases().phrases();
  const [collapseReasons, setCollapseReasons] = useState({});
  const [isMachineView, setIsMachineView] = useState(false);

  const {
    machines,
  } = props;
  
  const {
    reasons,
    reasonTimes,
    machineNames,
    loadingReasons,
    structure,
    reasonTotalCount,
  } = useTopReasons(machines);

  useEffect(() => {
    if (reasons && _.isEmpty(collapseReasons)) {
      let collapseReasons = {};
      reasons.forEach((reason) => {
        collapseReasons[reason[0]] = false;
      });
      setCollapseReasons(collapseReasons);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reasons]);

  useEffect(() => {
    if(machines !== undefined && !_.isEmpty(machines)){
      setIsMachineView(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps  
  }, [machines]);

  const { formatHoursMinutesDuration } = useDateTimeFormat();
  const { reasonText } = useStatesFilter();
  return (
    <Paper className={classes.paper} variant="outlined" square>
      <List>

        <ListItem alignItems="center">
          <ListItemAvatar>
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              size="2x"
            />
          </ListItemAvatar>
          <Typography variant="body1">
            {phrases.modules.reasons.reasons}
          </Typography>
          <Typography variant="body1" className={classes.loadingReasonsIndicator}>
            {loadingReasons ? <LoadingIndicator size={20} /> : ''}
          </Typography>
        </ListItem>
        <Divider variant="inset" component="li" />

      </List>
      <Table aria-label="simple table">

        <TableBody>
          {reasons?.map((reason, i) => (<>
            <TableRow>
              <TableCell align="left">
                <Typography variant="body2">{`${i + 1}. ${reasonText(reason[0])}:`}</Typography>
              </TableCell>
              <TableCell align="right">
                <span>
                  {formatHoursMinutesDuration(reason[1])}
                  {" "}
                  ({reasonTotalCount[reason[0]]}x)

                </span>
              </TableCell>
              {!isMachineView &&
              <TableCell 
                align="right" 
                className={classes.collapseCell} 
                onClick={() => setCollapseReasons(
                  {
                    ...collapseReasons, 
                    [reason[0]]: !collapseReasons[reason[0]]
                  })
                }>
                {collapseReasons[reason[0]] ? <ExpandLess /> : <ExpandMore />}
              </TableCell>
              }
            </TableRow>
            {collapseReasons[reason[0]] && !isMachineView && <>                             
              {reasonTimes[reason[0]]?.sort((a, b) => b.duration?.duration - a.duration?.duration)
              .map((detail) => {
                if(detail.duration === undefined || detail.duration === null || detail.duration?.duration === 0) return <></>;
                return (
                  <TableRow>
                    <TableCell align="left">
                      <CustomLink className={classes.link} id={detail.machine} object="machine" to={ROUTES.MACHINE_OVERVIEW}>
                        <b>{machineNames[detail.machine]?.name}</b>
                      </CustomLink>                          
                      {" "}
                      {
                        structure.id !== machineNames[detail.machine]?.structure?.id && <>
                        (
                        <CustomLink className={classes.link} id={machineNames[detail.machine]?.structure?.id} object="structure" to={ROUTES.STRUCTURE_OVERVIEW}>
                          {machineNames[detail.machine]?.structure?.businessId}
                        </CustomLink>
                        )</>
                      }                          
                    </TableCell>
                    <TableCell align="right">
                      <span>
                        {formatHoursMinutesDuration(detail.duration?.duration)}
                        {" "}
                        ({detail.duration?.count}x)
                      </span>
                    </TableCell>
                    <TableCell>                      
                    </TableCell>
                    </TableRow>
                )
              })}                
            </>}
            </>
          ))}
          {!reasons.length && !loadingReasons ? <Typography>{phrases.modules.reasons.noAssignedReasonsTimeframe}</Typography> : ''}

        </TableBody>

      </Table>
    </Paper>
  );
}
