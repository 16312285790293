import React from 'react';
import { usePhrases } from '@frontend/utils/usePhrases';
import {
    Grid,
    Paper,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      margin: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }));
const ShiftSpeedCard = (props) => {
    const { 
        shiftSpeed
    } = props;
    const classes = useStyles();
    const phrases = usePhrases().phrases();
    return (
        <Grid item xs={4}>
            <Paper className={classes.paper} variant="outlined" square>
                <Typography align='center' variant="h4" display="block" color="textSecondary">
                    {phrases.modules.simple_machine2.shiftSpeed}
                    {"-"}
                    {phrases.modules.simple_machine.partsPerHour}
                </Typography>
                <br />
                <Typography align='center' variant="h2" display="block" color="textSecondary">
                    <b>{shiftSpeed ? parseFloat(shiftSpeed).toFixed(0): "--"}</b>
                    
                </Typography>
            </Paper>
        </Grid>
        );
}
 
export default ShiftSpeedCard;
