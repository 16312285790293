import { combineReducers } from 'redux';
import userReducer from '@frontend/modules/user/reducers/userReducer';
import usersReducer from '@frontend/modules/user/reducers/usersReducer';
import {
  structuresReducer,
  dropdownStructuresReducer,
  selectionStructuresReducer,
} from '@frontend/modules/structure/reducers/structuresReducer';
import sensorsReducer from '@frontend/modules/sensor/reducers/sensorsReducer';
import sensorTypesReducer from '@frontend/modules/sensor/reducers/sensorTypesReducer';
import {
  machinesReducer,
  machineStatesReducer,
  singleMachineReducer,
} from '@frontend/modules/machine/reducers/machinesReducer';
import machineTypesReducer from '@frontend/modules/machine/reducers/machineTypesReducer';
import rolesReducer from '@frontend/modules/user/reducers/rolesReducer';
import {
  drawerReducer,
  selectionDrawerReducer,
  machineDrawerReducer,
  timespanStartReducer,
  timespanEndReducer,
  timespanDurationReducer,
  selectedTimespanTextReducer,
  selectedRelativeTimespanReducer,
  isRelativeTimespanReducer,
  isCustomTimePresetReducer,
  lastPulseUpdateReducer,
  structureInUseReducer,
  structureViewInUseReducer,
  machineInUseReducer,
  machineViewInUseReducer,
  tableStateReducer,
  machineStatusesReducer,
  dataLayerReducer,
  reasonsReducer,
  paletteTypeReducer,
  scrapTypesReducer,
  productionPulseFiltersReducer,
} from '@frontend/utils/UIReducer';
import { loadedModulesReducer } from '@frontend/loader/loaderReducers';

const allReducers = combineReducers({
  user: userReducer,
  users: usersReducer,
  structure: structuresReducer,
  dropdownStructures: dropdownStructuresReducer,
  sensors: sensorsReducer,
  sensorTypes: sensorTypesReducer,
  machine: singleMachineReducer,
  machines: machinesReducer,
  machineStates: machineStatesReducer,
  machineTypes: machineTypesReducer,
  roles: rolesReducer,
  drawerOpen: drawerReducer,
  selectionDrawerOpen: selectionDrawerReducer,
  machineDrawerOpen: machineDrawerReducer,
  selectionStructures: selectionStructuresReducer,
  timespanStart: timespanStartReducer,
  timespanEnd: timespanEndReducer,
  timespanDuration: timespanDurationReducer,
  isRelativeTimespan: isRelativeTimespanReducer,
  selectedRelativeTimespan: selectedRelativeTimespanReducer,
  selectedTimespanText: selectedTimespanTextReducer,
  isCustomTimePreset: isCustomTimePresetReducer,
  lastPulseUpdate: lastPulseUpdateReducer,
  loadedModules: loadedModulesReducer,
  structureInUse: structureInUseReducer,
  structureViewInUse: structureViewInUseReducer,
  machineInUse: machineInUseReducer,
  machineViewInUse: machineViewInUseReducer,
  tableState: tableStateReducer,
  machineStatuses: machineStatusesReducer,
  dataLayer: dataLayerReducer,
  reasons: reasonsReducer,
  scrapTypes: scrapTypesReducer,
  paletteType: paletteTypeReducer,
  productionPulseFilters: productionPulseFiltersReducer,
});

export default allReducers;
