import { useState, useEffect } from 'react';

/**
 * Hook for the structure dropdown.
 *
 * @param {Object[]} allStructures List of all structures within the system.
 */
const useStructureDropdown = (structures) => {
  const [options, setOptions] = useState([]);

  /**
   * Sort structures by path.
   *
   * @param {Object[]} _structures List of structures.
   */
  const sortStructures = (_structures) => {
    if (Array.isArray(_structures)) {
      return _structures.sort((a, b) => {
        if (a.path > b.path) return 1;
        if (a.path < b.path) return -1;
        return 0;
      });
    }
    return _structures;
  };

  /**
   * Gets trigerred when a new structures props is passed.
   */
  useEffect(() => {
    setOptions(sortStructures(structures));
  }, [structures]);

  return {
    options,
    setOptions,
  };
};

export default useStructureDropdown;
