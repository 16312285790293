/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import {
  FontAwesomeIcon
} from '@fortawesome/react-fontawesome';
import {
  faInfoCircle,
  faWaveSquare,
  faWifi
} from '@fortawesome/free-solid-svg-icons';
import {
  Avatar,
  Backdrop,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import FormControl    from '@material-ui/core/FormControl';
import Select         from '@material-ui/core/Select';
import MenuItem       from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Hidden         from '@material-ui/core/Hidden';
import LaunchIcon     from '@material-ui/icons/Launch';
import InsertChartIcon from '@material-ui/icons/InsertChart';

import { ROUTES } from '@frontend/constants';
import Time                   from '@frontend/components/Time';
import CustomLink             from '@frontend/components/CustomLink';
import BreadcrumbsNavigation  from '@frontend/components/BreadcrumbsNavigation';
import OeeHeaderCard          from '@frontend/components/OeeHeaderCard';
import Modal                  from '@frontend/components/Modal'; 
import StickyBar              from '@frontend/components/StickyBar';
import ShareButton            from '@frontend/components/ShareButton';
import LoadingIndicator       from '@frontend/components/LoadingIndicator';
import TimespanSelection      from '@frontend/components/TimespanSelection';
import useMachineKPIs   from '@frontend/hooks/useMachineKPIs';
import useMachineStats  from '@frontend/hooks/useMachineStats';
import loader     from '@frontend/loader/loader';
import useMachine           from '@frontend/modules/machine/hooks/useMachine';
import StatusesModalContent from '@frontend/modules/machine/machine.statuses/StatusesModalContent';
import MachinePulseBar      from '@frontend/modules/machine/MachinePulseBar';
import MachineIndicator     from '@frontend/modules/machine/MachineIndicator';
import MachineStatusChart   from '@frontend/modules/machine/MachineStatusChart';
import PartsInfoCard        from '@frontend/modules/machine/PartsInfoCard';
import SensorIndicator      from '@frontend/modules/sensor/SensorIndicator';
import StaticReason         from '@frontend/modules/machine/StaticReason';
import TopJobs              from '@frontend/modules/machine/TopJobs';
import StaticReasonAlertBox from '@frontend/modules/machine/StaticReasonAlertBox';
import TopQualityLosses     from '@frontend/modules/quality/TopQualityLosses';
import QualityReportsList   from '@frontend/modules/quality/QualityReportsList';
import QualityReport        from '@frontend/modules/quality/components/QualityReport';
import useJobTracker        from '@frontend/modules/orders/hooks/useJobTracker';
import PastActiveJobs       from '@frontend/modules/orders/PastActiveJobs'
import TopReasons               from '@frontend/modules/reasons/TopReasons';
import StaticReasonAssignModal  from '@frontend/modules/reasons/StaticReasonAssignModal';
import useApi               from '@frontend/utils/useApi';
import { usePhrases }       from '@frontend/utils/usePhrases';
import useDateTimeFormat    from '@frontend/utils/useDateTimeFormat';
import utils                from '@frontend/utils/utils'
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  alert: {
    float: "center",
    textAlign: "center",
    alignItems: "center"
  },
  link: {
    display: 'flex',
    float: "right",
    margin: 'auto'
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  machinePulse: {
    padding: theme.spacing(2),
    width: '100%',
    textAlign: 'left',
    marginBottom: theme.spacing(2),
  },
  green: {
    color: '#3bb273',
  },
  red: {
    color: '#d62828',
  },
  yellow: {
    color: '#FED766',
  },
  chart: {
    padding: theme.spacing(2),
  },
  push: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
    },
  },
  lastTableCell: {
    borderBottom: 'none',
  },
  topCardsContainer: {
    margin: theme.spacing(1),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(0),
  },    
  comment: {
    marginLeft: theme.spacing(1),
    cursor: "pointer"
  },
  cardCell: {
    padding: theme.spacing(1.5),
  },
  stickyBarContainer: {
    marginTop: theme.spacing(0),
  },
  pulseGrid: {
    marginBottom: theme.spacing(3),
  },
  sticky: {
    position: 'fixed',
    left: 0,
    top: 64,
    width: '100%',
    zIndex: theme.zIndex.appBar,
    [theme.breakpoints.down('sm')]: {
      top: 56,
    },

  },
  backdrop: {
    zIndex: 1,
    position: 'fixed',
    margin: 'auto',
  },

  item1: {
    order: 1,
    [theme.breakpoints.down('sm')]: {
      order: 2,
    },
  },

  item2: {
    order: 2,
    [theme.breakpoints.down('sm')]: {
      order: 3,
    },
  },

  item3: {
    order: 3,
    [theme.breakpoints.down('sm')]: {
      order: 4,
    },
  },

  item4: {
    order: 4,
    [theme.breakpoints.down('sm')]: {
      order: 1,
      position: 'fixed',
      left: 0,
      top: 56,
      width: '100%',
      zIndex: theme.zIndex.appBar,

    },
  },

  item5: {
    order: 1,
    [theme.breakpoints.down('sm')]: {
      order: 2,
    },
  },

  item6: {
    order: 2,
    [theme.breakpoints.down('sm')]: {
      order: 1,
    },
  },
}));

export default function MachineOverview() {
  // ===== State Objects =====
  const [sticky, setSticky] = useState(false);
  const [stickySpeed, setStickySpeed] = useState("-");
  const [jobSection, setJobSection] = useState([]);
  const [machineActualCount, setMachineActualCount] = useState(0);
  const [totalReports, setTotalReports] = useState({});
  const [currentStateDuration, setCurrentStateDuration] = useState(0);
  const [speed, setSpeed] = useState('minutes');
  const [speedTable, setSpeedTable] = useState(<></>);
  const [staticReasonAssignModalOpen, setStaticReasonAssignModalOpen] = useState(false);
  const [staticReason, setStaticReason] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  // ===== Redux Selectors =====
  const machineStates = useSelector((state) => state.machineStates);
  const machineInUse = useSelector((state) => state.machineInUse);

  // ===== Imports =====
  const classes = useStyles();
  const phrases = usePhrases().phrases();
  const { machineId } = useParams();
  const api = useApi();
  const {
    machine,
    sensors,
    isLoadingMachines,
    refreshPulse,
    getMachineStatesTimeframe,
    getMachineStatesTimeframeRelative,
  } = useMachine(machineId);
  const { moduleLoaded } = loader();
  const {
    formatDate,
    toLocaleNumber,
  } = useDateTimeFormat();
  const {
    setMachineToEngage,
    jobTracker
  } = useJobTracker(totalReports);
  const {
    getMachineAvailability,
    getMachinePerformance,
    getMachineQuality,
    getMachineOEE,
    getAvgPartsPerMinute,
  } = useMachineKPIs();
  const {
    getMachineLastState,
  } = useMachineStats();

  // ===== Local Variables =====
  const machineStatesPulse = machineStates[machine.id] ? machineStates[machine.id].states : [];
  const machineCity = machine.structure ? machine.structure.city : phrases.forms.shared.fields.none;
  const state = getMachineLastState(machine, machineStates);
  const totalParts = state ? state.actualCount : 0;
  const config = machine && machine.config ? machine.config.targetCycleTime : 0;

  // ===== Effects =====
  /**
   *  Reload every 60 min
   */
  useEffect(() => {
    const interval = setInterval(() => {
      window.location.reload(false);
    // min  s    ms
    }, 1*3600000);
    return () => {
      clearInterval(interval);
    };
  }, []);

   /**
   *  Set the Jobs of the given Machine
   */
  useEffect(() => {
    populateJobSection();
    renderSpeed(jobTracker?.targetCycleTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [machineStates, machine, jobTracker, config, currentStateDuration])

  useEffect(() => {
    setCurrentStateDuration(
      Math.round(
        moment(new Date())
        .diff(state.time, 'minutes', true)
        )
      );
  }, [state]);

  useEffect(() => {
    api(`/api/machines/${machineInUse}?populate=structure`, {
      method: 'get',
    }).then((response) => {
      if (response.status === 200 || response.status === 304) {
        setMachineToEngage(response.data);
      }
    }).catch((error) => {
      console.log(error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [machineInUse, machineId])

  useEffect(() => {
    const header = document.getElementById('myHeader');
    const sticky = header.offsetTop;
    const scrollCallBack = window.addEventListener('scroll', () => {
      if (window.pageYOffset + 64 > sticky) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    });
    return () => {
      window.removeEventListener('scroll', scrollCallBack);
    };
  }, []);

  // ===== Helper Methods =====
  const renderSpeed = (activeTargetCycleCount) => {

    if(activeTargetCycleCount !== undefined){
      // Set the speed of the active Job
      setSpeedTable(
        <TableCell className={`${classes.lastTableCell} ${classes.cardCell}`} align="right">
          {speed === 'minutes' ?
          `${toLocaleNumber(utils.cycleTimeToPartsPerMinute(jobTracker.speedPerMachine?.[machine.id]))} ${phrases.modules.machine.partsPerMinute} (${toLocaleNumber(utils.cycleTimeToPartsPerMinute(activeTargetCycleCount))}  ${phrases.modules.machine.partsPerMinute})`
          : `${toLocaleNumber(utils.cycleTimeToSecondsPerPart(jobTracker.speedPerMachine?.[machine.id]))} ${phrases.modules.machine.secPerPartString} (${toLocaleNumber(utils.cycleTimeToSecondsPerPart(activeTargetCycleCount))} ${phrases.modules.machine.secPerPartString})`}
        </TableCell>
      );
      setStickySpeed(
        speed === 'minutes' ?
          `${toLocaleNumber(utils.cycleTimeToPartsPerMinute(jobTracker.speedPerMachine?.[machine.id]))} ${phrases.modules.machine.partsPerMinute} (${toLocaleNumber(utils.cycleTimeToPartsPerMinute(activeTargetCycleCount))}  ${phrases.modules.machine.partsPerMinute})`
          : `${toLocaleNumber(utils.cycleTimeToSecondsPerPart(jobTracker.speedPerMachine?.[machine.id]))} ${phrases.modules.machine.secPerPartString} (${toLocaleNumber(utils.cycleTimeToSecondsPerPart(activeTargetCycleCount))} ${phrases.modules.machine.secPerPartString})`
      )
    } else {
      setSpeedTable(
        <TableCell className={`${classes.lastTableCell} ${classes.cardCell}`} align="right">
            {speed === 'minutes' ?
              `${toLocaleNumber(getAvgPartsPerMinute(machine, machineStates))} ${phrases.modules.machine.partsPerMinute} (${toLocaleNumber((60 / config).toFixed(2))} ${phrases.modules.machine.partsPerMinute})`
            : `${toLocaleNumber(getPartsPerSecond(totalParts, currentStateDuration * 60, config))} (${toLocaleNumber(config)} ${phrases.modules.machine.secPerPart})`}
        </TableCell>
      );
      setStickySpeed(
        speed === 'minutes' ?
              `${toLocaleNumber(getPartsPerMinute(currentStateDuration, totalParts, config))} (${toLocaleNumber((60 / config).toFixed(2))} ${phrases.modules.machine.partsPerMinute})`
            : `${toLocaleNumber(getPartsPerSecond(totalParts, currentStateDuration * 60, config))} (${toLocaleNumber(config)} ${phrases.modules.machine.secPerPart})`
      );
    }
  }
  /**
   * Create the Job fields
   */
  const populateJobSection = () => {
    var tmp = []
    if(Object.keys(jobTracker).length !== 0){
      tmp.push(
        <Grid item xs={12} md={6}>
          <TopJobs
            jobTracker={jobTracker}
          />
        </Grid>)
    }
    setJobSection(tmp);
  }

  function getRssiString(rssi) {
    if (rssi < -80) {
      return phrases.modules.sensor.rssiWeak;
    }
    if (rssi <= -70 && rssi > -80) {
      return phrases.modules.sensor.rssiFair;
    }
    if (rssi <= -50 && rssi > -70) {
      return phrases.modules.sensor.rssiGood;
    }
    return phrases.modules.sensor.rssiExcellent;
  }

  function getRssiClassname(rssi) {
    if (rssi == null) {
      return classes.red;
    }

    if ((rssi < -80) || (rssi <= -70 && rssi > -80)) {
      return classes.yellow;
    }

    return classes.green;
  }

  function getPartsPerSecond(totalParts, seconds, config) {
    if (totalParts === 0) {
      return `0 ${phrases.modules.machine.secPerPart} (${config} ${phrases.modules.machine.secPerPart})`;
    }
    if(isNaN(config) || isNaN((seconds / totalParts))){
      return "---";
    }
    const partsString = `${Math.abs((seconds / totalParts)).toFixed(2)} ${phrases.modules.machine.secPerPart}`;
    return partsString;
  }

    function getPartsPerMinute(stateDuration, parts, config) {

    if(isNaN(config) || isNaN(parts) || isNaN(config)){
      return "---";
    }
    const partsPerMinute = (parts / stateDuration);
    const partsPerMinuteString = `${Math.abs(partsPerMinute).toFixed(2)} ${phrases.modules.machine.partsPerMinute}`;
    return partsPerMinuteString;
  }

  function printSensorsList(sensors) {
    return sensors.map((sensor) => (

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <ListItem dense>
            <ListItemAvatar>
              <Avatar>
                <FontAwesomeIcon
                  icon={faWaveSquare}
                  size="lg"
                  className={sensor.online ? classes.green : classes.red}
                />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={sensor.alias}
              secondary={(
                <>
                  <Typography display="block" variant="overline">
                    {sensor.type.name}
                  </Typography>
                  <Typography variant="caption" display="block" className={sensor.online ? classes.green : classes.red}>
                    {sensor.online
                      ? phrases.modules.machine.online
                      : `${phrases.modules.machine.lastSeen}: ${sensor.lastSeenAt ? formatDate(sensor.lastSeenAt) : phrases.modules.machine.never}`}
                  </Typography>

                </>
              )}
            />
          </ListItem>
        </Grid>

        <Grid item xs={6}>
          <ListItem dense>
            <ListItemAvatar>
              <Avatar>
                <FontAwesomeIcon
                  icon={faWifi}
                  size="lg"
                  className={sensor.rssi ? getRssiClassname(sensor.rssi.rssi) : classes.red}
                />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={sensor.ssid ? sensor.ssid.ssid : phrases.modules.sensor.notConnected}
              secondary={(
                <>
                  <Typography display="block" variant="overline">
                    {sensor.rssi ? sensor.rssi.rssi : 0}
                    {' '}
                    dBm
                  </Typography>
                  <Typography variant="caption" display="block" className={sensor.rssi ? getRssiClassname(sensor.rssi.rssi) : classes.red}>
                    {

                      sensor.rssi

                        ? getRssiString(sensor.rssi.rssi)

                        : phrases.modules.sensor.noSignal
                    }

                  </Typography>

                </>
              )}
            />
          </ListItem>
        </Grid>
      </Grid>
    ));
  }

  const handleCloseModal = () => {
    setModalOpen(false);
  }
  const handleChange = (event) => {
    setSpeed(event.target.value);
  };
  const disableChart = true;
  // ===== Return =====
  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Hidden xsDown>
          <Grid item sm={12}>
            <Typography variant="body1" display="block" color="textSecondary">
              {machine.structure && (
                <BreadcrumbsNavigation
                  selectedStructure={machine.structure}
                  selectedMachine={machine}
                />
              )}
            </Typography>
          </Grid>
        </Hidden>

        <Grid container spacing={4} className={classes.topCardsContainer} component={Paper} variant="outlined" square>

          <Grid item xs={12} md={4} className={classes.item1}>
            <Table aria-label="simple table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.cardCell}>
                    <Typography variant="body1" align="left">
                      {machine.name}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.cardCell}>
                    <Typography variant="body1" align="right">
                      { machine.config?.externalLink && (
                      <IconButton href={machine.config.externalLink} target="_blank" variant="outlined" size="small">
                        <LaunchIcon fontSize={'small'}/>
                      </IconButton>)}
                      <ShareButton route={ROUTES.MACHINE_OVERVIEW} id={machine.id} />
                      { machine.config?.maxCycleTime && !disableChart && (
                      <CustomLink className={classes.link} id={machine.id} object="machine" to={ROUTES.MACHINE_PERFORMANCE}>
                        <InsertChartIcon fontSize={'medium'}/>
                      </CustomLink>
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>

                <TableRow>
                  <TableCell className={classes.cardCell} align="left">
                    {' '}
                    {phrases.forms.shared.fields.type}
                    :
                    {' '}
                  </TableCell>
                  <TableCell className={classes.cardCell} align="right">
                    {' '}
                    {machine.type ? machine.type.name : phrases.forms.shared.fields.none}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell className={classes.cardCell} align="left">
                    {' '}
                    {phrases.forms.shared.fields.city}
                    :
                    {' '}
                  </TableCell>
                  <TableCell className={classes.cardCell} align="right">
                    {' '}
                    {machineCity}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell className={`${classes.lastTableCell} ${classes.cardCell}`} align="left">
                    {' '}
                    {machineCity}
                    {' '}
                    {phrases.misc.time}
                    :
                    {' '}
                  </TableCell>
                  <TableCell className={`${classes.lastTableCell} ${classes.cardCell}`} align="right">
                    {' '}
                    {machine.structure ? <Time timezone={machine.structure.timezone} /> : phrases.forms.shared.fields.none}
                  </TableCell>
                </TableRow>

              </TableBody>
            </Table>
          </Grid>

          <Grid item xs={12} md={4} className={classes.item2}>

            <OeeHeaderCard
              oee={getMachineOEE(machine, machineStates)}
              availability={getMachineAvailability(machine, machineStates)}
              performance={getMachinePerformance(machine, machineStates)}
              quality={getMachineQuality(machine, machineStates)}
            />
          </Grid>
          <Grid item xs={12} md={4} className={classes.item3}>
            <PartsInfoCard
              machine={machine}
              setMachineActualCount={setMachineActualCount}
            />
            <Table>
        <TableBody>
          <TableRow>
            <TableCell className={`${classes.lastTableCell} ${classes.cardCell}`} align="left">
              <FormControl>
                <Select
                  labelId="select-label"
                  id="select-label"
                  value=""
                  onChange={handleChange}
                  displayEmpty
                  disableUnderline
                >
                  <MenuItem value="" disabled>
                    <Typography variant="body2">
                      {phrases.modules.machine.speedTarget}
                    </Typography>
                  </MenuItem>
                  <MenuItem value="minutes">
                    {' '}
                    <Typography variant="body2">{phrases.modules.machine.partsPerMinute}</Typography>
                  </MenuItem>
                  <MenuItem value="seconds"><Typography variant="body2">{phrases.modules.machine.secPerPart}</Typography></MenuItem>
                </Select>
              </FormControl>
            </TableCell>
            {speedTable}
          </TableRow>
        </TableBody>
      </Table>
          </Grid>

          {/* <MachineAvailabilityHeaderCard
            machine={machine}
            runtime={getMachineRuntimePercentage(machine, machineStates)}
            downtime={getMachineDowntimePercentage(machine, machineStates)}
            machineNotRunningPercentage={machineNotRunningPercentage}
            machineRunningPercentage={machineRunningPercentage}
          /> */}

          <Grid item xs={12} className={(classes.stickyBarContainer, classes.item4)} style={{ padding: 0 }}>
            <div id="myHeader" className={`${sticky ? classes.sticky : ''}`}>
              <StickyBar
                state={getMachineLastState(machine, machineStates)}
                speed={stickySpeed}
              />
            </div>
            {sticky ? (
              <div style={{ height: 40 }} />
            ) : ''}
          </Grid>
        </Grid>
        <StaticReasonAlertBox
          staticReason={staticReason}
          setStaticReason={setStaticReason}
          machine={machine}
        />
        <Hidden xsDown>
          <Grid item xs={12} className={classes.pulseGrid}>
            <Paper className={classes.paper} variant="outlined" square>
              <div className={classes.machinePulse}>
                  <MachineIndicator machine={machine} />
                  {sensors.map((sensor) => (sensor.machine.name === machine.name
                    ? <SensorIndicator sensor={sensor} /> : ''))}
                    <FontAwesomeIcon 
                      className={classes.comment}
                      icon={faInfoCircle} 
                      onClick={() => {
                          setModalOpen(true)
                    }}/> 
                  
                  <StaticReason
                    setStaticReasonAssignModalOpen={setStaticReasonAssignModalOpen}
                    mb
                  />

                  {moduleLoaded('quality') && (
                    <QualityReport
                      refreshPulse={refreshPulse}
                      machine={machine}
                      actualCount={machineActualCount}
                      mb
                    />
                  )}

                <MachinePulseBar
                  machine={machine}
                  data={machineStatesPulse}
                  refreshPulse={refreshPulse}
                />
              </div>
            </Paper>
          </Grid>
        </Hidden>
        <Grid container spacing={2}>
          <MachineStatusChart
          machine={machine}
          />

          {/* Active Job */}
          {moduleLoaded('orders')
            && jobSection}

          {/* Reasons */}
          {moduleLoaded('reasons')
            && (
              <Grid item xs={12} md={6} >
                <TopReasons
                  machines={machine.id ? [machine.id] : []}
                />
              </Grid>
            )}

          {/* Quality Losses */}
          {moduleLoaded('quality')
            && (
              <Grid item xs={12} md={6}>
                <TopQualityLosses
                  machines={machine.id ? [machine.id] : []}
                />
              </Grid>
            )}

          {/* Sensors */}
          <Grid item xs={12} md={6} >
            <Paper className={classes.paper} variant="outlined" square>
              <Table aria-label="simple table" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.cardCell}>
                      <Typography variant="body1" align="left">
                        {phrases.forms.shared.fields.sensors}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.cardCell}>
                      <Typography variant="body1" align="right">
                        {sensors.length}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>

                  <TableRow>
                    <List dense>
                      {printSensorsList(sensors)}
                      <Divider variant="inset" component="li" />
                    </List>
                  </TableRow>

                  <TableRow />

                </TableBody>
              </Table>
            </Paper>
          </Grid>

          {/* Past active Jobs */}
          {moduleLoaded('orders')
            && (
              <Grid item xs={12} md={6} >
                <PastActiveJobs
                  machines={machine.id ? [machine.id] : []}
                />
              </Grid>
            )}

          {/* QualityReports */}
          {moduleLoaded('quality')
            && (
              <Grid item xs={12} md={6}>
                <QualityReportsList
                  machine={machine}
                  actualCount={machineActualCount}
                  date
                  parts
                  refreshPulse={refreshPulse}
                  setTotalReports={setTotalReports}
                />
              </Grid>
            )}
        </Grid>
      </Grid>
      <Backdrop classes={{ root: classes.backdrop }} open={isLoadingMachines}>
        <LoadingIndicator light text={phrases.modules.machine.loadingMachine} />
      </Backdrop>
      {staticReasonAssignModalOpen && (
          <StaticReasonAssignModal
            open={staticReasonAssignModalOpen}
            staticReason={staticReason}
            handleCloseModal={() => setStaticReasonAssignModalOpen(false)}
            machine={machine}
            setStaticReason={setStaticReason}
          />
        )}
      <Modal
        modalTitle={phrases.modules.machine.legendModalTitle}
        open={
            modalOpen
        }
        className={classes.statusesModal}
        handleClose={handleCloseModal}
        content={(
            <StatusesModalContent
            machineId={machineId} />                   
        )}
      />

      <TimespanSelection
        selectTimeframe={getMachineStatesTimeframe}
        selectTimeframeRelative={getMachineStatesTimeframeRelative}
        selectTimeframeCustom={getMachineStatesTimeframe}
        disabled={isLoadingMachines}
      />
    </div>
  );
}
