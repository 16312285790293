import React, {useLayoutEffect, useRef} from 'react';
import { useSelector } from 'react-redux';
import {
    Paper,
    useTheme
} from '@material-ui/core';
import * as am5             from '@amcharts/amcharts5';
import * as am5xy           from "@amcharts/amcharts5/xy";
// import am5themes_Animated   from "@amcharts/amcharts5/themes/Animated";
import am5locales_en_US     from "@amcharts/amcharts5/locales/en_US";
import am5locales_de_DE     from "@amcharts/amcharts5/locales/de_DE";

import LoadingIndicator     from '@frontend/components/LoadingIndicator';
import usePartsChartModal   from '@frontend/modules/machine/hooks/usePartsChartModal';
import { usePhrases }       from '@frontend/utils/usePhrases';
import useDateTimeFormat    from '@frontend/utils/useDateTimeFormat';

const PartsChartModalContent = (props) => {
    const {
        machineId,
        targetCycleTime,
        height,
        fullyFeatured,                      // 0 - no features, 1 - partly Featured, 2 - fully featured
        isPartsPerMinute,
        customRoot,
        customTimespanStart,
        customTimespanEnd,
        calculationFactor,
        customStyle
    } = props;

    // ===== Imports =====
    const chartRef = useRef(null);
    const seriesRef = useRef(null);
    const theme = useTheme();
    const phrases = usePhrases().phrases();
    const { toLocaleNumber } = useDateTimeFormat();
    const {
        parts,
        maxCount,
        isLoadingParts
    } = usePartsChartModal(machineId, targetCycleTime, isPartsPerMinute, customTimespanStart, customTimespanEnd, calculationFactor, fullyFeatured);
    // ===== Redux Selectors =====
    const loggedInUser = useSelector((state) => state.user);

    useLayoutEffect(() => {
        // ==== Common Chart Settings ====
        am5.addLicense("AM5C377488971");
        let root = am5.Root.new(customRoot ?? "chartdiv");
        // root.setThemes(
        //     [
        //         am5themes_Animated.new(root)
        //     ]
        // );
        // Set Language depending on user's preference
        root.locale = loggedInUser.lang === "DE" ?
             am5locales_de_DE
            :am5locales_en_US;

        root.dateFormatter.setAll({
            dateFormat: "yyyy-MM-dd/HH:mm:ss",
            dateFields: ["valueX"]
        });
        root.interfaceColors.set("grid", am5.color(theme.palette.text.primary));
        root.interfaceColors.set("text", am5.color(theme.palette.text.primary));
        // ==== Specific Chart Settings ====
        let chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                panX: fullyFeatured === 2,
                panY: fullyFeatured === 2,
                pinchZoomX: fullyFeatured === 2,
        }));
        if(fullyFeatured === 2){
            chart.set("wheelX", "panX");
            chart.set("wheelY", "zoomX");
        }
        chart.set("wheelable", false);
        chart.set("forceInactive", true);

        // Customize the x axis, added the correct base interval and allowed grouping
        let xAxisRenderer = am5xy.AxisRendererX.new(root, {});
        if(fullyFeatured < 1){
            xAxisRenderer.labels.template.set("visible", false);
        }
        var xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
            maxDeviation: 0.1,
            groupData: true,
            zoomX: fullyFeatured === 2 ,
            wheelable: fullyFeatured === 2,
            baseInterval: {
              timeUnit: "second",
              count: 15
            },
            renderer: xAxisRenderer,

            tooltip: am5.Tooltip.new(root, {})
        }));
        var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            maxDeviation: 0.2,
            min: 0,
            max: maxCount+1,
            renderer: am5xy.AxisRendererY.new(root, {})
        }));
        // Set Axis Label for the y axis
        if(fullyFeatured >= 1){

            yAxis.children.unshift(
                am5.Label.new(root, {
                rotation: -90,
                text: isPartsPerMinute? calculationFactor=== 60 ? phrases.modules.performance.chartPartsProducedPartsHour: phrases.modules.performance.chartPartsProduced: phrases.modules.performance.chartPartsProducedSecPart,
                y: am5.p50,
                centerX: am5.p50
                })
            );
        }
        var series = chart.series.push(am5xy.LineSeries.new(root, {
            // minBulletDistance: 10,
            connect: true,
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "actualCount",
            valueXField: "formattedTime",
            wheelable: false,
            tooltip: am5.Tooltip.new(root, {
              pointerOrientation: "horizontal",
              labelText: `{valueY} ${isPartsPerMinute? phrases.modules.performance.chartParts: phrases.modules.performance.chartPartsSec}`
            })
        }));
        series.fills.template.setAll({
            fillOpacity: 0.2,
            visible: true
        });

        series.strokes.template.setAll({
            strokeWidth: fullyFeatured >=1 ? 2: 1
        });

        series.data.processor = am5.DataProcessor.new(root, {
            dateFormat: "yyyy-MM-dd/HH:mm:ss",
            dateFields: ["formattedTime"]
        });
        series.data.setAll(parts);

        if(fullyFeatured===2){
            var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
            cursor.lineX.set("forceHidden", true);
            cursor.lineY.set("forceHidden", true);
            // cursor.lineY.set("visible", false);
            chart.set("scrollbarX", am5.Scrollbar.new(root, {
                orientation: "horizontal"
            }));
        }
        var seriesRangeDataItem = yAxis.makeDataItem({ value: (60/parseFloat(targetCycleTime))*(calculationFactor?? 1), endValue: 0 });
        var seriesRange = series.createAxisRange(seriesRangeDataItem);
        seriesRange.fills.template.setAll({
            visible: true,
            opacity: 0.3
        });

        seriesRange.fills.template.set("fill", am5.color(theme.palette.text.primary));
        seriesRange.strokes.template.set("stroke", am5.color(theme.palette.text.primary));

        seriesRangeDataItem.get("grid").setAll({
            strokeOpacity: 1,
            visible: true,
            stroke: am5.color(theme.palette.text.primary),
            strokeDasharray: [2, 2]
        })

        seriesRangeDataItem.get("label").setAll({
            location:0,
            visible:true,
            text: `${phrases.modules.performance.chartTargetCycleTime}: ${ toLocaleNumber((60/parseFloat(targetCycleTime)*(calculationFactor?? 1)).toFixed(2))} ${isPartsPerMinute? calculationFactor === 60 ? phrases.modules.performance.chartPartsPartsHour: phrases.modules.performance.chartPartsPartsMin : phrases.modules.performance.chartPartsSecPart}`,
            inside:true,
            centerX:0,
            centerY:am5.p100,
            fontWeight:"bold"
        })
        // Wait for data to be loaded before showing the chart
        if(!isLoadingParts && fullyFeatured === 2){
            chart.appear(1000, 100);
        }
        chartRef.current = chart;
        seriesRef.current = series;

        return () => {
            root.dispose();
          };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [maxCount, isPartsPerMinute, calculationFactor]);

    useLayoutEffect(() => {
        seriesRef.current.data.setAll(parts);
        seriesRef.current.appear();
    }, [parts]);

    return (
        <>
        <Paper elevation={0} square>
            {isLoadingParts && fullyFeatured === 2 ?
                <LoadingIndicator
                    size={60}
                    thickness={3.5}
                    text={phrases.modules.performance.isLoadingParts}
                /> :
                ""
            }
            <div id={customRoot ?? "chartdiv"} style={{...customStyle, position: 'relative', height: height? height: "550px"}} ></div>
        </Paper>
        </>
     );
}



export default PartsChartModalContent;
