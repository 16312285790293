import React, { useState, useEffect }from 'react';
import { useSelector } from 'react-redux';
import {
    Collapse,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
  } from '@material-ui/core';
import { makeStyles }   from '@material-ui/core/styles';
import ExpandLess       from '@material-ui/icons/ExpandLess';
import ExpandMore       from '@material-ui/icons/ExpandMore';
import { faClock }          from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon }  from '@fortawesome/react-fontawesome';

import KPIChart               from '@frontend/components/KPIChart';
import CustomLink             from '@frontend/components/CustomLink';
import { ROUTES } from '@frontend/constants';
import useStatesFilter        from '@frontend/hooks/useStatesFilter';
import useMachineStatusChart  from '@frontend/modules/machine/hooks/useMachineStatusChart';
import useDateTimeFormat      from '@frontend/utils/useDateTimeFormat';
import { usePhrases }         from '@frontend/utils/usePhrases';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  link: {
    color: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      float: 'none',
      whiteSpace: 'pre-line',
      paddingTop: theme.spacing(1),
    },
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  chart: {
    padding: theme.spacing(2),
  },
  collapseCell: {
    cursor: 'pointer',
  },
}));

const MachineStatusChart = (props) => {
  const {          
      machine
  } = props;

  // ===== States =====
  const [machineStatusTimeDistribution, setMachineStatusTimeDistribution] = useState(true);
  const [collapseStatuses, setCollapseStatuses] = useState({});
  const [isMachineView, setIsMachineView] = useState(false);
  const [statuses, setStatuses] = useState([]);
  // ===== Imports =====
  const classes = useStyles();
  const phrases = usePhrases().phrases();
  const {
    stateText,
    stateColor
  } = useStatesFilter();    
  const {
    formatHoursMinutesDuration
  } = useDateTimeFormat();    
  const {
    machineStatuses,
    machineNames,
    statusKPIs,
    machineIds,
    structure
  } = useMachineStatusChart(machine);
  // ===== Redux Selectors =====
  const timespanDuration = useSelector((state) => state.timespanDuration);

  // ===== Effects =====
  useEffect(() => {
    const usedStatuses = Object.keys(machineStatuses).filter((status) => {
      // Does the status appear in any state?
      if (statusKPIs[status]?.total > 0) { return status; }
      return null;
    });
    setStatuses(usedStatuses);
    // eslint-disable-next-line react-hooks/exhaustive-deps  
  }, [statusKPIs, machineStatuses]);

  useEffect(() => {
    if(machine !== undefined && !_.isEmpty(machine)){
      setIsMachineView(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps  
  }, [machine]);
  // ===== Local Variables =====
  const chartConfigAvailability = {
      type: 'bar',
      data: {
        labels: statuses.map((status) => `${stateText(status)}(%)`),
        datasets: [{
          backgroundColor: statuses.map((status) => stateColor(status)),
          borderColor: statuses.map((status) => stateColor(status)),
          data: statuses.map((status) => {
            return Math.round(statusKPIs[status]?.total/(timespanDuration * machineIds.length * 60) * 100);
          }),
        }],
      },
      options: {    
        legend: {
          display: false,
        },
        weight: 0.5,
        animation: {
          duration: 0,
        },
        // responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            ticks: {
              min: 0,
              max: 100,
              stepSize: 20,
            },
          }],
        },
      },
  };

      
  return ( 
      <Grid item xs={12} md={6}>
      <Paper className={classes.paper} variant="outlined" square>
        <List>

          <ListItem alignItems="center">
            <ListItemAvatar>
              <FontAwesomeIcon
                icon={faClock}
                size="2x"
              />
            </ListItemAvatar>
            <Typography variant="body1">
              {phrases.misc.machineStatus}
              {' '}
            (%)
          </Typography>
          </ListItem>
          <Divider variant="inset" component="li" />
        </List>
        <div className={classes.chart}>
          <KPIChart chartConfig={chartConfigAvailability} />
        </div>
        <Paper variant="outlined" square>
          <ListItem button onClick={() => setMachineStatusTimeDistribution(!machineStatusTimeDistribution)}>
            <ListItemText primary={`${phrases.misc.machineStatus} (${phrases.modules.machine.statusTime})`} />
            {machineStatusTimeDistribution ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={machineStatusTimeDistribution} timeout="auto" unmountOnExit>
            <Table aria-label="simple table">
              <TableBody>
                {statuses.map((status) => {
                  return (
                    <>
                      <TableRow>
                        <TableCell align="left">
                          {stateText(status)}
                        :
                        </TableCell>
                        <TableCell align="right">
                          <span>
                            {formatHoursMinutesDuration(statusKPIs[status]?.total)}
                          </span>                      
                        </TableCell>
                        {!isMachineView && <TableCell 
                          align="right" 
                          className={classes.collapseCell} 
                          onClick={() => setCollapseStatuses(
                            {
                              ...collapseStatuses, 
                              [status]: !collapseStatuses[status]
                            })
                          }>
                          {collapseStatuses[status] ? <ExpandLess /> : <ExpandMore />}
                        </TableCell>
                        } 
                      </TableRow>                      
                      {collapseStatuses[status] && !isMachineView &&
                      <>
                        {statusKPIs[status].statusPerMachine
                          .sort((a, b) => b[status] - a[status])
                          .map((detail) => {                              
                            if(detail[status] === undefined || detail[status] === null) return <></>;
                              return (                                
                                <TableRow>
                                  <TableCell align="left">                                    
                                    <CustomLink className={classes.link} id={detail.machine} object="machine" to={ROUTES.MACHINE_OVERVIEW}>
                                      <b>{machineNames[detail.machine]?.name}</b>
                                    </CustomLink>                                      
                                    {" "}
                                    {
                                      structure.id !== machineNames[detail.machine]?.structure?.id && <>
                                      (<CustomLink className={classes.link} id={machineNames[detail.machine]?.structure?.id} object="structure" to={ROUTES.STRUCTURE_OVERVIEW}>
                                        {machineNames[detail.machine]?.structure?.businessId}
                                      </CustomLink>)
                                      </>
                                      
                                    }                                    
                                  </TableCell>
                                  <TableCell align="right" colSpan={1}>
                                    <span>
                                      {formatHoursMinutesDuration(detail[status])}
                                    </span>
                                  </TableCell>
                                  <TableCell align="right">
                                  </TableCell>
                                  </TableRow>
                              )
                          })
                        }
                      </>
                    }
                  </>
                )})}
              </TableBody>
            </Table>
          </Collapse>
        </Paper>
      </Paper>
    </Grid>
  );
}
 
export default MachineStatusChart;