import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Grid,
} from '@material-ui/core';
import ErrorBoundary from '@frontend/utils/ErrorBoundary';
import StructuresManagement from '@frontend/modules/structure/StructuresManagement';
import MachinesManagement from '@frontend/modules/machine/MachinesManagement';
import SensorsView from '@frontend/modules/sensor/SensorsManagement';
import UsersManagement from '@frontend/modules/user/UsersManagement';
import RolesView from '@frontend/modules/user/RolesView';
import PrivateRoute from '@frontend/components/PrivateRoute';
import useSecurity from '@frontend/utils/useSecurity';
import SensorTypesView from '@frontend/modules/sensor/SensorTypesList';
import MachineTypesView from '@frontend/modules/machine/MachineTypesView';
import AuthorizedRoute from '@frontend/components/AuthorizedRoute';
import MachineOverview from '@frontend/modules/machine/MachineOverview';
import StructureOverview from '@frontend/modules/structure/StructureOverview';
import AdvancedReportingView from '@frontend/modules/advanced_reporting/AdvancedReportingView'
import KnowledgeView from '@frontend/modules/knowledge/KnowledgeView'
import InfoView from '@frontend/modules/info/InfoView'
import PageNotFound from '@frontend/components/404';
import ModuleComponent from '@frontend/loader/ModuleComponent';
import { ROUTES } from '@frontend/constants';
import ScrollToTop from '@frontend/components/ScrollToTop';
import MachineStatusesManagement from '@frontend/modules/machine/machine.statuses/MachineStatusesManagement';
import OrdersView from '@frontend/modules/orders/OrdersView';
import JobView from '@frontend/modules/orders/JobView';
import useConditionalRender from '@frontend/utils/useConditionalRender';
import loader from '@frontend/loader/loader';
import MachinePerformanceOverview from '@frontend/modules/machine_performance/MachinePerformanceOverview';
import JobsManagementView from '@frontend/modules/orders/JobsManagementView';
import SimpleMachineOverview from '@frontend/modules/simple_machine/SimpleMachineOverview';
import SimpleMachineOverview2 from '@frontend/modules/simple_machine2/SimpleMachineOverview2';
import MachineShiftManagement from '@frontend/modules/machine_shift/MachineShiftManagement';
const closedDrawerWidth = 56;

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: theme.spacing(0, 0.5),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    margin: theme.spacing(4),
    marginRight: theme.spacing(4) + closedDrawerWidth,
    zIndex: 0,
    maxWidth: '100%',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1),
      marginTop: theme.spacing(2),
    },
  },
  pushDiv: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(9),
    },
    marginTop: theme.spacing(5),
  },
}));

/**
 * Main container for all of the components.
 */
const Content = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { getProfile } = useSecurity();
  const { conditionalRender } = useConditionalRender();
  const { moduleLoaded } = loader();
  const machineInUse = useSelector((state) => state.machineInUse);

  return (
    <Grid
      className={classes.content}
      style={{
        margin: (window.location.pathname.includes(ROUTES.TERMINAL_OVERVIEW) ? theme.spacing(1) : theme.spacing(4)),
        marginRight: (window.location.pathname.includes(ROUTES.TERMINAL_OVERVIEW) ? theme.spacing(1) : theme.spacing(4) + closedDrawerWidth),
      }}>
      <div className={classes.toolbar} id="back-to-top-anchor" />
      <Switch>

        <PrivateRoute
          exact
          path={ROUTES.HOME}
          component={() => (
            <ErrorBoundary>
              <Redirect to={`${getProfile().views.homeView}`} />
            </ErrorBoundary>
          )}
        />

        <PrivateRoute
          path={`${ROUTES.STRUCTURE_OVERVIEW}/:structureId`}
          component={() => (
            <ErrorBoundary>
              <StructureOverview />
            </ErrorBoundary>
          )}
        />

        <PrivateRoute
          path={ROUTES.STRUCTURE_OVERVIEW}
          component={() => (
            <ErrorBoundary>
              <StructureOverview />
            </ErrorBoundary>
          )}
        />
        <PrivateRoute
          path={ROUTES.ADVANCED_REPORTING_VIEW}
          component={() => (
            <ErrorBoundary>
              <AdvancedReportingView />
            </ErrorBoundary>
          )}
        />
        <PrivateRoute
          path={ROUTES.KNOWLEDGE}
          component={() => (
            <ErrorBoundary>
              <KnowledgeView />
            </ErrorBoundary>
          )}
        />
        <PrivateRoute
          path={ROUTES.INFO}
          component={() => (
            <ErrorBoundary>
              <InfoView />
            </ErrorBoundary>
          )}
        />
        <PrivateRoute
          path={ROUTES.STRUCTURES_MANAGEMENT}
          component={() => (
            <AuthorizedRoute resource="structures">
              <ErrorBoundary>
                <StructuresManagement />
              </ErrorBoundary>
            </AuthorizedRoute>
          )}
        />

        <PrivateRoute
          path={`${ROUTES.MACHINE_OVERVIEW}/:machineId`}
          component={() => (
            <ErrorBoundary>
              <MachineOverview />
            </ErrorBoundary>
          )}
        />

        <PrivateRoute
          path={ROUTES.MACHINE_OVERVIEW}
          component={() => (
            <ErrorBoundary>
              <MachineOverview />
            </ErrorBoundary>
          )}
        />

        <PrivateRoute
          path={ROUTES.MACHINES_MANAGEMENT}
          component={() => (
            <AuthorizedRoute resource="machines">
              <ErrorBoundary>
                <MachinesManagement />
              </ErrorBoundary>
            </AuthorizedRoute>
          )}
        />

        <PrivateRoute
          path={ROUTES.MACHINE_TYPES_MANAGEMENT}
          component={() => (
            <AuthorizedRoute resource="machine-types">
              <ErrorBoundary>
                <MachineTypesView />
              </ErrorBoundary>
            </AuthorizedRoute>
          )}
        />

        <PrivateRoute
          path={ROUTES.SENSORS_MANAGEMENT}
          component={() => (
            <AuthorizedRoute resource="sensors">
              <ErrorBoundary>
                <SensorsView />
              </ErrorBoundary>
            </AuthorizedRoute>
          )}
        />

        <PrivateRoute
          path={ROUTES.SENSOR_TYPES_MANAGEMENT}
          component={() => (
            <AuthorizedRoute resource="sensor-types">
              <ErrorBoundary>
                <SensorTypesView />
              </ErrorBoundary>
            </AuthorizedRoute>
          )}
        />

        <PrivateRoute
          path={ROUTES.USERS_MANAGEMENT}
          component={() => (
            <AuthorizedRoute resource="users">
              <ErrorBoundary>
                <UsersManagement />
              </ErrorBoundary>
            </AuthorizedRoute>
          )}
        />

        <PrivateRoute
          path={ROUTES.ROLES_MANAGEMENT}
          component={() => (
            <AuthorizedRoute resource="roles">
              <ErrorBoundary>
                <RolesView />
              </ErrorBoundary>
            </AuthorizedRoute>
          )}
        />

        {conditionalRender(moduleLoaded('andon-board'), (
          <PrivateRoute
            path={`${ROUTES.ANDON_BOARD}/:structureId`}
            component={() => (
              <AuthorizedRoute resource="structures">
                <ErrorBoundary>
                  <ModuleComponent path="andon-board/AndonBoard.js" />
                </ErrorBoundary>
              </AuthorizedRoute>
            )}
          />
        ))}

        {conditionalRender(moduleLoaded('andon-board'), (
          <PrivateRoute
            path={ROUTES.ANDON_BOARD}
            component={() => (
              <AuthorizedRoute resource="structures">
                <ErrorBoundary>
                  <ModuleComponent path="andon-board/AndonBoard.js" />
                </ErrorBoundary>
              </AuthorizedRoute>
            )}
          />
        ))}

        {conditionalRender(moduleLoaded('reporting'), (
          <PrivateRoute
            path={`${ROUTES.REPORTING_VIEW}/:structureId`}
            component={() => (
              <AuthorizedRoute resource="structures">
                <ErrorBoundary>
                  <ModuleComponent path="reporting/ReportingView.js" />
                </ErrorBoundary>
              </AuthorizedRoute>
            )}
          />
        ))}

        {conditionalRender(moduleLoaded('reporting'), (
          <PrivateRoute
            path={`${ROUTES.REPORTING_VIEW}/:structureId`}
            component={() => (
              <AuthorizedRoute resource="structures">
                <ErrorBoundary>
                  <ModuleComponent path="reporting/ReportingView.js" />
                </ErrorBoundary>
              </AuthorizedRoute>
            )}
          />
        ))}

        {conditionalRender(moduleLoaded('reporting'), (
          <PrivateRoute
            path={ROUTES.ADVANCED_REPORTING_VIEW}
            component={() => (
              <AuthorizedRoute resource="structures">
                <ErrorBoundary>
                  <ModuleComponent path="reporting/ReportingView.js" />
                </ErrorBoundary>
              </AuthorizedRoute>
            )}
          />
        ))}

        {conditionalRender(moduleLoaded('reasons'), (
          <PrivateRoute
            path={ROUTES.REASONS_MANAGEMENT}
            component={() => (
              <AuthorizedRoute resource="reasons">
                <ErrorBoundary>
                  <ModuleComponent path="reasons/ReasonsManagement.js" />
                </ErrorBoundary>
              </AuthorizedRoute>
            )}
          />
        ))}

        {conditionalRender(moduleLoaded('reasons'), (
          <PrivateRoute
            path={ROUTES.REASONS_CATEGORIES_MANAGEMENT}
            component={() => (
              <AuthorizedRoute resource="categories">
                <ErrorBoundary>
                  <ModuleComponent path="reasons/categories/CategoriesManagement.js" componentProps={{ categoryOf: 'REASONS' }} />
                </ErrorBoundary>
              </AuthorizedRoute>
            )}
          />
        ))}

        {conditionalRender(moduleLoaded('quality'), (
          <PrivateRoute
            path={ROUTES.SCRAP_TYPES_MANAGEMENT}
            component={() => (
              <AuthorizedRoute resource="scrap-types">
                <ErrorBoundary>
                  <ModuleComponent path="quality/ScrapTypesManagement.js" />
                </ErrorBoundary>
              </AuthorizedRoute>
            )}
          />
        ))}

        {conditionalRender(moduleLoaded('quality'), (
          <PrivateRoute
            path={ROUTES.SCRAP_TYPES_CATEGORIES_MANAGEMENT}
            component={() => (
              <AuthorizedRoute resource="categories">
                <ErrorBoundary>
                  <ModuleComponent path="reasons/categories/CategoriesManagement.js" componentProps={{ categoryOf: 'SCRAP_TYPES' }} />
                </ErrorBoundary>
              </AuthorizedRoute>
            )}
          />
        ))}

        <PrivateRoute
          path={ROUTES.MACHINE_STATUSES_MANAGEMENT}
          component={() => (
            <AuthorizedRoute resource="machine_statuses">
              <ErrorBoundary>
                <MachineStatusesManagement />
              </ErrorBoundary>
            </AuthorizedRoute>
          )}
        />

        {conditionalRender(moduleLoaded('terminal'), (
          <PrivateRoute
            path={`${ROUTES.TERMINAL_OVERVIEW}/:machineId`}
            component={() => (
              <ErrorBoundary>
                <ModuleComponent path="terminal/TerminalOverview.js" />
              </ErrorBoundary>
            )}
          />
        ))}

        {conditionalRender(moduleLoaded('terminal'), (
          <PrivateRoute
            path={ROUTES.APP_TERMINAL_OVERVIEW}
            component={() => (
              <ErrorBoundary>
                <ModuleComponent path="terminal/AppTerminalOverview.js" componentProps={{ machineInUse }} />
              </ErrorBoundary>
            )}
          />
        ))}

        {conditionalRender(moduleLoaded('terminal'), (
          <PrivateRoute
            path={`${ROUTES.APP_TERMINAL_OVERVIEW}/:machineId`}
            component={() => (
              <ErrorBoundary>
                <ModuleComponent path="terminal/AppTerminalOverview.js" />
              </ErrorBoundary>
            )}
          />
        ))}

        {conditionalRender(moduleLoaded('terminal'), (
          <PrivateRoute
            path={ROUTES.TERMINAL_OVERVIEW}
            component={() => (
              <ErrorBoundary>
                <ModuleComponent path="terminal/TerminalOverview.js" componentProps={{ machineInUse }} />
              </ErrorBoundary>
            )}
          />
        ))}

        {conditionalRender(moduleLoaded('schedule'), (
          <PrivateRoute
            path={ROUTES.SCHEDULE_MANAGEMENT}
            component={() => (
              <ErrorBoundary>
                <ModuleComponent path="schedule/ScheduleManagement.js" />
              </ErrorBoundary>
            )}
          />
        ))}

        {conditionalRender(moduleLoaded('schedule'), (
          <PrivateRoute
            path={ROUTES.SCHEDULES}
            component={() => (
              <ErrorBoundary>
                <ModuleComponent path="schedule/Timeline.js" />
              </ErrorBoundary>
            )}
          />
        ))}

        {conditionalRender(moduleLoaded('orders'), (
          <PrivateRoute
            path={ROUTES.JOB_MANAGEMENT}
            component={() => (
              <AuthorizedRoute resource="orders">
                <ErrorBoundary>
                  <JobsManagementView />
                </ErrorBoundary>
              </AuthorizedRoute>
            )}
          />
        ))}

        {conditionalRender(moduleLoaded('orders'), (
          <PrivateRoute
            exact
            path={ROUTES.ORDERS}
            component={() => (
              <AuthorizedRoute resource="orders">
                <ErrorBoundary>
                  <OrdersView />
                </ErrorBoundary>
              </AuthorizedRoute>
            )}
          />
        ))}

        {conditionalRender(moduleLoaded('orders'), (
          <PrivateRoute
            exact
            path={ROUTES.ORDERS_WITH_ID}
            component={() => (
              <AuthorizedRoute resource="orders">
                <ErrorBoundary>
                  <OrdersView />
                </ErrorBoundary>
              </AuthorizedRoute>
            )}
          />
        ))}

      

        {conditionalRender(moduleLoaded('orders'), (
          <PrivateRoute
            path={ROUTES.JOB}
            component={() => (
              <AuthorizedRoute resource="orders">
                <ErrorBoundary>
                  <JobView />
                </ErrorBoundary>
              </AuthorizedRoute>
            )}
          />
        ))}

        

        {conditionalRender(moduleLoaded('machine'), (
          <PrivateRoute
            path={ROUTES.MACHINE_PERFORMANCE+"/:machineId"}
            component={() => (
                <ErrorBoundary>
                  <MachinePerformanceOverview />
                </ErrorBoundary>
            )}
          />
        ))}
        {conditionalRender(moduleLoaded('machine'), (
          <PrivateRoute
            path={ROUTES.SIMPLE_MACHINE+"/:machineId"}
            component={() => (
                <ErrorBoundary>
                  <SimpleMachineOverview />
                </ErrorBoundary>
            )}
          />
        ))}
        {conditionalRender(moduleLoaded('machine'), (
          <PrivateRoute
            path={ROUTES.SIMPLE_MACHINE2+"/:machineId"}
            component={() => (
                <ErrorBoundary>
                  <SimpleMachineOverview2 />
                </ErrorBoundary>
            )}
          />
        ))}
        <PrivateRoute
          path={ROUTES.MACHINE_SHIFT_MANAGEMENT}
          component={() => (
              <ErrorBoundary>
                <MachineShiftManagement />
              </ErrorBoundary>
          )}
        />

        {/* <PrivateRoute
          path={ROUTES.COMPARISON}
          component={() => (
            <Comparison />
          )}
        /> */}

        <Route to="/" component={PageNotFound} />

      </Switch>

      <ScrollToTop />

      <div className={classes.pushDiv} />
    </Grid>
  );
};

export default Content;
