export const openDrawer = () => ({
  type: 'OPEN_DRAWER',
});

export const closeDrawer = () => ({
  type: 'CLOSE_DRAWER',
});

export const toggleDrawer = () => ({
  type: 'TOGGLE_DRAWER',
});

export const openSelectionDrawer = () => ({
  type: 'OPEN_SELECTION_DRAWER',
});

export const closeSelectionDrawer = () => ({
  type: 'CLOSE_SELECTION_DRAWER',
});

export const openMachinesDrawer = () => ({
  type: 'OPEN_MACHINE_DRAWER',
});

export const closeMachinesDrawer = () => ({
  type: 'CLOSE_MACHINE_DRAWER',
});

export const toggleSelectionDrawer = () => ({
  type: 'TOGGLE_SELECTION_DRAWER',
});

export const setTimespanStart = (startTime) => ({
  type: 'SET_TIMESPAN_START',
  startTime,
});

export const setTimespanEnd = (endTime) => ({
  type: 'SET_TIMESPAN_END',
  endTime,
});

export const setTimespanDuration = (duration) => ({
  type: 'SET_TIMESPAN_DURATION',
  duration,
});

export const setLastPulseUpdate = (lastPulseUpdate) => ({
  type: 'LAST_PULSE_UPDATE',
  lastPulseUpdate,
});

export const setIsRelativeTimespan = (isRelativeTimespan) => ({
  type: 'SET_IS_RELATIVE_TIMESPAN',
  isRelativeTimespan,
});

export const setIsCustomTimespan = (isCustomTimespan) => ({
  type: 'SET_IS_CUSTOM_TIMESPAN',
  isCustomTimespan,
});

export const setSelectedRelativeTimespan = (relativeTimespan) => ({
  type: 'SET_SELECTED_RELATIVE_TIMESPAN',
  relativeTimespan,
});

export const setSelectedCustomTimespan = (customTimespan) => ({
  type: 'SET_SELECTED_CUSTOM_TIMESPAN',
  customTimespan,
});

export const setIsCustomPreset = (customPreset) => ({
  type: 'SET_CUSTOM_TIME_PRESET',
  customPreset,
});

export const setSelectedTimespanText = (text) => ({
  type: 'SET_SELECTED_TIMESPAN_TEXT',
  text,
});

export const setStructureInUse = (structure) => ({
  type: 'SET_STRUCTURE_IN_USE',
  structure,
});

export const setStructureViewInUse = (structureView) => ({
  type: 'SET_STRUCTURE_VIEW_IN_USE',
  structureView,
});

export const setMachineInUse = (machine) => ({
  type: 'SET_MACHINE_IN_USE',
  machine,
});

export const setMachineViewInUse = (machineView) => ({
  type: 'SET_MACHINE_VIEW_IN_USE',
  machineView,
});

export const persistTableState = (tableState) => ({
  type: 'PERSIST_TABLE_STATE',
  tableState,
});

export const setMachineStatuses = (machineStatuses) => ({
  type: 'SET_MACHINE_STATUSES',
  machineStatuses,
});

export const setDataLayer = (dataLayer) => ({
  type: 'SET_DATA_LAYER',
  dataLayer,
});

export const setReasonsObject = (reasons) => ({
  type: 'SET_REASONS_OBJECT',
  reasons,
});

export const setScrapTypesObject = (scrapTypes) => ({
  type: 'SET_SCRAP_TYPES_OBJECT',
  scrapTypes,
});

export const setPaletteType = (palette) => ({
  type: 'SET_PALETTE_TYPE',
  palette,
});

export const setProductionPulseFilters = (productionPulseFilters) => ({
  type: 'SET_PRODUCTION_PULSE_FILTERS',
  productionPulseFilters,
});
