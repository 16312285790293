import React, { useEffect } from 'react';
import { usePhrases } from '@frontend/utils/usePhrases';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Paper, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import NewReleasesIcon  from '@material-ui/icons/NewReleases';
import PartsChartModalContent from '@frontend/modules/machine/PartsChartModalContent';
import useSimpleMachine from '@frontend/modules/simple_machine/hooks/useSimpleMachine';
import ActualSpeedCard from './ActualSpeedCard';
import TargetSpeedCard from './TargetSpeedCard';
import ShiftTargetCard from './ShiftTargetCard';
//import OrderCard from './OrderCard';
import useDateTimeFormat from '@frontend/utils/useDateTimeFormat';
//import CustomerCard from './CustomerCard';
import Clock from './Clock';
import useSimpleMachine2 from './hooks/useSimpleMachine2';
import ShiftSpeedCard from './ShiftSpeedCard';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    chart: {
      padding: theme.spacing(2),
    },
    
    topCardsContainer: {
      marginTop: theme.spacing(0),
      padding: theme.spacing(1),
    },     

    chartContainer: {
        marginTop: theme.spacing(0),
        padding: theme.spacing(1),
    },
    items: {
        textAlign: 'center',
        margin: "auto",
    },
    alert: {
      float: "center",
      textAlign: "center",
      alignItems: "center",
      marginBottom: theme.spacing(1),
    },
    gridCenter: {
        margin: "auto",
    },  
    shiftTextMargin: {
        marginLeft: theme.spacing(6),
    }
}));
const SimpleMachineOverview2 = () => {
    const { machineId } = useParams();
    const phrases = usePhrases().phrases(); 
    const classes = useStyles();
    const calculationFactor = 60;     // Parts per Hour
    const { formatTime } = useDateTimeFormat();
    const  {
      shiftKPIs,
      partsProduced,  
      aggregatedCurrentSpeed  
    } = useSimpleMachine(machineId);
    const {
        timeLocation,
        speedLow,
        speedMedium,
    } = useSimpleMachine2(machineId);

    // ===== Effects =====
  /**
   *  Reload every 60 min
   */
  useEffect(() => {
    const interval = setInterval(() => {
      window.location.reload(false);
    // min  s    ms
    }, 1*3600000);
    return () => {
      clearInterval(interval);
    };
  }, []);


    return ( 
    <>
    {shiftKPIs.error !== undefined ? (
    <Grid item xs={12} className={classes.alert} alignItems="center" justifyContent="center">
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <Alert style={{width:'50%'}}
                    className={classes.alert}
                    variant="filled"
                    severity="warning"
                    icon={
                      <NewReleasesIcon/>
                    }
                    >
                    {phrases.modules.machine_shift.errors.noShiftFound}
                </Alert>
            </Box>
        </Grid> ) : (
    <div className={classes.root}>
        <Grid container className={classes.topCardsContainer} spacing={4} variant="outlined" >
            <Grid item xs={12}>
                <Paper className={classes.paper} variant="outlined" square>
                    <Grid container spacing={4} variant="outlined">
                        <Grid item className={classes.gridCenter} xs={3}>
                            <Typography align='left' variant='h6' display='block' color='textSecondary' className={classes.shiftTextMargin}>
                                {phrases.modules.simple_machine2.shiftStart}
                                {": "}
                                {formatTime(shiftKPIs.startTimeMoment)}
                            </Typography>
                            <Typography align='left' variant='h6' display='block' color='textSecondary' className={classes.shiftTextMargin}>
                                {phrases.modules.simple_machine2.shiftEnd}
                                {": "}
                                {formatTime(shiftKPIs.endTimeMoment)}
                            </Typography>    
                        </Grid>
                        <Grid item className={classes.items} xs={6}>
                            <Typography align='center' variant="h1" display="inline" color="textSecondary">                    
                                {partsProduced}
                                {" "}
                                {phrases.modules.simple_machine.parts}
                            </Typography>
                        </Grid>
                        <Grid item className={classes.items} xs={3}>
                            <Clock  timeLocation={timeLocation}/>
                        </Grid>    
                    </Grid> 
                </Paper>               
            </Grid> 
        </Grid>
        
        <Grid container spacing={0} variant="outlined">            
            <ActualSpeedCard
                actualSpeed={aggregatedCurrentSpeed*calculationFactor}
                speedLow={speedLow}
                speedMedium={speedMedium}
                targetCycleTime={(60/parseFloat(shiftKPIs.config?.targetCycleTime)*calculationFactor).toFixed(0)}
            />
            <TargetSpeedCard
                targetSpeed={(60/parseFloat(shiftKPIs.config?.targetCycleTime)*calculationFactor).toFixed(0)}
            />
            <ShiftSpeedCard
                shiftSpeed={parseFloat(partsProduced)/parseFloat(shiftKPIs.runningTime)*60}
            />
            <ShiftTargetCard
                shiftKPIs={shiftKPIs}
            />
        </Grid>
        {/* <Grid container spacing={0} variant="outlined">
            <OrderCard
                machineId={machineId}
            />
            <CustomerCard
                customer={""}
            />

        </Grid> */}
        <Grid container className={classes.chartContainer} variant="outlined">
            <Grid item xs={12}>    
                <Paper className={classes.paper} variant="outlined" square>           
                    <div style={{marginRight: "2em"}}>
                    <PartsChartModalContent 
                            machineId={machineId}
                            fullyFeatured={1}
                            height={250}
                            isPartsPerMinute={true}
                            calculationFactor={calculationFactor}
                            targetCycleTime={shiftKPIs.config?.targetCycleTime}
                            customTimespanStart={shiftKPIs.startTimeMoment? new Date(shiftKPIs.startTimeMoment): new Date()}
                            customTimespanEnd={shiftKPIs.endTimeMoment? new Date(shiftKPIs.endTimeMoment): new Date()}
                        />
                    </div>     
                </Paper>            
            </Grid>
        </Grid>
      </div>        
    )}
    </> );
}
 
export default SimpleMachineOverview2;
