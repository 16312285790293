/* eslint-disable no-nested-ternary */
import React, { useRef, useEffect, useState } from 'react';
import moment from 'moment';
import {
  Popper,
  ClickAwayListener,
  Paper,
} from '@material-ui/core';
import { makeStyles }     from '@material-ui/core/styles';

import useResizeObserver      from '@frontend/hooks/useResizeObserver';
import usePulseStates         from '@frontend/modules/machine/hooks/usePulseStates';
import MergeStateModal        from '@frontend/modules/machine/MergeStateModal';
import OrderPopper            from '@frontend/modules/machine/OrderPopper';
import SplitStateModal        from '@frontend/modules/machine/SplitStateModal';
import PartsChartModalContent from '@frontend/modules/machine/PartsChartModalContent';
import usePulseOrders     from '@frontend/modules/orders/hooks/usePulseOrders';
import ReasonAssignModal  from '@frontend/modules/reasons/ReasonAssignModal';
import useSecurity        from '@frontend/utils/useSecurity';

const useStyles = makeStyles((theme) => ({
  pulsePopup: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    minWidth: 250,
  },
  popupWrapper: {
    zIndex: theme.zIndex.appBar - 1,
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.71em',
      marginLeft: 4,
      marginRight: 4,
      '&::before': {
        transformOrigin: '0 100%',
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-0.71em',
      marginLeft: 4,
      marginRight: 4,
      '&::before': {
        transformOrigin: '100% 0',
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: '-0.71em',
      height: '1em',
      width: '0.71em',
      marginTop: 4,
      marginBottom: 4,
      '&::before': {
        transformOrigin: '100% 100%',
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: '-0.71em',
      height: '1em',
      width: '0.71em',
      marginTop: 4,
      marginBottom: 4,
      '&::before': {
        transformOrigin: '0 0',
      },
    },
  },
  arrow: {
    overflow: 'hidden',
    position: 'absolute',
    width: '1em',
    height: '0.71em' /* = width / sqrt(2) = (length of the hypotenuse) */,
    boxSizing: 'border-box',
    color: theme.palette.background.paper,
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: '100%',
      height: '100%',
      boxShadow: theme.shadows[1],
      backgroundColor: 'currentColor',
      transform: 'rotate(45deg)',
    },
  },
}));

const MachineStateBar = React.memo(({
  machine,
  data,
  refreshPulse,
  overview
}) => {
  // ===== State Objects =====
  const [arrowRef, setArrowRef] = useState(null);
  const [canSplitState, setCanSplitState] = useState(true);
  const [canMergeState, setCanMergeState] = useState(false);

  // ===== Imports =====
  const classes = useStyles();
  const svgRef = useRef();
  const wrapperRef = useRef();
  const dimensions = useResizeObserver(wrapperRef);
  const {
    getProfile,
    isSystemAdmin,
    isManager
  } = useSecurity();
  const {
    pulseOrders
  } = usePulseOrders(machine.id, overview);
  const {
    renderPopup,
    handleClose,
    setPopupOpen,
    closeSplitStateModal,
    closeMergeStateModal,
    setReasonAssignModalOpen,
    popupOpen,
    orderPopupOpen,
    rect,
    selectedOrder,
    selectedState,
    reasonAssignModalOpen,
    splitStateModalOpen,
    mergeStateModalOpen,
    isEdit
  } = usePulseStates({
      canMergeState,
      canSplitState,
      data,
      pulseOrders,
      dimensions,
      svgRef,
      machine,
      overview,
      refreshPulse
  })

  // ===== Effects =====
  useEffect(() => {
    const stateDuration = moment(selectedState.endTime || new Date()).diff(selectedState.startTime, 'minutes');
    if (stateDuration >= 2) setCanSplitState(true);
    else setCanSplitState(false);

    if(isSystemAdmin(getProfile()) || isManager(getProfile())) {
      setCanMergeState(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedState]);

  // ===== Return =====
  return (
    <>
     {
     machine.config?.partsChart &&
     <PartsChartModalContent
        machineId = {machine.id}
        targetCycleTime = {machine.config?.targetCycleTime}
        height={75}
        fullyFeatured={0}
        isPartsPerMinute={true}
        customRoot={"chartdiv"+machine.id}
        customStyle={{marginLeft: "-25px", marginRight: "-20px"}}
      />
     }
      <div ref={wrapperRef} style={{
         height: 50,
         width: 'auto',
         marginTop: machine.config?.partsChart !== undefined ? "-1.4em": "0",
        //  marginRight: "2em",
         marginLeft: "2em",
         marginRight: "11px" }}>
      <>
        <svg ref={svgRef} width="100%">
          <g className="x-axis" />
        </svg>
        {popupOpen && (
          <Popper
            open={popupOpen}
            className={classes.popupWrapper}
            anchorEl={rect}
            transition
            disablePortal={false}
            modifiers={{
              arrow: {
                enabled: true,
                element: arrowRef,
              },
              flip: {
                enabled: true,
              },
              preventOverflow: {
                enabled: true,
                boundariesElement: 'scrollParent',
              },
            }}
          >
            <ClickAwayListener onClickAway={handleClose}>
              <Paper
                id="popup-menu"
                className={classes.pulsePopup}
                elevation={3}
                square
              >
                {renderPopup()}
              </Paper>
            </ClickAwayListener>
            <span className={classes.arrow} ref={setArrowRef} />
          </Popper>
        )}
        { orderPopupOpen && (
          <Popper
          open={orderPopupOpen}
          className={classes.popupWrapper}
          anchorEl={rect}
          transition
          disablePortal={false}
          modifiers={{
            arrow: {
              enabled: true,
              element: arrowRef,
            },
            flip: {
              enabled: true,
            },
            preventOverflow: {
              enabled: true,
              boundariesElement: 'scrollParent',
            },
          }}
        >
          <ClickAwayListener onClickAway={handleClose}>
            <Paper
              id="popup-menu"
              className={classes.pulsePopup}
              elevation={3}
              square
            >
              <OrderPopper
              order={selectedOrder}
              />
            </Paper>
          </ClickAwayListener>
          <span className={classes.arrow} ref={setArrowRef} />
        </Popper>
        )}
        {reasonAssignModalOpen && (
          <ReasonAssignModal
            open={reasonAssignModalOpen}
            handleCloseModal={() => setReasonAssignModalOpen(false)}
            selectedState={selectedState}
            isEdit={isEdit}
            refreshPulse={refreshPulse}
            setPopupOpen={setPopupOpen}
          />
        )}
        {splitStateModalOpen && (
          <SplitStateModal
            open={splitStateModalOpen}
            handleCloseModal={closeSplitStateModal}
            selectedState={selectedState}
            refreshPulse={refreshPulse}
          />
        )}
        {mergeStateModalOpen && (
          <MergeStateModal
            open={mergeStateModalOpen}
            handleCloseModal={closeMergeStateModal}
            selectedState={selectedState}
            refreshPulse={refreshPulse}
          />
        )}
      </>
    </div>
  </>

  );
});

export default MachineStateBar;
