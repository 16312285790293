/* eslint-disable consistent-return */
/* eslint-disable no-plusplus */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
/* eslint-disable no-shadow */
/* eslint-disable no-console */
import { useState, useEffect } from 'react';
import axios from 'axios';
import { usePhrases } from '@frontend/utils/usePhrases';
import useApi from '@frontend/utils/useApi';
import useSecurity from '@frontend/utils/useSecurity';
import useValidateUserForm from './useValidateUserForm';

const useUserForm = (callback, props) => {
  const emptyUser = {
    name: '',
    email: '',
    businessUserId: '',
    password: '',
    password2: '',
    isTerminalUser: false,
    username: '',
    pin: '',
    pin2: '',
    lang: '',
    isActive: true,
    role: '',
    structures: [],
    views: {
      structure: {
        view: '',
        id: '',
      },
      machine: {
        view: '',
        id: '',
      },
      homeView: '',
    },
  };

  const [userValues, setUserValues] = useState(props.user || emptyUser);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [structures, setStructures] = useState([]);
  const [roles, setRoles] = useState([]);
  const languages = ['EN', 'DE'];
  const { validateUserForm } = useValidateUserForm();
  const { isSystemAdmin } = useSecurity();
  const api = useApi();
  // let cancelAxios = null;
  const phrases = usePhrases().phrases();
  useEffect(() => {
    const cancelAxios = axios.CancelToken.source();

    return () => {
      if (cancelAxios) cancelAxios.cancel('Component unmounted');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const cancelAxios = axios.CancelToken.source();
    api('/api/structures?populate=machines structure', {
      method: 'get',
      params: {
        mongoQuery: { isActive: true },
      },
      cancelToken: cancelAxios.token,
    }).then((response) => {
      if (props.isEdit) {
        const userStructures = userValues.structures.map((structure) => structure.id);
        setUserValues({ ...userValues, structures: response.data.filter((structure) => userStructures.includes(structure.id)) });
      }
      setStructures(response.data);
    }).catch((error) => {
      if (!axios.isCancel(error)) console.log(error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {

    const cancelAxios = axios.CancelToken.source();
    api('/api/users/roles', {
      method: 'get',
      cancelToken: cancelAxios.token,
    }).then((response) => {
      setRoles(response.data);
    }).catch((error) => {
      if (!axios.isCancel(error)) console.log(error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
    setIsSubmitting(true);
    api('/api/users', {
      method: "GET",
      params: {
        mongoQuery: { businessUserId: userValues.businessUserId
      },
    }}).then((response) => {
      const errs = validateUserForm(userValues, props.isEdit);
      if (response.data.length > 0) {
        // Check if it matches the current user
        if (response.data[0]._id !== userValues._id) {
          setErrors({ 
            businessUserId: phrases.forms.user.errors.businessidInUse,
            ...errs,
          });
        } else {
          setErrors(
            errs
          );
        }
      }
      else {
        setErrors(errs);
      }
    }).catch((error) => {
      if (!axios.isCancel(error)) console.log(error);
      const errs = validateUserForm(userValues, props.isEdit)
      setErrors({
        businessUserId: phrases.forms.user.errors.otherError,
        ...errs,
      });
    });
  };

  const handleSwitch = (event) => {
    event.persist();
    const field = event.target.name;
    setUserValues((userValues) => ({ ...userValues, [field]: !userValues[field] }));
  };

  /**
     * When the user role changes to the System Admin role,
     * all structures are assigned to the System Admin user because the SystemAdmin role has access to all structures.
     */
  const handleStructureChangeForSystemAdmin = () => {
    setUserValues((userValues) => ({ ...userValues, structures }));
  };

  useEffect(() => {
    if (isSystemAdmin(userValues)) { handleStructureChangeForSystemAdmin(); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [structures]);

  const handleChange = (event) => {
    event.persist();
    const field = event.target.name;
    if (errors[field]) { delete errors[field]; }
    const { value } = event.target;
    setUserValues((userValues) => ({ ...userValues, [field]: value }));
    if (field === 'role' && isSystemAdmin({ ...userValues, [field]: value })) { handleStructureChangeForSystemAdmin(); }
  };

  const handleStructureChange = (event, value) => {
    if (!value) return;
    event.persist();

    const newArr = filterOutDuplicates(value);

    if (errors.structures) { delete errors.structures; }

    setUserValues((userValues) => ({ ...userValues, structures: [...newArr] }));
  };

  const filterOutDuplicates = (value) => {
    if (!value) return;
    const newArr = value;
    let h;
    let i;
    let j;
    for (h = 0; h < value.length; h++) {
      const curItem = value[h];
      let foundCount = 0;
      // search an array for an item
      for (i = 0; i < value.length; i++) {
        if (value[i].id === value[h].id) { foundCount++; }
      }
      if (foundCount > 1) {
        // remove the repeated item from the new array
        for (j = 0; j < newArr.length; j++) {
          if (newArr[j].id === curItem.id) {
            newArr.splice(j, 1);
            j--;
          }
        }
      }
    }
    return newArr;
  };

  const handleClearForm = () => {
    setIsSubmitting(false);
    setErrors({});
    setUserValues(emptyUser);
  };

  const handleCheck = (event) => {
    event.persist();
    const field = event.target.name;
    if (errors[field]) { delete errors[field]; }
    const { checked } = event.target;
    setUserValues((userValues) => ({ ...userValues, [field]: checked }));
  }

  /**
   * On errors change, this effect gets trigerred. This is only going to be trigerred
   * when submitting a form.
   */
  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback();
    } else { setIsSubmitting(false); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  return {
    setUserValues,
    handleChange,
    handleCheck,
    handleSwitch,
    handleClearForm,
    handleSubmit,
    handleStructureChange,
    userValues,
    structures,
    roles,
    languages,
    errors,
  };
};

export default useUserForm;
