/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core/styles';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  TextField,
  Button,
  Typography,
  Divider,
  CircularProgress,
  Backdrop,
  InputAdornment,
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import {
  MuiPickersUtilsProvider,
  DateTimePicker,
} from '@material-ui/pickers';
import useApi from '@frontend/utils/useApi';
import { usePhrases } from '@frontend/utils/usePhrases';
import Modal from '@frontend/components/Modal';
import useEditQualityReportForm from '@frontend/modules/quality/hooks/useEditQualityReportForm';
import useAlert from '@frontend/hooks/useAlert';
import MuiNumpad from '@frontend/modules/quality/components/MUI-Numpad';
import LoadingIndicator from '@frontend/components/LoadingIndicator';
import useStatesFilter from '@frontend/hooks/useStatesFilter';
import Alert from '@material-ui/lab/Alert';
import useDateTimeFormat from '@frontend/utils/useDateTimeFormat';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    width: '100%',
  },
  formBtn: {
    marginRight: theme.spacing(1),
    alignSelf: 'flex-end',
  },
  formButtons: {
    flexGrow: 1,
    display: 'flex',
    marginTop: theme.spacing(3),
  },
  loadingBtnIcon: {
    marginLeft: theme.spacing(1),
  },
  inputField: {
    width: '100%',
  },
  expand: {
    marginLeft: 'auto',
  },
  bold: {
    fontWeight: 900,
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  statusTitle: {
    marginLeft: theme.spacing(1),
  },
  reasonTitle: {
    marginBottom: theme.spacing(1),
  },
  pulseWrapper: {
    marginTop: theme.spacing(1),
  },
  backdrop: {
    zIndex: 1,
    position: 'fixed',
    margin: 'auto',
  },
  numpadWrapper: {
    margin: 0,
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
  },
}));

const QualityReportModal = (props) => {
  const classes = useStyles();
  const {
    open,
    allQualityReports,
    selectedReport,
    selectedState,
    handleCloseModal,
    refreshPulse,
    machine,
    actualCount,
  } = props;
  const api = useApi();
  const phrases = usePhrases()
    .phrases();
  const { createAlert } = useAlert();
  const { scrapTypeText } = useStatesFilter();
  const { getLocale } = useDateTimeFormat();
  const {
    handleStartTimeChange,
    handleEndTimeChange,
    handleChangeScrapType,
    handleSubmit,
    handleClearForm,
    qualityReportValues,
    oldQualityReportValues,
    scrapTypesByCategory,
    handleChangeValue,
    handleChangeNote,
    isSubmitting,
    isLoadingNote,
    setIsSubmitting,
    loadingScrapTypes,
    errors,
  } = useEditQualityReportForm(submitQualityReport, selectedReport, open, allQualityReports, machine, selectedState, actualCount);
  const handleScrapTypeClick = (_scrapType) => {
    handleChangeScrapType(_scrapType);
  };

  const handleCloseModalClick = () => {
    handleCloseModal();
    handleClearForm();
  };

  const onModalClose = () => {
    handleClearForm();
    handleCloseModal();
  };

  function submitQualityReport() {
    const data = qualityReportValues.noteId ? {
      id: qualityReportValues.noteId,
      text: qualityReportValues.note,
    } : { text: qualityReportValues.note };
    api('/api/shared/notes', {
      data,
      method: qualityReportValues.noteId ? 'put' : 'post',
    })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          submitQualityReportScrapInfo(response.data.id);
        }
      })
      .catch((error) => {
        const errorMessage = phrases.forms.structure.errors.genericError;
        if (error.response) {
          if (error.response.status === 404) {
            createAlert(errorMessage, 'error');
            setIsSubmitting(false);
          } else if (error.response.status === 403) {
            setIsSubmitting(false);
            createAlert(phrases.errorMessages.notAuthorized, 'error');
          } else {
            createAlert(errorMessage, 'error');
            setIsSubmitting(false);
          }
        }
      });
  }

  const submitQualityReportScrapInfo = (noteId) => {
    const data = {
      machine: machine.id,
      oldQualityReportData: (oldQualityReportValues ? {
        machineId: machine.id,
        note: noteId,
        wasteCount: oldQualityReportValues.value,
        scrapType: oldQualityReportValues.identifier,
        startTime: oldQualityReportValues.startTime,
        endTime: oldQualityReportValues.endTime,
      } : null),
      qualityReportData: {
        machineId: machine.id,
        note: noteId,
        wasteCount: qualityReportValues.value,
        scrapType: qualityReportValues.identifier,
        startTime: qualityReportValues.startTime,
        endTime: qualityReportValues.endTime,
      },
    };
    api('/api/quality/report', {
      data,
      method: 'post',
    })
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          setIsSubmitting(false);
          handleCloseModal();
          handleClearForm();
          createAlert(phrases.modules.quality.qualityReportAdded, 'success');
          refreshPulse();
        }
      })
      .catch((error) => {
        const errorMessage = phrases.forms.structure.errors.genericError;
        if (error.response) {
          if (error.response.status === 404) {
            createAlert(errorMessage, 'error');
            setIsSubmitting(false);
          } else if (error.response.status === 403) {
            setIsSubmitting(false);
            createAlert(phrases.errorMessages.notAuthorized, 'error');
          } else {
            createAlert(errorMessage, 'error');
            setIsSubmitting(false);
          }
        }
      });
  };

  const handleFocusField = (event) => event.target.select();
  
  return (
    <>
      <Modal
        modalTitle={phrases.modules.quality.qualityReport}
        open={open}
        disableBackdropClick
        handleClose={onModalClose}
        content={(
          <Grid container spacing={1}>
            {/* <Grid item xs={12}>
              <QualityCoverage
                qualityReport={qualityReportValues}
                qualityReports={allQualityReports}
                machine={machine}
              />
            </Grid> */}
            <form className={classes.root} noValidate onSubmit={handleSubmit} autoComplete="off">
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={5} style={{ position: 'relative' }}>
                    <div className={classes.numpadWrapper}>
                      <MuiNumpad
                        onChange={handleChangeValue}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={7}>
                    <FormControl variant="outlined" fullWidth margin="dense" className={classes.formControl}>
                      <InputLabel id="demo-simple-select-outlined-label">{phrases.modules.quality.scrapType}</InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={qualityReportValues.identifier}
                        label={phrases.modules.quality.scrapType}
                        error={errors.identifier}
                      >
                        {scrapTypesByCategory.map((scrapType) =>
                          (<MenuItem id={scrapType.id} onClick={() => handleScrapTypeClick(scrapType)} data-scrap-type={scrapType} value={scrapType.identifier}>
                            {scrapTypeText(scrapType.identifier)}
                          </MenuItem>))}
                      </Select>
                      <FormHelperText error>{errors.identifier}</FormHelperText>
                    </FormControl>
                    <TextField
                      error={errors.value}
                      helperText={errors.value}
                      id="value"
                      name="value"
                      label={phrases.modules.quality.count}
                      variant="outlined"
                      fullWidth
                      onFocus={handleFocusField}
                      type="number"
                      className={classes.inputField}
                      onChange={(e) => handleChangeValue(e.target.value)}
                      value={qualityReportValues.value}
                      margin="dense"
                    />
                    <TextField
                      id="note"
                      name="note"
                      label={phrases.forms.reason.misc.note}
                      multiline
                      rows={3}
                      variant="outlined"
                      fullWidth
                      className={classes.inputField}
                      onChange={handleChangeNote}
                      value={qualityReportValues.note}
                      margin="dense"
                      disabled={isLoadingNote}
                      InputProps={{
                        endAdornment:
                          <InputAdornment position="end">
                            {isLoadingNote && <CircularProgress color="inherit" size={15} />}
                          </InputAdornment>,
                      }}
                    />
                    <Grid item xs={12}>
                      <Typography
                        variant="button"
                        display="block"
                        color="textSecondary"
                        className={classes.reasonTitle}
                      >
                        {phrases.modules.quality.productionTimePeriod}
                        <Divider />
                      </Typography>
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getLocale()}>
                          <DateTimePicker
                            autoOk
                            ampm={false}
                            disableFuture
                            openTo="hours"
                            inputVariant="outlined"
                            margin="dense"
                            format={phrases.timespanSelection.timeFormat}
                            fullWidth
                            minutesStep={5}
                            maxDate={qualityReportValues.endTime}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <AccessTimeIcon />
                                </InputAdornment>
                              ),
                            }}
                            value={qualityReportValues.startTime}
                            onChange={handleStartTimeChange}
                            label={phrases.timespanSelection.from}
                            error={errors.timespan}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getLocale()}>
                          <DateTimePicker
                            autoOk
                            ampm={false}
                            disableFuture
                            inputVariant="outlined"
                            margin="dense"
                            format={phrases.timespanSelection.timeFormat}
                            openTo="hours"
                            minutesStep={5}
                            fullWidth
                            minDate={qualityReportValues.startTime}
                            className={classes.dateSelection}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <AccessTimeIcon />
                                </InputAdornment>
                              ),
                            }}
                            value={qualityReportValues.endTime}
                            onChange={handleEndTimeChange}
                            label={phrases.timespanSelection.to}
                            error={errors.timespan}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                    </Grid>
                    {errors.timespan
                      && <Alert variant="filled" severity="error">{errors.timespan}</Alert>}
                    <Backdrop classes={{ root: classes.backdrop }} open={loadingScrapTypes}>
                      :
                      {' '}
                      <LoadingIndicator light />
                    </Backdrop>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.formButtons}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    className={classes.formBtn}
                    disabled={isSubmitting}
                  >
                    {phrases.forms.shared.buttons.submit}
                    {isSubmitting
                      && (
                        <CircularProgress
                          className={classes.loadingBtnIcon}
                          color="inherit"
                          size={15}
                        />
                      )}
                  </Button>
                  <Button
                    variant="outlined"
                    className={classes.formBtn}
                    onClick={handleCloseModalClick}
                  >
                    {phrases.forms.shared.buttons.close}
                  </Button>
                </div>
              </Grid>
            </form>
          </Grid>
        )}
      />
    </>
  );
};

export default QualityReportModal;
// export default React.memo(
//   QualityReportModal,
//   (prevProps, nextProps) => prevProps.open === nextProps.open,
// );
