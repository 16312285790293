import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Grid,
  Paper,
  Divider,
  Backdrop,
  Hidden,
} from '@material-ui/core';

import Time from '@frontend/components/Time';
import TimespanSelection from '@frontend/components/TimespanSelection';
import MachinePulses from '@frontend/modules/machine/MachinePulses';
import ShareButton from '@frontend/components/ShareButton';
import { usePhrases } from '@frontend/utils/usePhrases';
import utils from '@frontend/utils/utils'
import useStructureOverview from '@frontend/modules/structure/hooks/useStructureOverview';
import { ROUTES } from '@frontend/constants';
import LoadingIndicator from '@frontend/components/LoadingIndicator';
import BreadcrumbsNavigation from '@frontend/components/BreadcrumbsNavigation';
import OeeHeaderCard from '@frontend/components/OeeHeaderCard';
import StructureSticky from '@frontend/modules/structure/StructureSticky';
import StructureAvailabilityMachinesCard from '@frontend/components/StructureAvailabilityMachinesCard';
import loader from '@frontend/loader/loader';
import TopReasons from '@frontend/modules/reasons/TopReasons';
import TopQualityLosses from '@frontend/modules/quality/TopQualityLosses';
import MachineStatusChart from '../machine/MachineStatusChart';
import moment from 'moment';
// import StructureInsightsView from '@frontend/components/StructureInsightsView';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(0),
    },
  },
  paper: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  machinePulsesContainer: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    position: 'relative',
  },
  lastTableCell: {
    borderBottom: 'none',
  },
  pushDiv: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(9),
    },
    marginTop: theme.spacing(5),
  },
  topCardsContainer: {
    margin: theme.spacing(0),
    padding: theme.spacing(0),
  },
  cardCell: {
    padding: theme.spacing(1.5),
  },
  cardCellWithIcon: {
    padding: theme.spacing(1),
  },
  progress: {
    textAlign: 'center',
    margin: 'auto',
  },
  backdrop: {
    zIndex: 1,
    position: 'fixed',
    // left: 0,
    width: 'calc(100% - 56px)',
    textAlign: 'center',
  },
}));

const StructureView = () => {
  const { structureId } = useParams();
  const {
    structureCalculations,
    runningCalculations,
    loadStructureCalculations,
    isStructureLoading,
    sensors,
    isLoadingMachines,
    setMachineStates,
    fetchNextBatch,
    pageCount,
    refreshPulse,
  } = useStructureOverview(structureId);
  const classes = useStyles();
  const phrases = usePhrases().phrases();
  const { moduleLoaded } = loader();

  // Redux store  
  const structure = useSelector((state) => state.structure);
  const machines = useSelector((state) => state.machines);
  const timespanStart = moment(useSelector((state) => state.timespanStart));
  const timespanEnd = moment(useSelector((state) => state.timespanEnd));
  const duration = timespanEnd.diff(timespanStart, "minutes");
  const getPercentage = (amount, total) => {
    if (!amount || total === 0) return '0%';

    return `${Math.round((amount / total) * 100)}%`;
  };

  // ===== Effects =====
  /**
   *  Reload every 60 min
   */
  useEffect(() => {
    const interval = setInterval(() => {
      window.location.reload(false);
    // min  s    ms
    }, 1*3600000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className={classes.root}>
      <Grid container spacing={2} className={classes.root}>
        <Hidden xsDown>
          <Grid item xs={12}>
            <BreadcrumbsNavigation selectedStructure={structure} />
          </Grid>
        </Hidden>

        <Grid
          container
          spacing={2}
          className={classes.topCardsContainer}
          variant="outlined"
        >
          <Grid item xs={12} md={4}>
            <Paper variant="outlined" square>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.cardCell}>
                      <Typography variant="body1" align="left">
                        {structure.name}
                      </Typography>
                    </TableCell>

                    <TableCell className={classes.cardCellWithIcon}>
                      <Typography variant="body1" align="right">
                        <ShareButton
                          route={ROUTES.STRUCTURE_OVERVIEW}
                          id={structure.id}
                        />
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  <TableRow>
                    <TableCell className={classes.cardCell} align="left">
                      {' '}
                      {phrases.forms.shared.fields.description}:{' '}
                    </TableCell>
                    <TableCell className={classes.cardCell} align="right">
                      {' '}
                      {structure.description
                        ? structure.description
                        : phrases.forms.shared.fields.none}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className={classes.cardCell} align="left">
                      {' '}
                      {phrases.forms.shared.fields.city}:{' '}
                    </TableCell>
                    <TableCell className={classes.cardCell} align="right">
                      {' '}
                      {structure.city}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell
                      className={`${classes.lastTableCell} ${classes.cardCell}`}
                      align="left"
                    >
                      {' '}
                      {structure.city} {phrases.misc.time}:{' '}
                    </TableCell>
                    <TableCell
                      className={`${classes.lastTableCell} ${classes.cardCell}`}
                      align="right"
                    >
                      {' '}
                      {structure.timezone && (
                        <Time timezone={structure.timezone} />
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
          </Grid>

          <Grid item xs={12} md={4}>
            <Paper variant="outlined" square>
              <OeeHeaderCard
                oee={structureCalculations ? structureCalculations.oee : 0}
                availability={
                  structureCalculations ? structureCalculations.availability : 0
                }
                performance={
                  structureCalculations ? structureCalculations.performance : 0
                }
                quality={
                  structureCalculations ? structureCalculations.quality : 0
                }
              />
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper variant="outlined" square>
              <StructureAvailabilityMachinesCard
                machines={structureCalculations?.totalMachines || '-'}
                runningMachines={
                  structureCalculations
                    ? structureCalculations?.machinesRunning
                    : 0
                }
                runningMachinesPercentage={getPercentage(
                  structureCalculations
                    ? structureCalculations.machinesRunning
                    : 0,
                  structureCalculations?.totalMachines,
                )}
                idleMachines={
                  (structureCalculations
                    ? structureCalculations.machinesNotRunning
                    : 0)
                }
                idleMachinesPercentage={getPercentage(
                  (structureCalculations
                    ? structureCalculations.machinesNotRunning
                    : 0),
                  structureCalculations?.totalMachines,
                )}
              />
            </Paper>
          </Grid>
        </Grid>
                  
        <Grid item xs={12}>
          <StructureSticky
            structure={structure}
            structureCalculations={structureCalculations}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="button" display="block" color="textSecondary">
            {phrases.modules.structure.productionMachines}
          </Typography>
          <Divider />
        </Grid>

        <Grid
          item
          xs={12}
          component={Paper}
          variant="outlined"
          className={classes.machinePulsesContainer}
          square
        >
          <MachinePulses
            structure={structure}
            machines={machines}
            sensors={sensors}
            fetchNextBatch={fetchNextBatch}
            pageCount={pageCount}
            refreshPulse={refreshPulse}
            setMachineStates={setMachineStates}
          />

          {!isStructureLoading &&
            !runningCalculations &&
            !isLoadingMachines &&
            !structure.machines.length && (
              <Typography
                className={classes.noMachinesText}
                variant="subtitle1"
                align="center"
                color="textSecondary"
              >
                {phrases.modules.andon.noMachines}
              </Typography>
            )}
        </Grid>
        <Grid container spacing={2}>
          {!utils.needsGrouping(duration) 
            && (
              <MachineStatusChart
              />
            )}
          {!utils.needsGrouping(duration)
            && moduleLoaded('reasons')
            && (
              <Grid item xs={12} md={6}>
                <TopReasons
                />
              </Grid>
            )}

          {!utils.needsGrouping(duration)
            && moduleLoaded('quality')
            && (
              <Grid item xs={12} md={6}>
                <TopQualityLosses
                />
              </Grid>
            )}
        </Grid>
      </Grid>

      <div className={classes.pushDiv} />

      <Backdrop
        classes={{ root: classes.backdrop }}
        open={isStructureLoading || runningCalculations || isLoadingMachines}
      >
        {isStructureLoading ? (
          <LoadingIndicator
            size={60}
            thickness={3.5}
            text={phrases.modules.structure.structureLoading}
          />
        ) : runningCalculations ? (
          <LoadingIndicator
            size={60}
            thickness={3.5}
            text={phrases.modules.structure.runningCalculations}
          />
        ) : isLoadingMachines ? (
          <LoadingIndicator
            size={60}
            thickness={3.5}
            text={phrases.modules.reporting.loadingMachines}
          />
        ) : (
                ''
              )}
      </Backdrop>

      <TimespanSelection
        onTimespanSelect={loadStructureCalculations}
        // selectTimeframe={getMachineStatesTimeframe}
        // selectTimeframeRelative={getMachineStatesTimeframeRelative}
        disabled={
          isStructureLoading || isLoadingMachines || runningCalculations
        }
      />
    </div>
  );
};

export default StructureView;
