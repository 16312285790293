import { useEffect, useState } from 'react';
import useSecurity from './useSecurity';

const usePhrases = () => {
  const { getProfile } = useSecurity();
  const [lang, setLang] = useState(getProfile()?.lang || 'EN');

  useEffect(() => {
    const user = getProfile();
    if (user) {
      setLang(getProfile().lang);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const phrases = (userLang) => {
    switch (userLang || lang) {
      default:
      case 'EN':
      case 'en':
        return {
          nav: {
            profile: 'Profile',
            settings: 'Settings',
            logout: 'Logout',
            collapseSidebar: 'Collapse Sidebar',
            home: 'Home',
            structures: 'Structures',
            machines: 'Machines',
            sensors: 'Sensors',
            users: 'Users',
            manageStructures: 'Structures',
            manageMachines: 'Machines',
            roles: 'Roles',
            sensorTypes: 'Sensor Types',
            machineTypes: 'Machine Types',
            structureOverview: 'Structure Overview',
            machineOverview: 'Machine Overview',
            reasons: 'Reasons',
            reasonsManagement: 'Reasons',
            categoriesManagement: 'Categories',
            machineStatusesManagement: 'Statuses',
            terminal: 'Terminal',
            oee: 'OEE',
            report: 'Report',
            advanced_report: 'Advanced Report',
            schedule: 'Schedule',
            andon: 'Andon',
            views: 'Views',
            management: 'Management',
            orders: 'Orders',
            supportTicket: 'Support Ticket',
            supportPage: 'Support Website',
            help: 'Help',
            machineNaming: 'Machine Naming',
            machinePerformance: 'Machine Performance',
            jobs: "Jobs",
          },

          machineFilters: {
            default: 'default',
            availabilityminmax: 'availability-min-max',
            availabilitymaxmin: 'availability-max-min',
            performanceminmax: 'performance-min-max',
            performancemaxmin: 'performance-max-min',
            qualityminmax: 'quality-min-max',
            qualitymaxmin: 'quality-max-min',
          },

          pageTitles: {
            sensorsManagement: 'Sensors management',
            sensorTypesManagement: 'Sensor types management',
            usersManagement: 'Users management',
            rolesManagement: 'Roles management',
            machinesMangement: 'Machines management',
            machineTypesManagement: 'Machine types management',
            structuresManagement: 'Structures management',
            signIn: 'Sign in',
            help: 'Help',
          },

          tabs: {
            CreateStructure: 'Create Structure',
            ExistingStructures: 'Existing Structures',
            CreateMachine: 'Create Machine',
            ExistingMachines: 'Existing Machines',
            CreateSensor: 'Create Sensor',
            ExistingSensors: 'Existing Sensors',
            CreateUser: 'Create User',
            ExistingUsers: 'Existing Users',
            CreateRole: 'Create Role',
            ExistingRoles: 'Existing Roles',
            CreateSensorType: 'Create Sensor Type',
            ExistingSensorTypes: 'Existing Sensor Types',
            CreateMachineType: 'Create Machine Type',
            ExistingMachineTypes: 'Existing Machine Types',
            CreateReason: 'Create Reason',
            ExistingReasons: 'Existing Reasons',
            CreateCategory: 'Create Category',
            ExistingCategories: 'Existing Categories',
            CreateMachineStatus: 'Create Status',
            ExistingMachineStatuses: 'Existing Statuses',
            UserLogin: 'User Login',
            AppLogin: 'App Login',
            TerminalLogin: 'Terminal Login',
            SensorHealth: 'Sensor Health',
          },

          errorMessages: {
            emailOrPasswordIncorret: 'Email or password incorrect.',
            notAuthorized: 'You are not authorized to do that',
            pageNotFound: 'The page you are trying to access does not exist.',
            moduleNotFound:
              'The module you are trying to access is not available.',
            notAuthorizedToViewMachine:
              'You are not authorized to view this machine.',
            machineDoesNotExist:
              'The machine you are trying to access does not exist.',
              noMaxCycleTime: 'No "maxCycleTime" configured for this machine.',
          },

          misc: {
            selectStructureYouWantToView:
              'Select a structure you want to view.',
            selectMachineYouWantToView: 'Select a machine you want to view.',
            toContinueToLeanFocus: 'To continue to LEANFOCUS',
            noMachinesInThisStructure: 'No machines in this structure',
            loading: 'Loading...',
            minAvailability: 'Min. Availability',
            maxAvailability: 'Max. Availability',
            copyUrl: 'Copy URL',
            urlCopySuccess: 'URL copied successfully',
            availability: 'Availability',
            time: 'Time',
            search: 'Search',
            overview: 'Overview',
            showMachines: 'Show Machines',
            machineStatusDistribution: 'Machine status distribution',
            machineStatusTimeDistribution: 'Machine status time distribution',
            machineStatus: 'Machines Status',
            deselectAll: 'Deselect ALL',
            sortBy: 'Sort By',
            default: 'Default',
            for: 'for',
            with: 'with',
            average: 'at an average of',
            applyFilters: 'Apply Filters',
            all: 'All',
            title: 'Filters',
            reset: 'Reset',
          },

          modal: {
            EditStructure: 'Edit Structure',
            EditMachine: 'Edit Machine',
            EditSensor: 'Edit Sensor',
            EditUser: 'Edit User',
            EditRole: 'Edit Role',
            EditSensorType: 'Edit Sensor Type',
            EditMachineType: 'Edit Machine Type',
            CreateSensorType: 'Create Sensor Type',
            CreateMachineType: 'Create Machine Type',
            EditReason: 'Edit Reason',
            EditCategory: 'Edit Category',
            EditMachineStatus: 'Edit Machine Status',
          },

          timespanSelection: {
            last: 'Last',
            from: 'From',
            to: 'To',
            Last: 'Last',
            Hour: 'Hour',
            Hours: 'Hours',
            Minutes: 'Minutes',
            Minute: 'Minute',
            Days: 'Days',
            Day: 'Day',
            Weeks: 'Weeks',
            Week: 'Week',
            LastHour: 'Last Hour',
            Last6Hours: 'Last 6 Hours',
            Last12Hours: 'Last 12 Hours',
            Last24Hours: 'Last 24 Hours',
            Last7Days: 'Last 7 Days',
            ShowLastHour: 'Show Last Hour',
            ShowLast6Hours: 'Show Last 6 Hours',
            ShowLast12Hours: 'Show Last 12 Hours',
            ShowLast24Hours: 'Show Last 24 Hours',
            ShowLast7Days: 'Show Last 7 Days',
            Relative: 'Relative',
            DateRange: 'Date Range',
            CustomTimePreset: "Custom Time Preset",
            ctpTitleAlreadyPresent: "The title of the new preset is already present in the list. Please pick another one",
            ctpTitleEmpty: "The provided title is empty or invalid",
            newPresetTitle: "Title",
            addNewPreset: "Add new preset",
            addNewPresetDescription: "Please add the title of the preset and its start and end times.",
            deletePresetModalTitle: "Delete Custom Preset",
            addPresetModalTitle: "Add Custom Preset",
            areYouSureDeleteTimePreset: "Are you sure that you want to delete the preset?",
            dayOfPreset: "Day of Preset",
            CustomBegin: "Begin",
            CustomEnd: "End",
            SelectTimespan: 'Select Timespan',
            Today: 'Today',
            ShowPulseForToday: 'Show Pulse For Today',
            Yesterday: 'Yesterday',
            ShowPulseForYesterday: 'Show Pulse For Yesterday',
            ThisWeek: 'This week',
            ThisMonth: 'This Month',
            ThisYear: 'This Year',
            ShowPulseForThisWeek: 'Show Pulse for this week',
            ShowPulseForThisMonth: 'Show Pulse for this month',
            ShowPulseForThisYear: 'Show Pulse for this year',
            LastWeek: 'Last week',
            ShowPulseForLastWeek: 'Show Pulse For Last week',
            Presets: 'Presets',
            CustomPresets: 'Custom Presets',
            RestoreDefaultTimespan: 'Restore Default Timespan',
            localeDef: {
                "decimal": ".",
                "thousands": ",",
                "grouping": [3],
                "currency": ["$", ""],
                "dateTime": "%a %b %e %X %Y",
                "date": "%Y-%m-%d",
                "time": "%H:%M:%S",
                "periods": ["AM", "PM"],
                "days": ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
                "shortDays": ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
                "months": ["January", "Febuary", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
                "shortMonths": ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
            },
            timeFormat: "MMMM do - HH:mm",
            dateFormat: "MMMM do",
          },

          modules: {
            andon: {
              config: 'ANDON CONFIG',
              theme: 'Theme',
              light: 'Light',
              dark: 'Dark',
              preview: 'ANDON PREVIEW',
              loadingMachines: 'Loading machines',
              loadingStructure: 'Loading structure',
              noStructureSelected: 'No structure selected.',
              noMachines: 'No machines in selected structure.',
              slideshowInterval: 'Slideshow interval',
              set: 'Set',
              minutes: 'Minutes',
              seconds: 'Seconds',
              minutesAbbr: 'Min',
              secondsAbbr: 'Sec',
            },
            machine: {
              legendModalTitle: "Definition Overview",
              staticReason: "Reason",
              statusTime: "Time",
              loadingPulse: 'Loading the production pulse...',
              runtime: 'Runtime',
              downtime: 'Downtime',
              running: 'Running',
              notRunning: 'Not Running',
              online: 'Online',
              lastSeen: 'Last seen',
              never: 'never',
              duration: 'Duration',
              state: 'State',
              machine: 'Machine',
              drillDown: 'Drill Down',
              machinesPermissions: 'Machines permissions',
              machineTypesPermission: 'Machine Types permissions',
              machineStatusesPermission: 'Machine statuses permissions',
              availabilityLowToHigh: 'Availability: Low to High',
              availabilityHighToLow: 'Availability: High to Low',
              performanceLowToHigh: 'Performance: Low to High',
              performanceHighToLow: 'Performance: High to Low',
              loadingMachine: 'Loading machine...',
              partsPerMinute: 'Parts/Minute',
              secPerPart: 'Sec/Part',
              partsPerMinuteString: 'parts per minute',
              secPerPartString: 'seconds per part',
              currentSpeedTarget: 'Current Speed (Target)',
              selectMachine: 'Please select a machine',
              sortBy: 'Sort by',
              qualityLowToHigh: 'Quality: Low to High',
              qualityHighToLow: 'Quality: High to Low',
              speedTarget: 'Ø Speed (Target)',
              speedJob: 'Job Speed (Target)',
              splitState: 'Split State',
              mergeState: 'Merge States',
              merge: 'Merge',
              addPreviousState: 'Add previous State',
              addNextState: 'Add next State',
              noMoreStates: 'No more states available in selected timeframe',
              splitStateSuccessful: 'State has been split succesfully.',
              mergeStateSuccessful: 'States have been merged succesfully.',
            },
            simple_machine: {
              default: "Hello",
              parts: "Parts",
              shiftGoalLabel: "Shift\n[bold]Goal[/]",
              shiftGoal: "Shift Goal",
              timeGoal: "Time Goal",
              unit: "Parts",
              gauge: "Gauge",
              progress: "Progress Bar",
              targetCylceTime: 'Target Cycle Time',
              currentSpeed: 'Current Speed',
              partsPerHour: 'Parts/Hour',
            },
            simple_machine2: {
              currentSpeed: 'Current',
              targetSpeed: 'Target',
              shiftSpeed: "Shift",
              currentOrder: 'Current Order',
              customer: 'Customer',
              orderLoading: 'Loading Order...', 
              product: "Product",
              order: "Order",
              shiftStart: "Shift Start",
              shiftEnd: "Shift End",
            },
            machine_shift: {
              shift: 'Shift',
              shifts: 'Shifts',
              createNewMachineShift: 'Create new machine shift',
              existingMachineShifts: 'Exisiting Machine Shifts',
              deleteConfirmation: "Are you sure that you want to delete shift '{shiftName}' from {startTime} to {endTime}?",
              confirmDeleteTitle: "Confirm delete shift",
              editMachineShift: "Edit machine shift",
              machineShiftPermission: "Permission for machine shifts",
              errors:{
                endTimeLargerStartTime: "End time must be larger than start time",
                noShiftFound: "No shift found for current time",
                nameRequired: "Shift name is required",
                machineRequired: "Machine is required",
                startTimeRequired: "Start time is required",
                endTimeRequired: "End time is required",
                isActiveRequired: "Active value is required",
                targetNumberLargerZero: "Target number must be larger than 0",
                mondayRequired: "Monday value is required",
                tuesdayRequired: "Tuesday value is required",
                wednesdayRequired: "Wednesday value is required",
                thursdayRequired: "Thursday value is required",
                fridayRequired: "Friday value is required",
                saturdayRequired: "Saturday value is required",
                sundayRequired: "Sunday value is required",
              },
              forms: {
                machineShiftAdded: "Machine shift was successfully added",
                machineShiftUpdated: "Machine shift was successfully updated",
                name: "Shift name",
                startTime: "Start time",
                endTime: "End time",
                machine: "Machine",
                machines: "Maschines",
                monday: "Monday",
                tuesday: "Tuesday",
                wednesday: "Wednesday",
                thursday: "Thursday",
                friday: "Friday",
                saturday: "Saturday",
                sunday: "Sunday",
                isActive: "Active",
                targetNumber: "Target Number",
              }
            },
            reporting: {
              minStartTime: 'Min Start Time',
              minEndTime: 'Min End Time',
              maxStartTime: 'Max Start Time',
              maxEndTime: 'Max End Time',
              minDuration: 'Min Duration',
              maxDuration: 'Max Duration',
              loadingReportData: 'Loading report data',
              oeeTab: 'OEE',
              machineTab: 'Machine',
              machineTabMachine: 'Machine',
              jobTab: 'Jobs',
              noMatchingData: 'Sorry, there is no matching data to display',
              minAvailability: 'Min Availability',
              maxAvailability: 'Max Availability',
              loadingMachines: 'Loading machines...',
              loadingQualityReports: 'Loading Quality Reports...',
              actualIdealCount: 'Actual/Target count',
              actualCount: 'Actual Count',
              goodCount: 'Good Count',
              targetCount: 'Target Count',
              scrapCount: 'Scrap Count',
              plannedDowntime: 'Planned Downtime',
              downtime: 'Unplanned Downtime',
              scheduleLoss: 'Schedule Loss',
            },
            advanced_reporting: {
                machineRankingHeadingText: "Machine Performance Overview",
                machineRankingChartID: "Machine Ranking",
                machineRankingValue: "performance",
                machineRankingCategory: "name",
                noPerformanceText: "N/A",
                noPerformanceValue: 0,
                reasonRankingHeadingText: "Worst failure reason Overview",
                reasonRankingChartID: "Reason Ranking",
                reasonRankingValue: "count",
                reasonRankingCategory: "name",
                noReasonsText: "No reasons yet",
                noReasonsValue: 1,
                averageHeadingText: "Average Overview",
                averageChartID: "Average Overview",
                averageValue: "value",
                averageCategory: "category",
                fromText: "From",
                toText: "To",
                oneh: "1h",
                twoh: "2h",
                threeh: "3h",
                timeSelectorHeading: "Time Selector",
                timeSelectorTimeframeSelect: "Select a timeframe",
                pickTime: "Enter a time (last hours)"
            },
            user: {
              signIn: 'Sign In',
              noStructures: 'No structures or machines assigned to the user.',
              noViews: 'No views for structure or machine assigned to user.',
              note: 'NOTE',
              editingDisabled:
                'Editing of the System Admin role is disabled by the system!',
              users: 'Users',
              usersPermissions: 'Users permissions',
              deactivateUsers: 'Deactivate Users',
              oeeOverview: 'OEE Overview',
              andonBoard: 'Andon Board',
              comparisonOverview: 'Comparison Overview',
              reportingOverview: 'Reporting Overview',
              scheduleOverview: 'Schedule Overview',
              machineOverview: 'Machine Overview',
              structureManagement: 'Structure Management',
              machineManagement: 'Machine Management',
              sensorManagement: 'Sensor Management',
              userManagement: 'User Management',
              view: 'View',
              structure: 'Structure',
              machine: 'Machine',
              homeview: 'Home view',

            },
            structure: {
              averageRuntime: 'Avg. Machine Runtime',
              averageDowntime: 'Avg. Machine Downtime',
              machines: 'Machines',
              timespan: 'Timespan',
              productionPulse: 'Production Pulse',
              productionMachines: 'Production Machines',
              structureLoading: 'Structure loading...',
              runningCalculations: 'Running calculations...',
              deactivateStructures: 'Deactivate structures',
              selectStructure: 'Please select a structure',
              machinesRunning: 'machines running.',
              of: 'of',
            },
            performance: {
              slowRunning: 'Slow Running',
              idle: 'Idle',
              jobs: "Jobs",
              availability: 'Availability',
              performance: 'Performance',
              minPerformance: 'Min. Performance',
              maxPerformance: 'Max. Performance',
              qualityShort: 'QUA',
              performanceShort: 'PER',
              availabilityShort: 'AVA',
              quality: 'Quality',
              oee: 'OEE',
              parts: 'Parts',
              avgSign: 'Ø',
              partsPerMinute: 'Parts/Min',
              partsProduced: 'Parts produced',
              partsSurplus: 'Parts surplus',
              partsMissed: 'Parts missed',
              target: 'Target count',
              chartTargetCycleTime: 'Target Cycle Time',
              chartParts: 'Parts',
              chartPartsSec: 'Seconds',
              chartPartsProduced: 'Parts produced (Parts/Min)',
              chartPartsProducedSecPart: 'Parts produced (Sec/Part)',
              chartPartsProducedPartsHour: 'Parts produced (Parts/Hour)',
              chartPartsPartsHour: 'Parts/Hour',
              chartPartsTitle: 'Produced parts',
              chartPartsPartsMin: 'Parts/Min',
              chartPartsSecPart: 'Sec/Part',
              isLoadingParts: 'Loading parts...',
            },
            terminal: {
              productionCapacity: 'Production capacity',
            },
            reasons: {
              reasons: 'Reasons',
              noAssignedReasonsTimeframe: 'No reasons assigned in this timeframe.',
              deactivateReasons: 'Deactivate Reasons',
              categoriesPermissions: 'Categories permissions',
              notesPermissions: 'Notes permissions',
              reasonsPermissions: 'Reasons permissions',
            },
            sensor: {
              deactivateSensors: 'Deactivate Sensors',
              sensorsPermission: 'Sensors permissions',
              sensorTypesPermission: 'Sensor Types permissions',
              notConnected: 'Not connected',
              noSignal: 'No signal',
              signalStrength: 'Signal strength',
              rssiExcellent: 'Excellent',
              rssiGood: 'Good',
              rssiFair: 'Fair',
              rssiWeak: 'Weak',
              noMachine: 'No associated machine',
              lastSeenAt: 'Last seen at: ',
              online: 'Online: ',
              rssiAverage: 'RSSI (average 7 days)',
              version: 'Version: ',
              hardware: 'Hardware: ',
            },
            roles: {
              rolesPermission: 'Roles permissions',
            },
            schedule: {
              date: 'Date',
              today: 'TODAY',
              dayTemplatePermissions: 'Day Template permissions',
              weekTemplatePermissions: 'Week Template permissions',
              schedulePermissions: 'Schedule permissions',
              shiftsPermissions: 'Shifts permissions',
              activateSchedule: 'Activate schedule',
              schedule: 'Schedule',
              noEligibleSchedules: 'No eligible schedules for this machine.',
              changeActiveSchedule: 'Change active schedule',
              editSchedule: 'Edit schedule',
              editDay: 'Edit day',
              deactivateTemplates: 'Deactivate Templates',
              existingShifts: 'Existing Shifts',
              createShift: 'Create Shift',
              start: 'Start',
              end: 'End',
              scheduled: 'Scheduled',
              duration: 'Duration',
              preview: 'Preview',
              enabled: 'Enabled',
              shiftUpdated: 'Shift updated succesfully.',
              shifts: 'Shifts',
              editShift: 'Edit shift',
              selectedTime: 'Selected time of: ',
              invalidStart:
                ' is invalid as it conflicts with the start of the shift.',
              invalidEnd:
                ' is invalid as it conflicts with the end of the shift.',
              shiftStart: 'Shift start',
              shiftEnd: 'Shift end',
              manageSchedules: 'Manage Schedules',
              createSchedule: 'Create Schedule',
              existingWeekTemplates: 'Existing week templates',
              createWeekTemplate: 'Create week template',
              existingDayTemplates: 'Existing day templates',
              createDayTemplate: 'Create day templates',
              weekRemoved: 'Week removed successfully.',
              weekEdited: 'Week edited successfully.',
              dayEdited: 'Day edited successfully.',
              scheduleCreated: 'The schedule has been created successfully.',
              scheduleUpdated: 'The schedule has been updated successfully.',
              startDate: 'Start date',
              endDate: 'End date',
              addWeek: 'ADD WEEK',
              editWeek: 'Edit week',
              scheduleInfo: 'Schedule info',
              scheduleName: 'Name',
              scheduleDescription: 'Description:',
              lastDeployed: 'Last Deployed:',
              lastEdited: 'Last Edited:',
              goTo: 'Go to:',
              scheduleDeployed: 'The schedule has been successfully deployed.',
              deploymentCanceled: 'Deployment has been successfully canceled.',
              scheduleActivated:
                'The schedule has been successfully activated.',
              scheduleDuplicated:
                'The schedule has been successfully duplicated.',
              deployment: 'Deployment',
              activation: 'Activation',
              areYouSureDeploy: 'Are you sure you want to deploy ',
              areYouSureActivate: 'Are you sure you want to activate ',
              overwriteOnMachines:
                'This will overwrite any changes done on any of the machines listed below.',
              onTheseMachines: ' on these machines:',
              editCreatedSchedule:
                'Do you want to edit newly created schedule?',
              areYouSureDelete: 'Are you sure you want to delete ',
              areYouSureDuplicate: 'Are you sure you want to duplicate ',
              areYouSureCancel: 'Are you sure you want to cancel deployment for ',
              scheduleDeleted: 'The schedule has been deleted succesfully.',
              schedules: 'Schedules',
              areYouSure: 'Are you sure?',
              editNewSchedule: 'Edit new schedule',
              doItLater: 'I will do it later',
              deployingSchedule: 'Deploying schedule: ',
              cancelingDeployment: 'Canceling deployment of schedule: ',
              takeWhile: 'It may take a while depending on number of machines.',
              activatingSchedule: 'Activating schedule: ',
              addRemoveMachines: 'Add/Remove machines',
              editMasterTemplate: 'Edit master template',
              editDeployedSchedule: 'Edit deployed schedule',
              editNewWeekTemplate:
                'Do you want to edit the newly created week template?',
              deployingYourSchedule: 'Deploying your schedule...',
              editNewTemplate: 'Edit new template',
              scheduleCantBeEdited: 'The Schedule cannot be edited before',
              selectedMachines: 'Selected machines: ',
              weekTemplateDuplicated:
                'The week template has been successfully duplicated.',
              addDayTemplate: 'Add day template',
              scheduleTemplateUpdated:
                'The schedule template updated succesfully.',
              weekTemplates: 'Week templates',
              editWeekTemplate: 'Edit week template',
              weekTemplateCreated:
                'The week template has been created successfully.',
              weekTemplateUpdated:
                'The week template has been updated successfully.',
              clearDay: 'Clear day',
              templateNameRequired:
                'The template name is required. Cannot be left empty.',
              templateEvents: 'Template events cannot be left empty.',
              templateTimeframe:
                'Template timeframe has to be filled completely.',
              scheduleNameRequired:
                'The schedule name is required. Cannot be left empty.',
              scheduleToMachine:
                'The schedule has to be connected to at least one machine.',
              startDateCantBeInThePast:
                'Start Date must not be in the past.',
              endDateCantBeBeforeStartDate:
                'End Date must not be before start date.',
              atLeastOneWeekTemplate: 'Schedule must have at least one week template added.',
              dayTemplateDuplicated:
                'The day template has been successfully duplicated.',
              dayTemplates: 'Day Templates',
              editTemplate: 'Edit template',
              dayTemplateCreated:
                'The day template has been successfully created.',
              dayTemplateUpdated:
                'The day template has been successfully updated.',
              duplicate: 'Duplicate',
              edit: 'Edit',
              view: 'View',
              delete: 'Delete',
              hasExpired: 'Has Expired',
              hasNotExpired: 'Has Not Expired',
              all: 'All',
              expiration: 'Expiration',
              cancelDeployment: 'Cancel Deployment',
              deploy: 'Deploy',
              activateOn: 'Activate on:',
              activationScheduledSuccessfully: 'Activation has been scheduled successfully.',
              activationCanceledSuccessfully: 'Scheduled activation has been canceled successfully.',
              isDueForActivationOn: 'is due for activation on',
              selectAllMachines: 'SELECT ALL MACHINES',
              remove: "REMOVE",
            },
            quality: {
              quality: 'Quality',
              existingScrapTypes: 'Existing Scrap Types',
              createNewScrapType: 'Create a new scrap type',
              scrapTypes: 'Scrap types',
              scrapType: 'Scrap type',
              minQuality: 'Min. Quality',
              maxQuality: 'Max. Quality',
              count: 'Count',
              qualityReportAdded:
                'A new quality report has been added successfully.',
              qualityReportDeleted: 'The quality report has been deleted successfully',
              qualityReport: 'Quality report',
              alreadyAdded:
                ' already added. Please click on edit above to change.',
              productionTimePeriod: 'Production time period',
              wasteCount: 'Waste count',
              totalWasteCount: 'Total Waste Count: ',
              scrapTypeRequired: 'Scrap type required',
              endTimeRequired: 'End time required',
              startTimeRequired: 'Start time required',
              countRequired: 'Count required. Cannot be 0 or left blank.',
              invalidScrapNumber: 'Invalid amount of scrap, cannot be negative',
              tooMuchScrap: 'Invalid amount of scrap, amount too big',
              actualCountInvalid: 'Sensor count not valid, cannot enter scrap',
              typeNameRequired:
                'Scrap Type name is required. Cannot be left empty.',
              typeIdentifierRequired:
                'Scrap Type identifier is required. Cannot be left empty.',
              categoryRequired: 'Category is required. Cannot be left empty.',
              originRequired: 'Origin is required. Cannot be left empty.',
              scrapTypesPermissions: 'Scrap Types Permissions',
              parts: 'Parts',
              noQualityReportSubmitted: 'No quality reports submitted in the selected timeframe.',
              qualityLosses: 'Quality Losses',
              qualityReports: 'Quality Reports',
              deleteQualityReportModalTitle: 'Delete quality report?',
              areYouSureDeleteQualityReport: 'Are you sure you want to delete this quality report?',
              noQualityReportsSubmitted: 'No quality reports submitted in the selected timeframe.',
            },
            orders: {
              areYouSureDeleteJob: "Delete Job?",
              areYouSureDeleteJobText: "Are you sure that you want to delete the following job?",              
              orders: "Orders",
              edit: "Edit",
              delete: "Delete",
              pastActiveJobs: "Past active Jobs",
              noPastActiveJobsInTimeframe: 'No past active jobs in selected timeframe',
              ordersPermissions: 'Orders Permissions',
              progress: "Progress (%)",
              speedJob: "Speed (PPM)",
              jobsPermissions: 'Jobs Permissions ',
              productsPermissions: 'Products Permissions',
              activeJobs: 'Active Job',
              jobId: 'Job ID',
              orderId: 'Order ID',
              status: 'Status',
              plannedStartDate: 'Planned start date',
              actualStartDate: 'Actual start date',
              target: 'Target',
              sensorCount: 'Sensor count',
              goodCount: 'Good parts',
              scrapCount: 'Total scrap',
              handCount: 'Hand count',
              machine: 'Machine',
              structureField: 'structure',
              intendedMachineField: 'intendedMachine',
              intendedMachine: 'Intended Machine',
              structure: 'Structure',
              intended: 'Intended',
              operator: 'Operator',
              startTime: 'Start time',
              endTime: 'End time',
              duration: 'Duration',
              uploadCSV: 'Upload CSV',
              chooseFileToUpload: 'Choose file to upload',
              chooseFile: 'Choose file',
              close: 'Close',
              upload: 'Upload',
              uploading: 'Uploading',
              uploadAnotherFile: 'Upload another file',
              jobsCreatedSuccessfully: 'jobs created successfully',
              backToUploadFile: 'Back to upload file',
              fileHasBeenUploaded: 'File has been uploaded.',
              uploadAgainWithCorrections:
                'However same file needs to be uploaded again with following corrections:',
              row: 'Row',
              backToJobsList: 'Back to jobs list',
              product: 'Product',
              description: 'Description',
              jobStatus: 'Job Status',
              targetCount: 'Target Count',
              targetCycleTime: 'Target cycle time',
              targetSetupTime: 'Target setup time',
              engagements: 'Engagements',
              operatorNotes: 'Operator notes',
              loadingJob: 'Loading job...',
              existingNotes: 'Existing notes',
              enterNoteText: 'Enter note text',
              addNote: 'Add Note',
              clearScreen: 'Clear Screen',
              start: 'Start',
              stop: 'Stop',
              setJobStatus: 'Set the job status.',
              ACTIVE: 'ACTIVE',
              OPEN: 'OPEN',
              PAUSED: 'PAUSED',
              COMPLETED: 'COMPLETED',
              setJobStatusPaused: 'Set job status to paused.',
              setJobStatusCompleted: 'Set job status to completed.',
              currentlyEngaged: 'Currently Engaged',
              jobTotalCount: 'Job Total Count',
              speed: 'Speed',
              actual: 'Actual',
              setup: 'Setup',
              warning: 'Warning',
              areYouSureOverwriteHandcount:
                'Are you sure you want to overwrite the current handcount?',
              byClickingSubmit: 'By clicking submit, the current handcount:',
              willBeDeleted: 'will be deleted.',
              yes: 'Yes',
              no: 'No',
              STARTED: 'STARTED',
              ENDED: 'ENDED',
              intendedTable: 'intended',
              unintendedTable: 'unintended',
              fulltime: 'Full Time',
              timeframe: 'Timeframe',
              pastTwoWeeks: 'Past 2 Weeks',
              pastMonth: 'Past Month',
              pastSixMonths: 'Past 6 Months',
              pastYear: 'Past Year',
              jobProgressOverview: 'Job Progress Overview',
              jobProgress: 'Job Progress',
              existingJobs: 'Existing Jobs',
              createNewJob: "Create New Job",
              activationOverview: 'Activation Overview',
              addedHandCount: 'Added Hand count',
            },
            machinePerformance: {
              machinePerformance: 'Machine Performance',
              theoreticalOutput: 'Theoretical Maximum Output',
              workingOutput: 'Scheduled Maximum Output',
              scheduledOutput: 'After Schedule Loss',
              downtimeLoss: 'After Downtime Loss',
              speedLoss: 'After Speed Loss',
              qualityLoss: 'After Quality Loss',
              theoreticalOutputDesc: 'Theoretical maximum of produced parts when a machine runs 24/7',
              workingOutputDesc: 'Theoretical produced parts according to the schedule plan',
              scheduledOutputDesc: 'Theoretical produced parts according to the schedule plan without planned breaks',
              downtimeLossDesc: 'Theoretical maximum produced parts while machine was running',
              speedLossDesc: 'Parts produced',
              qualityLossDesc: 'Good parts',
              performanceLoading: 'Loading machine performance...',
              unit: 'Parts',
              unitMaxCycleTime: 'Parts/min',
              totalTime: 'Total Time',
              maxCycleTime: 'Max. parts per minute',
              noPerformance: 'No performance data available',
              workingTime: 'Planned working time',
              plannedReasons: 'Total planned reasons time',
              plannedReasonsHeading: 'Planned Downtimes',
              runningTime: 'Total running time',
              runningStates: 'Number of running states',
              avgPartsPerMinute: 'Average parts per minute',
              avgScrapPerMinute: 'Average scrap per minute',
              total: 'Total',
              totalDowntimeLoss: 'Total downtime loss',
              delta: 'Delta',
              reasonsHeading: 'Unplanned Downtimes',
              qualityLossesHeading: 'Quality Reports',
              backToMachineView: 'Back to Machine View',
              scheduledWorkingTime: 'Scheduled Working Time',
              overtime: 'Overtime',
              plannedRunningTime: "Planned Runtime"
            }
          },

          forms: {
            jobs: {
              product: "Product",
              targetCycleTime: "Target Cycle Time",
              targetSetupTime: "Target Setup Time",
              targetQuantity: "Target Quantity",
              description: "Description",
              intendedMachine: "Intended Machine",
              scanCode: "Scan Code",
              handCount: "Hand Count",
              completionStatus: "Completion Status",
              businessJobId: "Business ID",
              modalTitle: "Edit Job",
              jobEditSuccess: "Job saved successfully",
              jobEditFailure: "Saving Job failed",
              jobDeleteSuccess: "Deleted Job successfully",
              jobDeleteFailure: "Deleting Job failed",
              validate: {
                handCountRequired: "Hand Count number is required",
                descriptionRequired: "Job Description is required",
                completionStatusRequired: "A Job Completion status is required",
                intendedMachineRequired: "A machine is required",
                targetQuantityRequired: "A target quantity number is required",
                targetSetupTimeRequired: "A target setup time value is required",
                targetCycleTimeRequired: "A target cycle time value is required",
                productRequired: "A product selection is required"
              }

            },
            machine: {
              fields: {
                placeNumber: 'Place Number',
                manufacturer: 'Manufacturer',
                machineConfiguration: 'Machine Configuration',
                configurationName: 'Configuration Name',
                configurationValue: 'Configuration Value',
              },

              buttons: {
                removeField: 'Remove Field',
                addField: 'Add Field',
              },

              errors: {
                machineNameRequired:
                  'The machine name is required. Cannot be left empty.',
                structureRequired:
                  'The structure is required. Cannot be left empty.',
                machineTypeRequired:
                  'Machine type is required. Cannot be left empty.',
                nameCannotBeBlank: 'Name is required. Cannot be left empty.',
                genericError: 'Something went wrong. Please try again later.',
                sensorPerMachine:
                  'Current version only supports one sensor per machine.',
                machineStatusName:
                  'The machine status name is required. Cannot be left empty.',
                machineStatusActivity:
                  'The machine status activity is required. Cannot be left empty.',
                machineStatusColor: 'Color is required. Cannot be left empty.',
                machineStatusCategory:
                  'Category is required. Cannot be left empty.',
                machineStatusDataLayer:
                  'Data layer is required. Cannot be left empty.',
              },

              success: {
                machineAdded: 'A new machine has been added successfully.',
                machineUpdated: 'The machine has been updated successfully.',
                machineTypeAdded:
                  'A new machine type has been added successfully.',
                machineTypeUpdated:
                  'The machine type has been updated successfully.',
                machineTypeDeleted:
                  'The machine type was deleted successfully.',
                machineConfigSent:
                  'The machine config has been sent successfully.',
              },
            },

            sensor: {
              fields: {
                sensorID: 'Sensor ID',
                measurementUnit: 'Measurement Unit',
              },

              errors: {
                sensorIdRequired:
                  'The sensor ID is required. Cannot be left empty.',
                aliasRequired:
                  'Sensor alias is required. Cannot be left empty.',
                sensorTypeRequired:
                  'The sensor type is required. Cannot be left empty.',
                isActiveUndefined:
                  'Active toggle is required. Cannot be left undefined.',
                genericError: 'Something went wrong. Please try again later.',
                sensorNameRequired:
                  'Sensor name is required. Cannot be left blank.',
                measurementUnitRequired:
                  'Measurement unit is required. Cannot be left blank',
              },

              success: {
                sensorAdded: 'A new sensor has been added successfully.',
                sensorUpdated: 'The sensor has been updated successfully.',
                sensorDeleted: 'The sensor has been deleted successfully.',
                sensorTypeAdded:
                  'A new sensor type has been added successfully.',
                sensorTypeUpdated:
                  'The sensor type has been updated successfully.',
                sensorTypeDeleted: 'Sensor type deleted successfully.',
              },
            },

            structure: {
              fields: {
                country: 'Country',
                parentStructure: 'Parent Structure',
              },

              errors: {
                notAuthorized: 'You are not authorized to view this structure.',
                structureDoesntExist:
                  'The structure you are trying to access does not exist.',
                structureNameRequired:
                  'The structure name is required. Cannot be left blank.',
                structureCityRequired:
                  'The city is required. Cannot be left blank.',
                structureCountryRequired:
                  'The country is required. Cannot be left blank.',
                structureTimezoneRequired:
                  'The structure timezone is required. Cannot be left blank.',
                genericError: 'Something went wrong. Please try again later.',
              },

              success: {
                structureAdded: 'A new structure has been added successfully.',
                structureUpdated:
                  'The structure has been updated successfully.',
                structureDeleted:
                  'The structure has been deleted successfully.',
              },
            },

            user: {
              fields: {
                email: 'Email',
                password: 'Password',
                businessUserId: 'Business User ID',
                fullName: 'Full Name',
                confirmPassword: 'Confirm Password',
                username: 'Username',
                pin: 'PIN',
                confirmPin: 'Confirm PIN',
                language: 'Language',
                role: 'Role',
                defaultView: 'Default View',
              },

              errors: {
                otherError:
                  'Something went wrong. Please try again later.',
                invalidEmailFormat:
                  'Invalid email format. Should be user@site.com',
                roleNameRequired: 'The name is required. Cannot be left blank.',
                fullNameRequired:
                  'Full name is required. Cannot be left blank.',
                emailRequired: 'Email is required. Cannot be left blank.',
                passwordRequired:
                  'The password is required. Cannot be left blank.',
                passwordMismatch:
                  'The passwords do not match. Please try again.',
                passwordLength:
                  'The password must be at least 8 characters long.',
                businessidInUse:
                  'The provided business user ID is already in use.',
                languageRequired:
                  'The language is required. Cannot be left blank.',
                roleRequired: 'The role is required. Cannot be left blank.',
                structuresRequired:
                  'The structures are required. Cannot be left blank.',
                structureViewRequired:
                  'The structure view is required. Cannot be left blank.',
                structureRequired:
                  'A structure is required. Cannot be left blank.',
                machineViewRequired:
                  'The machine view is required. Cannot be left blank.',
                machineRequired: 'A machine is required. Cannot be left blank.',
                homeViewRequired:
                  'The home view is required. Cannot be left blank.',
                genericError: 'Something went wrong. Please try again later.',
                adminCannotBeDeleted: 'System Admin role cannot be deleted!',
                userAlreadyExists: 'A user with that email already exists.',
                pinRequired: 'The PIN is required. Cannot be left blank.',
                pinMismatch: 'The PIN numbers do not match. Please try again.',
              },

              success: {
                roleAdded: 'A new role has been added successfully.',
                roleUpdated: 'The role has been updated successfully.',
                roleDeleted: 'The role has been deleted successfully.',
                userAdded: 'A new user has been added successfully.',
                userUpdated: 'The user has been updated successfully.',
                userDeleted: 'The user has been deleted successfully.',
              },
            },

            reason: {
              fields: {
                name: 'Name',
                identifier: 'Identifier',
                description: 'Description',
                color: 'Color',
                category: 'Category',
                machineType: 'Machine Type',
                roles: 'Roles',
                origin: 'Origin',
                reasonConfiguration: 'Reason Configuration',
              },

              misc: {
                staticReasonActive: "Static Reason is active!",
                machineStatus: 'Machine Status',
                reason: 'Reason',
                note: 'Note',
                assignStaticReason: 'Assign Static Reason',
                assignReason: 'Assign Reason',
                changeReason: 'Change Reason',
                editReason: 'Edit Reason',
                editStaticReason: 'Edit Static Reason',
                removeReason: 'Remove Reason',
                loadingReasons: 'Loading reasons...',
              },

              errors: {
                reasonNameRequired:
                  'The reason name is required. Cannot be left empty.',
                reasonIdentifierRequired:
                  'The reason identifier is required. Cannot be left empty.',
                reasonColorRequired: 'Color is required. Cannot be left empty.',
                reasonCategoryRequired:
                  'Category is required. Cannot be left empty.',
                reasonOriginRequired:
                  'Origin is required. Cannot be left empty.',
                reasonRequired: 'The reason is required. Cannot be left empty.',
              },

              success: {
                staticReasonAdded: 'A new static reason has been set for this machine.',
                staticReasonDeleted: 'The static reason for this machine has been deleted.',
                reasonAdded: 'A new reason has been added successfully.',
                reasonUpdated: 'The reason has been updated successfully.',
                reasonDeleted: 'The reason has been deleted successfully.',
              },
            },

            quality: {
              errors: {
                qualityIdentifierRequired:
                  'The quality identifier is required. Cannot be left empty.',
                qualityNameRequired:
                  'The reason name is required. Cannot be left empty.',
                reasonIdentifierRequired:
                  'The reason identifier is required. Cannot be left empty.',
                reasonColorRequired: 'Color is required. Cannot be left empty.',
                reasonCategoryRequired:
                  'Category is required. Cannot be left empty.',
                reasonOriginRequired:
                  'Origin is required. Cannot be left empty.',
                reasonRequired: 'The reason is required. Cannot be left empty.',
              },
            },

            terminal: {
              fields: {
                username: 'Username',
                pin: 'PIN',
                logout: 'Logout',
              },

              errors: {
                terminalUsernameLength:
                  'The username should be at least 3 characters long.',
                terminalPinLength:
                  'Invalid PIN length. The PIN should be 4 numbers long.',
                terminalInvalidPinFormat:
                  'Invalid PIN format. The PIN can only contain numbers.',
              },
            },

            machineStatus: {
              fields: {
                name: 'Name',
                activity: 'Activity',
                color: 'Color',
                category: 'Category',
                dataLayer: 'Data Layer',
                origin: 'Origin',
                machineStatusConfiguration: 'Machine Status Configuration',
                identifier: 'Identifier',
                machineType: 'Machine Type',
              },

              errors: {
                statusNameRequired:
                  'The name is required. Cannot be left blank.',
              },

              success: {
                machineStatusAdded:
                  'A new machine status has been added successfully.',
                machineStatusUpdated:
                  'The machine status has been updated successfully.',
                machineStatusDeleted:
                  'The machine status has been deleted successfully.',
              },
            },

            category: {
              errors: {
                categoryNameRequired:
                  'The name is required. Cannot be left blank.',
              },

              success: {
                categoryAdded: 'A new category has been added successfully.',
                categoryUpdated: 'The category has been updated successfully.',
                categoryDeleted: 'The category has been deleted successfully.',
              },
            },

            shared: {
              fields: {
                name: 'Name',
                businessID: 'Business ID',
                description: 'Description',
                city: 'City',
                inactive: 'Inactive',
                active: 'Active',
                disabled: 'Disabled',
                enabled: 'Enabled',
                timezone: 'Timezone',
                none: 'None',
                alias: 'Alias',
                type: 'Type',
                config: 'Configuration',
                structure: 'Structure',
                structures: 'Structures',
                sensors: 'Sensors',
                sortIndex: 'Sort Index',
                startTime: 'Start Time',
                endTime: 'End Time',
                duration: 'Duration',
                jobId: 'Job ID',
                partsProduced: 'Parts Produced',
                targetCount: 'Target Count',
                realisticCycleTime: 'Realistic Cycle Time',
                targetSetupTime: 'Target Setup Time',
                actualCount: 'Actual Count',
                reason: 'Reason',
                status: 'Status',

              },

              buttons: {
                merge: 'Merge',
                reset: 'Reset',
                submit: 'Submit',
                save: 'Save',
                clear: 'Clear',
                close: 'Close',
                cancel: 'Cancel',
                updatePulse: 'Update Pulse',
                goBack: 'GO BACK',
                activate: 'Activate',
                back: 'Back',
                next: 'Next',
                saveChanges: 'SAVE CHANGES',
                add: 'Add',
                remove: 'Remove',
                edit: 'Edit',
              },
            },
          },

          tables: {
            shared: {
              active: 'Active',
              machine: 'Machine',
              machines: 'Machines',
              actions: 'Actions',
              machineInformation: 'Machine Information',
              categories: 'Categories',
              reasons: 'Reasons',
              machineStatuses: 'Machine statuses',
              product: 'Product'
            },
            noData: "Sorry, there is no matching data to display.",
            sensor: {
              registrationDate: 'Registration Date',
              lastSeen: 'Last Seen',
            },
            textLabels: {
              body: {
                noMatch: "Sorry, no matching records found",
                noData: "Sorry, no data to display yet",
                toolTip: "Sort",
                columnHeaderTooltip: column => `Sort for ${column.label}`
              },
              pagination: {
                next: "Next Page",
                previous: "Previous Page",
                rowsPerPage: "Rows per page:",
                displayRows: "of",
              },
              toolbar: {
                search: "Search",
                downloadCsv: "Download CSV",
                print: "Print",
                viewColumns: "View Columns",
                filterTable: "Filter Table",
              },
              filter: {
                all: "All",
                title: "FILTERS",
                reset: "RESET",
              },
              viewColumns: {
                title: "Show Columns",
                titleAria: "Show/Hide Table Columns",
              },
              selectedRows: {
                text: "row(s) selected",
                delete: "Delete",
                deleteAria: "Delete Selected Rows",
              },
            }
          },
          confirmationModal: {
            textDeleteStaticReason: "Are you sure you want to stop the static reason for machine ",
            deactivateMachines: 'Deactivate machines',
            areYouSureDeactivateMachines:
              'Are you sure you want to deactivate these machines',
            areYouSureDeactivateMachine:
              'Are you sure you want to deactivate this machine',
            confirm: 'Confirm',
            cancel: 'Cancel',
            deactivateUsers: 'Deactivate users',
            areYouSureDeactivateUsers:
              'Are you sure you want to deactivate these users',
            areYouSureDeactivateUser:
              'Are you sure you want to deactivate this user',
            deactivateStructures: 'Deactivate structures',
            areYouSureDeactivateStructures:
              'Are you sure you want to deactivate these structures',
            areYouSureDeactivateStructure:
              'Are you sure you want to deactivate this structure',
            deactivateSensors: 'Deactivate sensors',
            areYouSureDeactivateSensors:
              'Are you sure you want to deactivate these sensors',
            areYouSureDeactivateSensor:
              'Are you sure you want to deactivate this sensor',
            deactivateReasons: 'Deactivate reasons',
            areYouSureDeactivateReasons:
              'Are you sure you want to deactivate these reasons',
            areYouSureDeactivateReason:
              'Are you sure you want to deactivate this reason',
          },

          machineStatusesText: {
            info: "Info",
            machineStatusColorHeading: "State Color",
            machineStatusNameHeading: "Title",
            machineReasonNameHeading: "Reason Title",
            machineReasonColorHeading: "Reason Color",
            machineStatusDescriptionHeading: "Description",
            running: 'Running',
            scheduled: 'Producing',
            slow_running: 'Slow Running',
            not_running: 'Not Running',
            unscheduled: 'Schedule Loss',
            creep: 'Break Creep',
            unscheduled_PO_presence: 'Vacant',
            not_running_PO_presence: 'Vacant',
            running_PO_presence: 'Occupied',
            running_PO_EOL: 'Test is running',
            waiting_PO_EOL: 'Waiting for part',
            test_done_PO_EOL: 'Test done',
            not_running_PO_EOL: 'Not Running',
            unscheduled_PO_EOL: 'Not Running',
            running_PO_rework: 'Productive',
            occupied_PO_rework: 'Occupied',
            not_running_PO_rework: 'Vacant',
            unscheduled_PO_rework: 'Vacant',
            maintenance: "Maintenance",
            descriptions: {
              machineStatusColorHeading: "Lorem Ipsum",
              machineStatusNameHeading: "Lorem Ipsum",
              machineStatusDescriptionHeading: "Lorem Ipsum",
              running: 'Lorem Ipsum',
              scheduled: 'Lorem Ipsum',
              slow_running: 'Lorem Ipsum',
              not_running: 'Lorem Ipsum',
              unscheduled: 'Lorem Ipsum',
              creep: 'Lorem Ipsum',
              unscheduled_PO_presence: 'Lorem Ipsum',
              not_running_PO_presence: 'Lorem Ipsum',
              running_PO_presence: 'Lorem Ipsum',
              running_PO_EOL: 'Lorem Ipsum',
              waiting_PO_EOL: 'Lorem Ipsum',
              test_done_PO_EOL: 'Lorem Ipsum',
              not_running_PO_EOL: 'Lorem Ipsum',
              unscheduled_PO_EOL: 'Lorem Ipsum',
              running_PO_rework: 'Lorem Ipsum',
              occupied_PO_rework: 'Lorem Ipsum',
              not_running_PO_rework: 'Lorem Ipsum',
              unscheduled_PO_rework: 'Lorem Ipsum',
              maintenance: 'Lorem Ipsum',
            }
          },

          reasonsText: {
            quality: 'Quality Testing',
            toolchange: 'Toolchange',
            setup: 'Setup',
            tool_breakage: 'Tool Breakage',
            lack_of_personnel: 'Lack of Personnel',
            other_break: 'Other Break',
            repair_pneumatics: 'Repair Pneumatics',
            coil_change: 'Coil Change',
            defective_material: 'Defective Material',
            material_lack: 'Lack of Material',
            repair_hydraulics: 'Repair Hydraulics',
            unavailable: 'Unavailable',
            cleaning: 'Maintenance / Cleaning',
            unscheduled: 'Unscheduled',
            repair_mechanics: 'Repair Mechanics',
            no_qc_approval: 'Waiting on qc approval',
            follow_up_process: 'Follow-up Process Unavailable',
            quality_issue: 'Quality issue',
            welder_adjustments: 'Welder Adjustments',
            repair_electrical: 'Repair Electrical',
            preventive_maintenance: 'Preventive Maintenance',
            break: 'Break',
            machine_adjustments: 'Machine Adjustments',
            missing_order: 'Missing Order',
            601: '601 - Drucker',
            602: '602 - Elektrode',
            603: '603 - Flanschelektrode',
            604: '604 - Platine',
            605: '605 - Längsschweißung',
            606: '606 - Querschweißung',
            607: '607 - Teller',
            608: '608 - Töpfe',
            609: '609 - Spender',
            610: '610 - Lichtschranke',
            611: '611 - Steuerung',
            612: '612 - Spreizung',
            613: '613 - Greifer',
            614: '614 - Glättung',
            615: '615 - Paddel',
            616: '616 - Säule',
            617: '617 - Sockel',
            618: '618 - Entnahme',
            619: '619 - Transport',
            620: '620 - Sicherheit',
            621: '621 - Stempel',
            622: '622 - Farbband',
            623: '623 - allgemein',
            624: '624 - Druckluft',
            401: '401 - Drucker',
            402: '402 - Elektrode',
            403: '403 - Flanschelektrode',
            404: '404 - Platine',
            405: '405 - Längsschweißung',
            406: '406 - Querschweißung',
            407: '407 - Teller',
            408: '408 - Töpfe',
            409: '409 - Spender',
            410: '410 - Lichtschranke',
            411: '411 - Steuerung',
            412: '412 - Spreizung',
            413: '413 - Greifer',
            414: '414 - Glättung',
            415: '415 - Paddel',
            416: '416 - Säule',
            417: '417 - Sockel',
            418: '418 - Entnahme',
            419: '419 - Transport',
            420: '420 - Stempel',
            421: '421 - Farbband',
            422: '422 - allgemein',
            423: '423 - Druckluft',
            301: '301 - Drucker',
            302: '302 - Elektrode',
            303: '303 - Flanschelektrode',
            304: '304 - Platine',
            305: '305 - Längsschweißung',
            306: '306 - Querschweißung',
            307: '307 - Teller',
            308: '308 - Töpfe',
            309: '309 - Spender',
            310: '310 - Lichtschranke',
            311: '311 - Steuerung',
            312: '312 - Spreizung',
            313: '313 - Greifer',
            314: '314 - Glättung',
            315: '315 - Paddel',
            316: '316 - Säule',
            317: '317 - Sockel',
            318: '318 - Entnahme',
            319: '319 - Transport',
            320: '320 - Sicherheit',
            321: '321 - Stempel',
            322: '322 - Farbband',
            323: '323 - allgemein',
            324: '324 - Druckluft',
            201: '201 - Drucker',
            202: '202 - Elektrode',
            203: '203 - Flanschelektrode',
            204: '204 - Platine',
            205: '205 - Längsschweißung',
            206: '206 - Querschweißung',
            207: '207 - Teller',
            208: '208 - Töpfe',
            209: '209 - Spender',
            210: '210 - Lichtschranke',
            211: '211 - Spreizung',
            212: '212 - Greifer',
            213: '213 - Glättung',
            214: '214 - Paddel',
            215: '215 - Säule',
            216: '216 - Sockel',
            217: '217 - Entnahme',
            218: '218 - Transport',
            219: '219 - Sicherheit',
            220: '220 - Stempel',
            221: '221 - Farbband',
            222: '222 - allgemein',
            223: '223 - Druckluft',
            101: '101 - Drucker',
            102: '102 - Elektrode',
            103: '103 - Flanschelektrode',
            104: '104 - Längsschweißung',
            105: '105 - Querschweißung',
            106: '106 - Spender',
            107: '107 - Lichtschranke',
            108: '108 - Steuerung',
            109: '109 - Spreizung',
            110: '110 - Greifer',
            111: '111 - Glättung',
            112: '112 - Paddel',
            113: '113 - Säule',
            114: '114 - Sockel',
            115: '115 - Entnahme',
            116: '116 - Transport',
            117: '117 - Sicherheit',
            not_defined: 'Undefined',
            break_creep: 'Break Creep',
            not_defined_PO_presence: 'Undefined',
            break_creep_PO_presence: 'Break Creep',
            micro_stop: 'Micro Stop'
          },

          scrapTypesText: {
            coil_change: 'Coil Change',
            defective_material: 'Defective material',
            setup: 'Setup',
            quality_testing: 'Quality testing',
          },
        };

      case 'DE':
      case 'de':
        return {
          nav: {
            profile: 'Profil',
            settings: 'Einstellungen',
            logout: 'Ausloggen',
            collapseSidebar: 'Seitenleiste einklappen',
            home: 'Home',
            structures: 'Strukturen',
            machines: 'Maschinen',
            sensors: 'Sensoren',
            users: 'Benutzer',
            manageStructures: 'Strukturen',
            manageMachines: 'Maschinen',
            roles: 'Rollen',
            sensorTypes: 'Sensortypen',
            machineTypes: 'Maschinentypen',
            structureOverview: 'Strukturübersicht',
            machineOverview: 'Maschinenübersicht',
            reasons: 'Stillstandsgründe',
            reasonsManagement: 'Stillstandsgründe',
            categoriesManagement: 'Kategorien',
            machineStatusesManagement: 'Maschinenstatus',
            terminal: 'Terminal',
            oee: 'OEE',
            report: 'Bericht',
            advanced_report: 'E-Bericht',
            schedule: 'Zeitplan',
            andon: 'Andon',
            views: 'Ansichten',
            management: 'Management',
            orders: 'Aufträge',
            supportTicket: 'Unterstüzungsticket',
            supportPage: 'Hilfe Website',
            help: 'Hilfe',
            machineNaming: 'Anlagen Benamung',
            machinePerformance: 'Leistungsübersicht',
            jobs: "Aufträge",
          },

          machineFilters: {
            default: 'default',
            availabilityminmax: 'verfuegbarkeit-min-max',
            availabilitymaxmin: 'verfuegbarkeit-max-min',
            performanceminmax: 'leistung-min-max',
            performancemaxmin: 'leistung-max-min',
            qualityminmax: 'qualitaet-min-max',
            qualitymaxmin: 'qualitaet-max-min',
          },

          pageTitles: {
            sensorsManagement: 'Sensormanagement',
            sensorTypesManagement: 'Sensortypen verwalten',
            usersManagement: 'Benutzer verwalten',
            rolesManagement: 'Rollen verwalten',
            machinesMangement: 'Maschinen verwalten',
            machineTypesManagement: 'Maschinentypen verwalten',
            structuresManagement: 'Strukturen verwalten',
            signIn: 'Einloggen',
            help: 'Hilfe',
          },

          tabs: {
            CreateStructure: 'Struktur erstellen',
            ExistingStructures: 'Existierende Strukturen',
            CreateMachine: 'Maschine erstellen',
            ExistingMachines: 'Existierende Maschinen',
            CreateSensor: 'Sensor erstellen',
            ExistingSensors: 'Existierende Sensoren',
            CreateUser: 'Benutzer erstellen',
            ExistingUsers: 'Existierende Benutzer',
            CreateRole: 'Benutzerrolle erstellen',
            ExistingRoles: 'Existierende Benutzerrollen',
            CreateSensorType: 'Sensortyp erstellen',
            ExistingSensorTypes: 'Existierende Sensortypen',
            CreateMachineType: 'Maschinentyp erstellen',
            ExistingMachineTypes: 'Existierende Maschinentypen',
            CreateReason: 'Stillstandsgrund erstellen',
            ExistingReasons: 'Existierende Stillstandsgründe',
            CreateCategory: 'Kategorie erstellen',
            ExistingCategories: 'Existierende Kategorien',
            CreateMachineStatus: 'Maschinenstatus erstellen',
            ExistingMachineStatuses: 'Existierende Maschinenstatus',
            UserLogin: 'Benutzeranmeldung',
            AppLogin: 'Login',
            TerminalLogin: 'Terminalanmeldung',
            SensorHealth: 'Sensorzustand',
          },

          errorMessages: {
            emailOrPasswordIncorret: 'Falsche E-Mail oder Passwort.',
            notAuthorized: 'Sie sind dazu nicht berechtigt.',
            pageNotFound:
              'Die Seite, auf die Sie zugreifen möchten, ist nicht vorhanden.',
            moduleNotFound:
              'Das Modul, auf das Sie zugreifen möchten, ist nicht verfügbar.',
            notAuthorizedToViewMachine:
              'Sie sind nicht berechtigt, diese Maschine anzuzeigen.',
            machineDoesNotExist: 'Diese Maschine ist nicht vorhanden.',
            noMaxCycleTime: 'Keine "maxCycleTime" in der Maschinenkonfiguration vorhanden.'
          },

          misc: {
            selectStructureYouWantToView:
              'Wählen Sie eine Struktur aus, die Sie anzeigen möchten.',
            selectMachineYouWantToView:
              'Wählen Sie eine Maschine aus, die Sie anzeigen möchten.',
            toContinueToLeanFocus: 'Weiter zu LEANFOCUS',
            noMachinesInThisStructure:
              'Keine Maschinen in der ausgewählten Struktur.',
            loading: 'Wird geladen...',
            minAvailability: 'Min. Verfügbarkeit',
            maxAvailability: 'Max. Verfügbarkeit',
            copyUrl: 'URL kopieren',
            urlCopySuccess: 'URL erfolgreich kopiert',
            availability: 'Verfügbarkeit',
            time: 'Ortszeit',
            search: 'Suche',
            overview: 'Überblick',
            showMachines: 'Maschinen anzeigen',
            machineStatusDistribution: 'Verteilung des Maschinenstatus',
            machineStatusTimeDistribution: 'Maschinenstatus Zeitverteilung',
            machineStatus: 'Maschinenstatus',
            deselectAll: 'Alle abwählen',
            sortBy: 'Sortieren nach',
            default: 'Standard',
            for: 'für',
            with: 'mit',
            average: 'Durchschnitt',
            applyFilters: 'Filter Anwenden',
            all: 'Alle',
            title: 'Filter',
            reset: 'Zurücksetzen',
          },

          modal: {
            EditStructure: 'Struktur bearbeiten',
            EditMachine: 'Maschine bearbeiten',
            EditSensor: 'Sensor bearbeiten',
            EditUser: 'Benutzer bearbeiten',
            EditRole: 'Rolle bearbeiten',
            EditSensorType: 'Sensortyp bearbeiten',
            CreateSensorType: 'Sensortyp erstellen',
            CreateMachineType: 'Maschinentyp erstellen',
            EditReason: 'Stillstandsgrund bearbeiten',
            EditCategory: 'Kategorie bearbeiten',
            EditMachineStatus: 'Maschinenstatus bearbeiten',
          },

          timespanSelection: {
            last: 'Letzte',
            from: 'Von',
            to: 'Bis',
            Last: 'Letzte',
            Hour: 'Stunde',
            Hours: 'Stunden',
            Minutes: 'Minuten',
            Minute: 'Minute',
            Days: 'Tage',
            Day: 'Tag',
            Weeks: 'Wochen',
            Week: 'Woche',
            LastHour: 'Letzte Stunde',
            Last6Hours: 'Letzte 6 Stunden',
            Last12Hours: 'Letzte 12 Stunden',
            Last24Hours: 'Letzte 24 Stunden',
            Last7Days: 'Letzte 7 Tage',
            ShowLastHour: 'Letzte Stunde anzeigen',
            ShowLast6Hours: 'Letzte 6 Stunden anzeigen',
            ShowLast12Hours: 'Letzte 12 Stunden anzeigen',
            ShowLast24Hours: 'Letzte 24 Stunden anzeigen',
            ShowLast7Days: 'Letzte 7 Tage anzeigen',
            Relative: 'Relative Zeitspanne',
            DateRange: 'Feste Zeitspanne',
            CustomTimePreset: "Eigene Zeitvorlage",
            ctpTitleAlreadyPresent: "Der Titel der Vorlage existiert bereits. Bitte wählen Sie einen anderen.",
            ctpTitleEmpty: "Der Titel ist leer oder ungültig",
            newPresetTitle: "Titel",
            addNewPreset: "Neue Vorlage hinzufügen",
            addNewPresetDescription: "Bitte geben Sie den Titel sowie die Start- und Endzeit der Vorlage ein.",
            deletePresetModalTitle: "Vorlage löschen",
            addPresetModalTitle: "Vorlage hinzufügen",
            areYouSureDeleteTimePreset: "Sind Sie sich sicher, dass sie die Vorlage löschen möchten?",
            dayOfPreset: "Tag der Vorlage",
            CustomBegin: "Beginn",
            CustomEnd: "Ende",
            SelectTimespan: 'Zeitspanne wählen',
            Today: 'Heute',
            ShowPulseForToday: 'Maschinenpulse für Heute anzeigen',
            Yesterday: 'Gestern',
            ShowPulseForYesterday: 'Maschinenpulse für Gestern anzeigen',
            ThisWeek: 'Diese Woche',
            ThisMonth: 'Dieser Monat',
            ThisYear: 'Dieses Jahr',
            ShowPulseForThisWeek: 'Maschinenpulse für diese Woche anzeigen',
            ShowPulseForThisMonth: 'Maschinenpulse für diesen Monat anzeigen',
            ShowPulseForThisYear: 'Maschinenpulse für dieses Jahr anzeigen',
            LastWeek: 'Letzte Woche',
            ShowPulseForLastWeek: 'Maschinenpulse für letzte Woche anzeigen',
            Presets: 'Voreinstellungen',
            CustomPresets: 'Eigene Voreinstellungen',
            RestoreDefaultTimespan: 'Standard wiederherstellen',
            localeDef: {
                "decimal": ",",
                "thousands": ".",
                "grouping": [3],
                "currency": ["€", ""],
                "dateTime": "%a %b %e %X %Y",
                "date": "%d.%m.%Y",
                "time": "%H:%M:%S",
                "periods": ["AM", "PM"],
                "days": ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
                "shortDays": ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
                "months": ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
                "shortMonths": ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"]
            },
            timeFormat: "dd. MMMM - HH:mm",
            dateFormat: "dd. MMMM",
          },

          modules: {
            andon: {
              config: 'ANDON Einstellungen',
              theme: 'Theme',
              light: 'Hell',
              dark: 'Dunkel',
              preview: 'ANDON Vorschau',
              loading: 'Wird geladen...',
              loadingMachines: 'Lade Maschinen',
              loadingStructure: 'Lade Struktur',
              noStructureSelected: 'Keine Struktur ausgewählt.',
              noMachines: 'Keine Maschinen in der ausgewählten Struktur.',
              slideshowInterval: 'Wechsel-Intervall',
              set: 'Festlegen',
              minutes: 'Minuten',
              seconds: 'Sekunden',
              minutesAbbr: 'Min',
              secondsAbbr: 'Sek',
            },

            machine: {
              legendModalTitle: "Definitionsübersicht",
              staticReason: "Grund",
              statusTime: "Zeit",
              loadingPulse: 'Laden des Produktionspulses...',
              runtime: 'Laufzeit',
              downtime: 'Ausfallzeit',
              running: 'Laufend',
              notRunning: 'Stillstand',
              online: 'Online',
              lastSeen: 'Zuletzt gesehen',
              never: 'nie',
              duration: 'Dauer',
              state: 'Status',
              machine: 'Maschine',
              drillDown: 'Detailansicht',
              machinesPermissions: 'Berechtigungen für Maschinen',
              machineTypesPermission: 'Berechtigungen für Maschinentypen',
              machineStatusesPermission: 'Berechtigungen für Maschinenstatus',
              availabilityLowToHigh: 'Verfügbarkeit: Niedrig zu hoch',
              availabilityHighToLow: 'Verfügbarkeit: Hoch zu niedrig',
              performanceLowToHigh: 'Performance: Niedrig zu hoch',
              performanceHighToLow: 'Performance: Hoch zu niedrig',
              loadingMachine: 'Lade Maschine ...',
              partsPerMinute: 'Teile/Minute',
              secPerPart: 'Sek/Teil',
              partsPerMinuteString: 'Teile/Minute',
              secPerPartString: 'Sekunden/Teil',
              currentSpeedTarget: 'Aktuelle Geschwindigkeit (Soll)',
              selectMachine: 'Bitte wählen Sie eine Maschine aus',
              sortBy: 'Filtern nach',
              qualityLowToHigh: 'Qualität: Niedrig zu hoch',
              qualityHighToLow: 'Qualität: Hoch zu niedrig',
              speedTarget: 'Ø Geschwindigkeit (Soll)',
              speedJob: 'Auftragsgeschwindigkeit (Soll)',
              splitState: 'Zeitspanne aufteilen',
              mergeState: 'Zeitspanne zusammenführen',
              merge: 'Zusammenführen',
              addPreviousState: 'Nächsten Status hinzufügen',
              addNextState: 'Vorherigen Status hinzufügen',
              noMoreStates: 'Keine weiteren Zeitspannen im ausgewählten Zeitbereich verfügbar',
              splitStateSuccessful: 'Die Zeitspanne wurde erfolgreich aufgeteilt.',
              mergeStateSuccessful: 'Die Zeitspannen wurden erfolgreich zusammengeführt.',
            },
            simple_machine: {
              default: "Hallo",
              parts: "Teile",
              shiftGoalLabel: "Schicht\n[bold]Ziel[/]",
              shiftGoal: "Schichtziel",
              timeGoal: "Zeitziel",
              unit: "Teile",
              gauge: "Gauge",
              progress: "Progress Bar",
              targetCylceTime: 'Soll-Zyklus Zeit',
              currentSpeed: 'Aktuelle Geschwindigkeit',
              partsPerHour: 'Teile/Stunde',
            },
            simple_machine2: {
              currentSpeed: 'Ist',
              targetSpeed: 'Soll',
              shiftSpeed: "Schicht",
              currentOrder: 'Aktueller Auftrag',
              customer: 'Kunde',  
              orderLoading: 'Lade Auftrag...',     
              product: "Produkt",       
              order: "Auftrag",
              shiftStart: "Schichtbeginn",
              shiftEnd: "Schichtende",
            },
            machine_shift: {
              shift: 'Schicht',
              shifts: 'Schichten',
              createNewMachineShift: 'Neue Schicht erstellen',
              existingMachineShifts: 'Existierende Schichten',
              confirmDeleteTitle: "Schicht löschen bestätigen",
              deleteConfirmation: "Sind sie sicher, dass Sie Schicht '{shiftName}' von {startTime} bis {endTime} löschen möchten?",
              editMachineShift: "Schicht bearbeiten",
              machineShiftPermission: "Berechtigungen für Schichten",
              errors:{
                endTimeLargerStartTime: "Endzeit muss größer als Startzeit sein",
                noShiftFound: "Keine Schicht im aktuellen Zeitraum gefunden",
                nameRequired: "Schichtname ist erforderlich",
                machineRequired: "Maschine ist erforderlich",
                startTimeRequired: "Startzeit ist erforderlich",
                endTimeRequired: "Endzeit ist erforderlich",
                isActiveRequired: "Ein Wert für Aktiv ist erforderlich",
                targetNumberLargerZero: "Zielanzahl muss größer als 0 sein",
                mondayRequired: "Ein Wert für Montag ist erforderlich",
                tuesdayRequired: "Ein Wert für Dienstag ist erforderlich",
                wednesdayRequired: "Ein Wert für Mittwoch ist erforderlich",
                thursdayRequired: "Ein Wert für Donnerstag ist erforderlich",
                fridayRequired: "Ein Wert für Freitag ist erforderlich",
                saturdayRequired: "Ein Wert für Samstag ist erforderlich",
                sundayRequired: "Ein Wert für Sonntag ist erforderlich",
              },
              forms: {
                machineShiftAdded: "Schicht wurde erfolgreich hinzugefügt",
                machineShiftUpdated: "Schicht wurde erfolgreich aktualisiert",
                name: "Schichtname",
                startTime: "Startzeit",
                endTime: "Endzeit",
                machine: "Maschine",
                machines: "Maschinen",
                monday: "Montag",
                tuesday: "Dienstag",
                wednesday: "Mittwoch",
                thursday: "Donnerstag",
                friday: "Freitag",
                saturday: "Samstag",
                sunday: "Sonntag",
                isActive: "Aktiv",
                targetNumber: "Zielanzahl",
              }
            },
            reporting: {
              minStartTime: 'Min. Startzeit',
              minEndTime: 'Min. Endzeit',
              maxStartTime: 'Max. Startzeit',
              maxEndTime: 'Max. Endzeit',
              minDuration: 'Min. Dauer',
              maxDuration: 'Max. Dauer',
              loadingReportData: 'Lade Berichtdaten',
              oeeTab: 'OEE',
              machineTab: 'Maschine',
              machineTabMachine: 'Maschine',
              jobTab: 'Aufträge',
              noMatchingData:
                'Leider können keine übereinstimmenden Daten angezeigt werden.',
              minAvailability: 'Min. Verfügbarkeit',
              maxAvailability: 'Max. Verfügbarkeit',
              loadingMachines: 'Lade Maschinen...',
              loadingQualityReports: 'Lade Qualitätsberichte...',
              actualIdealCount: 'Menge Ist/Soll',
              actualCount: 'Ist-Menge',
              goodCount: 'Gut-Menge',
              targetCount: 'Soll-Menge',
              scrapCount: 'Ausschuss',
              plannedDowntime: 'Geplanter Stillstand',
              downtime: 'Ungeplanter Stillstand',
              scheduleLoss: 'Schichtmäßiger Stillstand',
            },
            advanced_reporting: {
              machineRankingHeadingText: "Machinen Leistung - Übersicht",
              machineRankingChartID: "Maschinen Ranking",
              machineRankingValue: "performance",
              machineRankingCategory: "name",
              noPerformanceText: "N/A",
              noPerformanceValue: 0,
              reasonRankingHeadingText: "Schlimmste Stillstandsgründe",
              reasonRankingChartID: "Reason Ranking",
              reasonRankingValue: "count",
              reasonRankingCategory: "name",
              noReasonsText: "No reasons yet",
              noReasonsValue: 1,
              averageHeadingText: "Durschnittszeiten - Übersicht",
              averageChartID: "Average Overview",
              averageValue: "value",
              averageCategory: "category",
              fromText: "Von",
              toText: "Bis",
              timeSelectorHeading: "Zeitauswahl",
              timeSelectorTimeframeSelect: "Wählen Sie ein Zeitfenster",
              pickTime: "Geben Sie eine Zeit an (letzte Stunden)"
            },
            user: {
              signIn: 'Einloggen',
              noStructures:
                'Dem Benutzer sind keine Strukturen oder Maschinen zugeordnet.',
              noViews:
                'Dem Benutzer sind keine Ansichten für Strukturen oder Maschinen zugewiesen.',
              note: 'Hinweis',
              editingDisabled:
                'Das Bearbeiten der Systemadministratorrolle ist deaktiviert!',
              users: 'Benutzer',
              usersPermissions: 'Berechtigungen für Benutzer',
              deactivateUsers: 'Benutzer deaktivieren',
              oeeOverview: 'OEE Übersicht',
              andonBoard: 'Andon Board',
              comparisonOverview: 'Vergleichsübersicht',
              reportingOverview: 'Berichtsübersicht',
              scheduleOverview: 'Schichtübersicht',
              machineOverview: 'Maschinenübersicht',
              structureManagement: 'Strukturverwaltung',
              machineManagement: 'Machinenverwaltung',
              sensorManagement: 'Sensorverwaltung',
              userManagement: 'Benutzerverwaltung',
              structure: 'Struktur',
              machine: 'Maschine',
              homeview: 'Home Ansicht',
            },

            structure: {
              averageRuntime: 'Durchschnittliche Maschinenlaufzeit',
              averageDowntime: 'Durchschnittliche Maschinenstillstandszeit',
              machines: 'Maschinen',
              timespan: 'Zeitspanne',
              productionPulse: 'Produktionspuls',
              productionMachines: 'Produktionsanlagen',
              structureLoading: 'Lade Struktur...',
              runningCalculations: 'Berechnungen durchführen...',
              deactivateStructures: 'Strukturen deaktivieren',
              selectStructure: 'Bitte wählen Sie eine Struktur aus',
              machinesRunning: 'Maschinen laufen.',
              of: 'von',
            },

            performance: {
              slowRunning: 'Langsam',
              idle: 'Leerlauf',
              jobs: "Aufträge",
              availability: 'Verfügbarkeit',
              performance: 'Performance',
              minPerformance: 'Min. Performance',
              maxPerformance: 'Max. Performance',
              qualityShort: 'QUA',
              performanceShort: 'PER',
              availabilityShort: 'VER',
              avgSign: 'Ø',
              partsPerMinute: 'Teile/Min',
              quality: 'Qualität',
              oee: 'OEE',
              parts: 'Menge',
              partsProduced: 'Teile produziert',
              partsSurplus: 'Überschuss',
              partsMissed: 'Fehlende Teile',
              chartTargetCycleTime: 'Soll-Zyklus Zeit',
              target: 'Soll',
              chartParts: 'Teile',
              chartPartsSec: 'Sekunden',
              chartPartsProduced: 'Ist-Menge (Teile/Min)',
              chartPartsProducedSecPart: 'Ist-Menge (Sek/Teil)',
              chartPartsProducedPartsHour: 'Ist-Menge (Teile/Stunde)',
              chartPartsPartsHour: 'Teile/Stunde',
              chartPartsTitle: 'Produzierte Teile',
              chartPartsPartsMin: 'Teile/Min',
              chartPartsSecPart: 'Sek/Teil',
              isLoadingParts: 'Lade Teile...',
            },
            terminal: {
              productionCapacity: 'Produktionskapazität',
            },

            reasons: {
              reasons: 'Stillstandsgründe',
              noAssignedReasonsTimeframe: 'Keine Stillstandsgründe im ausgewählten Zeitraum.',
              deactivateReasons: 'Stillstandsgründe deaktivieren',
              categoriesPermissions: 'Berechtigungen für Kategorien',
              notesPermissions: 'Berechtigung für Notizen',
              reasonsPermissions: 'Berechtigung für Stillstandsgründe',
            },

            sensor: {
              deactivateSensors: 'Sensoren deaktivieren',
              sensorsPermission: 'Berechtigung für Sensoren',
              sensorTypesPermission: 'Berechtigung für Sensortypen',
              notConnected: 'Nicht verbunden',
              noSignal: 'Kein Signal',
              signalStrength: 'Signalstärke',
              rssiExcellent: 'Ausgezeichnet',
              rssiGood: 'Gut',
              rssiFair: 'Ausreichend',
              rssiWeak: 'Schwach',
              noMachine: 'Keine angeschlossene Maschine vorhanden',
              lastSeenAt: 'Zuletzt online: ',
              online: 'Online: ',
              rssiAverage: 'RSSI (7-Tage-Durchschnitt)',
              version: 'Version: ',
              hardware: 'Hardware: ',
            },

            roles: {
              rolesPermission: 'Berechtigungen für Rollen',
            },

            schedule: {
              date: 'Datum',
              today: 'HEUTE',
              dayTemplatePermissions: 'Berechtigungen für Tagesvorlagen',
              weekTemplatePermissions: 'Berechtigungen für Wochenvorlagen',
              schedulePermissions: 'Berechtigungen für Zeitplanung',
              shiftsPermissions: 'Berechtigungen für Schichten',
              activateSchedule: 'Zeitplan aktivieren',
              schedule: 'Zeitplan',
              noEligibleSchedules: 'Kein auswählbarer Zeitplan vorhanden.',
              changeActiveSchedule: 'Aktiven Zeitplan ändern',
              editSchedule: 'Zeitplan bearbeiten',
              editDay: 'Tag bearbeiten',
              deactivateTemplates: 'Vorlagen deaktivieren',
              existingShifts: 'Existierende Zeitpläne',
              createShift: 'Zeitplan erstellen',
              start: 'Start',
              end: 'Ende',
              scheduled: 'Geplant',
              duration: 'Dauer',
              preview: 'Vorschau',
              enabled: 'Aktiviert',
              shiftUpdated: 'Zeitplan erfolgreich aktualisiert.',
              shifts: 'Zeitpläne',
              editShift: 'Zeitplan bearbeiten',
              selectedTime: 'Ausgewählter Zeitraum: ',
              invalidStart:
                ' ist fehlerhaft da es in Konflikt mit dem Beginn der Schicht steht.',
              invalidEnd:
                ' ist fehlerhaft da es in Konflikt mit dem Ende der Schicht steht.',
              shiftStart: 'Schichtbeginn',
              shiftEnd: 'Schichtende',
              manageSchedules: 'Zeitpläne bearbeiten',
              createSchedule: 'Zeitplan erstellen',
              existingWeekTemplates: 'Existierende Wochenvorlagen',
              createWeekTemplate: 'Wochenvorlage erstellen',
              existingDayTemplates: 'Existierende Tagesvorlagen',
              createDayTemplate: 'Tagesvorlage erstellen',
              weekRemoved: 'Woche erfolgreich entfernt.',
              weekEdited: 'Woche erfolgreich bearbeitet.',
              dayEdited: 'Tag erfolgreich bearbeitet.',
              scheduleCreated: 'Zeitplan erfolgreich erstellt.',
              scheduleUpdated: 'Zeitplan erfolgreich geändert.',
              startDate: 'Startdatum',
              endDate: 'Enddatum',
              addWeek: 'Woche hinzufügen',
              editWeek: 'Woche bearbeiten',
              scheduleInfo: 'Zeitplaninformation',
              scheduleName: 'Name',
              scheduleDescription: 'Beschreibung:',
              lastDeployed: 'Zuletzt eingesetzt:',
              lastEdited: 'Zuletzt bearbeitet:',
              goTo: 'Gehe zu:',
              scheduleDeployed: 'Zeitplan erfolgreich eingesetzt.',
              deploymentCanceled: 'Das Deployment wurde erfolgreich gestoppt.',
              scheduleActivated: 'Zeitplan erfolgreich aktiviert.',
              scheduleDuplicated: 'Kopie erstellt.',
              deployment: 'Einsatz',
              activation: 'Aktivierung',
              areYouSureDeploy: 'Wollen Sie den Zeitplan einsetzten ',
              areYouSureDuplicate: 'Wollen Sie sicher ein Duplikat erstellen?',
              areYouSureCancel: 'Wollen Sie sicher das Deployment abbrechen?',
              areYouSureActivate: 'Wollen Sie den Zeitplan aktivieren ',
              overwriteOnMachines:
                'Dies wird alle an den unten aufgelisteten Maschinen vorgenommenen Änderungen überschreiben.',
              onTheseMachines: ' auf folgenden Maschinen:',
              editCreatedSchedule:
                'Wollen Sie den neu erstellten Zeitplan bearbeiten?',
              areYouSureDelete: 'Den Zeitplan wirklich löschen ',
              scheduleDeleted: 'Der Zeitplan wurde erfolgreich gelöscht.',
              schedules: 'Zeitpläne',
              areYouSure: 'Sind Sie sicher?',
              editNewSchedule: 'Neuen Zeitplan bearbeiten',
              doItLater: 'Später',
              deployingSchedule: 'Zeitplan einsetzen: ',
              cancelingDeployment: 'Stoppen des Deployments:',
              takeWhile: 'Je nach Anzahl der Maschine kann dies etwas dauern.',
              activatingSchedule: 'Aktiviere Zeitplan: ',
              addRemoveMachines: 'Hinzufügen/Entfernen von Maschinen',
              editMasterTemplate: 'Hauptvorlage bearbeiten',
              editDeployedSchedule: 'Eingesetzten Zeitplan bearbeiten',
              editNewWeekTemplate:
                'Wollen Sie die neu erstellte Vorlage bearbeiten?',
              deployingYourSchedule: 'Einsetzen des Zeitplans...',
              editNewTemplate: 'Neue Vorlage bearbeiten',
              scheduleCantBeEdited:
                'Der Zeitplan kann für das gewünschte Datum nicht mehr bearbeitet werden',
              selectedMachines: 'Ausgewählte Maschinen: ',
              weekTemplateDuplicated:
                'Die Wochenvorlage wurde erfolgreich dupliziert.',
              addDayTemplate: 'Tagesvorlage hinzufügen',
              scheduleTemplateUpdated:
                'Die Vorlage wurde erfolgreich aktualisiert.',
              weekTemplates: 'Wochenvorlage',
              editWeekTemplate: 'Wochenvorlage bearbeiten',
              weekTemplateCreated:
                'Die Wochenvorlage wurde erfolgreich erstellt.',
              weekTemplateUpdated:
                'Die Wochenvorlage wurde erfolgreich aktualisiert.',
              clearDay: 'Tag leeren',
              templateNameRequired: 'Vorlagenname ist erforderlich.',
              templateEvents: 'Vorlagenereignis ist erforderlich.',
              templateTimeframe:
                'Der Zeitraum der Vorlage muss komplett ausgefüllt sein.',
              scheduleNameRequired: 'Name des Zeitplans erforderlich.',
              scheduleToMachine:
                'Der Zeitplan muss mindestens mit einer Maschine verbunden sein.',
              startDateCantBeInThePast:
                'Startdatum darf nicht in der Vergangenheit liegen.',
              endDateCantBeBeforeStartDate:
                'Enddatum darf nicht vor dem Startdatum liegen.',
              atLeastOneWeekTemplate: 'Dem Zeitplan muss mindestens eine Wochevorlage hinzugegefügt werden.',
              dayTemplateDuplicated:
                'Eine Kopie der Tagesvorlage wurde erfolgreich erstellt.',
              dayTemplates: 'Tagesvorlage',
              editTemplate: 'Vorlage bearbeiten',
              dayTemplateCreated:
                'Die Tagesvorlage wurde erfolgreich erstellt.',
              dayTemplateUpdated:
                'Die Tagesvorlage wurde erfolgreich aktualisiert.',
              duplicate: 'Duplizieren',
              edit: 'Editieren',
              view: 'Anzeigen',
              delete: 'Löschen',
              hasExpired: 'Abgelaufen',
              hasNotExpired: 'Nicht abgelaufen',
              all: 'Alle',
              expiration: 'Ablauf',
              cancelDeployment: 'Deployment abbrechen',
              deploy: 'Deploy',
              activateOn: 'Aktivieren am:',
              activationScheduledSuccessfully: 'Altivierung wirde erfolgreich geplant.',
              activationCanceledSuccessfully: 'Geplante Aktivierung wurde erfolgreich beendet.',
              isDueForActivationOn: 'ist geplant zu aktibieren am',
              selectAllMachines: 'ALLE MASCHINEN AUSWÄHLEN',
              remove: "ENTFERNEN"
            },

            quality: {
              quality: 'Qualität',
              existingScrapTypes: 'Existierende Ausschussarten',
              createNewScrapType: 'Neue Ausschussart erstellen',
              scrapTypes: 'Ausschussarten',
              scrapType: 'Ausschussart',
              minQuality: 'Min. Qualität',
              maxQuality: 'Max. Qualität',
              count: 'Menge',
              qualityReportAdded:
                'Ein neuer Qualitätsbericht wurde erfolgreich erstellt.',
              qualityReportDeleted: 'Der Qualititätsbericht wurder erfolgreich gelöscht',
              qualityReport: 'Qualitätsbericht',
              alreadyAdded:
                ' wurde bereits hinzugefügt. Bitte wählen Sie "Bearbeiten" aus um Änderungen vorzunehmen.',
              productionTimePeriod: 'Produktionszeitraum',
              wasteCount: 'Ausschussmenge',
              totalWasteCount: 'Gesamte Ausschussmenge:',
              scrapTypeRequired: 'Ausschussart erforderlich',
              endTimeRequired: 'Endzeitpunkt benötigt',
              startTimeRequired: 'Startzeitpunkt benötigt',
              countRequired:
                'Menge benötigt. Kann nicht 0 sein oder leer gelassen werden.',

              invalidScrapNumber: 'Ungültige Menge an Ausschuss; kann nicht negativ sein.',
              tooMuchScrap: 'Ungültige Menge an Ausschuss; Anzahl zu groß',
              actualCountInvalid: 'Ist-Menge ist ungültig, Ausschuss Eintrag nicht möglich.',
              typeNameRequired: 'Ausschussart Name erforderlich.',
              typeIdentifierRequired: 'Ausschussart erforderlich.',
              categoryRequired: 'Kategorie erforderlich.',
              originRequired: 'Datenquelle erforderlich.',
              scrapTypesPermissions: 'Berechtigungen für Ausschussarten.',
              parts: 'Menge',
              noQualityReportSubmitted: 'Keine Qualitätsberichte im ausgewählten Zeitraum',
              qualityLosses: 'Qualitätsverluste',
              qualityReports: 'Qualitätsreport',
              deleteQualityReportModalTitle: 'Qualitätsreport löschen?',
              areYouSureDeleteQualityReport: 'Wollen Sie den Qualitätsreport wirklich löschen?',
              noQualityReportsSubmitted: 'Kein Qualitätsreport für den ausgewählten Zeitraum vorhanden.',
            },

            orders: {
              areYouSureDeleteJob: "Auftrag Löschen?",
              areYouSureDeleteJobText: "Sind Sie sich sicher, dass Sie den folgenden Auftrag löschen möchten?",
              orders: "Aufträge",
              edit: "Bearbeiten",
              delete: "Löschen",
              pastActiveJobs: "Vergangene aktive Jobs",
              noPastActiveJobsInTimeframe: 'Keine vergangenen aktiven Jobs im ausgewählten Zeitraum',
              ordersPermissions: 'Berechtigungen für Aufträge (Orders)',
              jobsPermissions: 'Berechtigungen für Arbeitsaufträge (Jobs) ',
              productsPermissions: 'Berechtigungen für Produkte',
              jobId: 'Auftragsnummer',
              progress: "Fortschritt (%)",
              speedJob: "Geschwindikeit (TPM)",
              activeJobs: 'Aktiver Auftrag',
              orderId: 'Bestellnummer ',
              status: 'Status',
              plannedStartDate: 'Plan-Start',
              actualStartDate: 'Ist-Start',
              target: 'Soll-Menge',
              sensorCount: 'Ist-Menge',
              goodCount: 'Gut-Menge',
              scrapCount: 'Gesamter Ausschuss',
              handCount: 'Manuell gezählt',
              machine: 'Maschine',
              intended: 'Geplant',
              operator: 'Bediener',
              structureField: 'structure',
              intendedMachineField: 'intendedMachine',
              intendedMachine: 'Geplante Maschine',
              structure: 'Struktur',
              startTime: 'Startzeit',
              endTime: 'Endzeit',
              duration: 'Dauer ',
              uploadCSV: 'CSV Hochladen',
              chooseFileToUpload: 'Datei zum Hochladen auswählen',
              chooseFile: 'Datei wählen',
              close: 'Schließen',
              upload: 'Hochladen',
              uploading: 'Hochladen',
              uploadAnotherFile: 'Eine andere Datei hochladen',
              jobsCreatedSuccessfully: 'Aufträge erfolgreich erstellt',
              backToUploadFile: 'Zurück zum Hochladen der Datei',
              fileHasBeenUploaded: 'Datei wurde hochgeladen.',
              uploadAgainWithCorrections:
                'Die Datei muss mit folgenden Korrekturen erneut hochgeladen werden:',
              row: 'Reihe',
              backToJobsList: 'Zurück zur Auftragsliste',
              product: 'Produkt',
              description: 'Beschreibung',
              jobStatus: 'Auftragsstatus',
              targetCount: 'Soll-Menge',
              targetCycleTime: 'Soll-Zyklus Zeit',
              targetSetupTime: 'Soll-Setup Zeit',
              engagements: 'Einsätze',
              operatorNotes: 'Bedienernotizen',
              loadingJob: 'Lade Auftrag...',
              existingNotes: 'Vorhandene Notizen',
              enterNoteText: 'Notiz eingeben',
              addNote: 'Notiz hinzufügen',
              clearScreen: 'Ansicht zurücksetzen',
              start: 'Start',
              stop: 'Stopp',
              setJobStatus: 'Stellen Sie den Auftragsstatus ein. ',
              ACTIVE: 'AKTIV',
              OPEN: 'OFFEN',
              PAUSED: 'PAUSIERT',
              COMPLETED: 'ABGESCHLOSSEN',
              setJobStatusPaused: 'Auftrag pausieren',
              setJobStatusCompleted:
                'Auftrag abschließen',
              currentlyEngaged: 'Aktuell im Einsatz',
              jobTotalCount: 'Gesamtanzahl der Aufträge',
              speed: 'Geschwindigkeit',
              actual: 'Aktuell',
              setup: 'Setup',
              warning: 'Warnung',
              areYouSureOverwriteHandcount:
                'Manuelle Zählung überschreiben?',
              byClickingSubmit:
                'Durch Bestätigen wird die manuelle Zählung gesetzt auf:',
              willBeDeleted: 'als vorheriger Wert wird gelöscht.',
              yes: 'Ja',
              no: 'Nein',
              STARTED: 'GESTARTET',
              ENDED: 'BEENDET',
              intendedTable: 'geplant',
              unintendedTable: 'ungeplant',
              fulltime: 'Volles Zeitfenster',
              timeframe: 'Zeitfenster',
              pastTwoWeeks: 'Vergangene 2 Wochen',
              pastMonth: 'Verganger Monat',
              pastSixMonths: 'Vergangene 6 Monate',
              pastYear: 'Vergangenes Jahr',
              jobProgress: 'Auftragsfortschritt',
              jobProgressOverview: 'Auftragsfortschritt Übersicht',
              existingJobs: 'Alle Aufträge',
              createNewJob: "Neuen Auftrag erstellen",
              activationOverview: 'Aktivierung Übersicht',
              addedHandCount: 'Manuelle Zählung',
            },
            machinePerformance: {
              machinePerformance: 'Machinen Leistungsbewertung',
              theoreticalOutput: 'Theoretische Maximalleistung',
              workingOutput: 'Theoretische Arbeitsleistung',
              scheduledOutput: 'Geplante Gesamtleistung',
              downtimeLoss: 'Unterbrechungsverluste',
              speedLoss: 'Geschwindigkeitsverluste',
              qualityLoss: 'Qualitätsverluste',
              theoreticalOutputDesc: 'Theoretische Maximalzahl an produzierten Teilen, wenn die Maschine 24/7 in Betrieb ist.',
              workingOutputDesc: 'Theoretische Anzahl produzierter Teile, wenn nach Schichtplan produziert wird.',
              scheduledOutputDesc: 'Theoretische Anzahl produzierter Teile nach Schichtplan und geplanten Stops.',
              downtimeLossDesc: 'Theoretische maximale Anzahl produzierter Teile während die Maschine lief.',
              speedLossDesc: 'Produzierte Teile',
              qualityLossDesc: 'Qualitativ gute Teile',
              performanceLoading: 'Leistungsbewertung wird geladen...',
              unit: 'Teile',
              totalTime: 'Gesamtzeit',
              maxCycleTime: 'Max. Teile pro Minute',
              unitMaxCycleTime: 'Teile/min',
              noPerformance: 'Keine Leistungsdaten verfügbar.',
              plannedReasons: 'Geplante Pausenzeit',
              plannedReasonsHeading: 'Geplante Stillstandsgründe',
              runningTime: 'Gesamte Laufzeit',
              avgPartsPerMinute: 'Durchschnittliche Teile pro Minute',
              avgScrapPerMinute: 'Durchschnittlicher Ausschuss pro Minute',
              total: 'Gesamt',
              totalDowntimeLoss: 'Gesamtverlust durch Ausfall',
              delta: 'Delta',
              reasonsHeading: 'Stillstandsgründe',
              qualityLossesHeading: 'Qualitätsverluste',
              backToMachineView: 'Zurück zur Maschinenübersicht',
              scheduledWorkingTime: 'Geplante Arbeitszeit',
              overtime: 'Zusätzliche Arbeitszeit',
              plannedRunningTime: "Geplante Laufzeit"
            }
          },

          forms: {
            jobs: {
              product: "Produkt",
              targetCycleTime: 'Soll-Zyklus Zeit',
              targetSetupTime: 'Soll-Setup Zeit',
              targetQuantity: "Sollmenge",
              description: "Beschreibung",
              intendedMachine: 'Geplante Maschine',
              scanCode: "Scan Code",
              handCount: 'Manuell gezählt',
              completionStatus: "Status",
              businessJobId: "Business ID",
              modalTitle: "Auftrag bearbeiten",
              jobEditSuccess: "Auftrag gespeichert",
              jobEditFailure: "Fehler beim Speichern des Auftrages",
              jobDeleteSuccess: "Auftrag erfolgreich gelöscht",
              jobDeleteFailure: "Fehler beim Löschen des Auftrages",
              validate: {
                handCountRequired: "Anzahl für manuell gezählte Teile ist erforderlich",
                descriptionRequired: "Beschreibung des Auftrages ist erforderlich",
                completionStatusRequired: "Ein Auftragsstatus ist erforderlich",
                intendedMachineRequired: "Eine Maschine muss ausgewählt werden",
                targetQuantityRequired: "Eine Soll-Menge ist erforderlich",
                targetSetupTimeRequired: "Eine Soll-Setup Zeit ist erforderlich",
                targetCycleTimeRequired: "Eine Soll-Zyklus Zeit ist erforderlich",
                productRequired: "Ein Produkt muss ausgewählt werden"
              }
            },
            machine: {
              fields: {
                placeNumber: 'Stellplatz',
                manufacturer: 'Hersteller',
                machineConfiguration: 'Maschinenkonfiguration',
                configurationName: 'Name',
                configurationValue: 'Wert',
              },

              buttons: {
                removeField: 'Entfernen',
                addField: 'Hinzufügen',
              },

              errors: {
                machineNameRequired:
                  'Der Maschinenname ist erforderlich. Kann nicht leer gelassen werden.',
                structureRequired:
                  'Die Struktur ist erforderlich. Kann nicht leer gelassen werden.',
                machineTypeRequired:
                  'Der Maschinentyp ist erforderlich. Kann nicht leer gelassen werden.',
                nameCannotBeBlank:
                  'Der Name ist erforderlich. Kann nicht leer gelassen werden.',
                genericError:
                  'Etwas ist schief gelaufen. Bitte versuchen Sie es später noch einmal.',
                sensorPerMachine:
                  'Die aktuelle Version unterstützt nur einen Sensor pro Maschine.',
                machineStatusName:
                  'Der Maschinenstatus-Name ist erforderlich. Kann nicht leer gelassen werden.',
                machineStatusActivity:
                  'Die Maschinenstatus-Aktivität ist erforderlich. Kann nicht leer gelassen werden.',
                machineStatusColor:
                  'Die Farbe ist erforderlich. Kann nicht leer gelassen werden.',
                machineStatusCategory:
                  'Die Kategorie ist erforderlich. Kann nicht leer gelassen werden.',
                machineStatusDataLayer:
                  'Die Datenschicht ist erforderlich. Kann nicht leer gelassen werden.',
              },

              success: {
                machineAdded:
                  'Eine neue Maschine wurde erfolgreich hinzugefügt.',
                machineUpdated: 'Die Maschine wurde erfolgreich aktualisiert.',
                machineTypeAdded:
                  'Ein neuer Maschinentyp wurde erfolgreich hinzugefügt.',
                machineTypeUpdated:
                  'Der Maschinentyp wurde erfolgreich aktualisiert.',
                machineTypeDeleted:
                  'Der Maschinentyp wurde erfolgreich gelöscht.',
                machineConfigSent:
                  'Die Maschinenkonfiguration wurde erfolgreich gesendet.',
              },
            },

            sensor: {
              fields: {
                sensorID: 'Sensor ID',
                measurementUnit: 'Maßeinheit',
              },

              errors: {
                sensorIdRequired:
                  'Die Sensor ID ist erforderlich. Kann nicht leer gelassen werden.',
                aliasRequired:
                  'Der Sensor Alias ist erforderlich. Kann nicht leer gelassen werden.',
                sensorTypeRequired:
                  'Der Sensortyp ist erforderlich. Kann nicht leer gelassen werden.',
                isActiveUndefined:
                  'Aktives Umschalten ist erforderlich. Kann nicht undefiniert bleiben.',
                genericError:
                  'Etwas ist schief gelaufen. Bitte versuchen Sie es später noch einmal.',
                sensorNameRequired:
                  'Der Sensorname ist erforderlich. Kann nicht leer gelassen werden.',
                measurementUnitRequired:
                  'Die Maßeinheit ist erforderlich. Kann nicht leer gelassen werden.',
              },

              success: {
                sensorAdded: 'Ein neuer Sensor wurde erfolgreich hinzugefügt.',
                sensorUpdated: 'Der Sensor wurde erfolgreich aktualisiert.',
                sensorDeleted: 'Der Sensor wurde erfolgreich gelöscht.',
                sensorTypeAdded:
                  'Ein neuer Sensortyp wurde erfolgreich hinzugefügt.',
                sensorTypeUpdated:
                  'Der Sensortyp wurde erfolgreich aktualisiert.',
                sensorTypeDeleted: 'Sensortyp erfolgreich gelöscht.',
              },
            },

            structure: {
              fields: {
                country: 'Land',
                parentStructure: 'Übergeordnete Struktur',
                structure: 'Struktur',
              },

              errors: {
                notAuthorized:
                  'Sie sind nicht berechtigt, diese Struktur anzuzeigen.',
                structureDoesntExist:
                  'Die Struktur, auf die Sie zugreifen möchten, ist nicht vorhanden.',
                structureNameRequired:
                  'Der Strukturname ist erforderlich. Kann nicht leer gelassen werden.',
                structureCityRequired:
                  'Der Ort ist erforderlich. Kann nicht leer gelassen werden.',
                structureCountryRequired:
                  'Das Land ist erforderlich. Kann nicht leer gelassen werden.',
                structureTimezoneRequired:
                  'Die Zeitzone der Struktur ist erforderlich. Kann nicht leer gelassen werden.',
                genericError:
                  'Etwas ist schief gelaufen. Bitte versuchen Sie es später noch einmal.',
              },

              success: {
                structureAdded:
                  'Eine neue Struktur wurde erfolgreich hinzugefügt.',
                structureUpdated:
                  'Die Struktur wurde erfolgreich aktualisiert.',
                structureDeleted: 'Die Struktur wurde erfolgreich gelöscht.',
              },
            },

            user: {
              fields: {
                email: 'E-Mail',
                password: 'Passwort',
                businessUserId: 'Geschäftsnutzer-ID',
                fullName: 'Vollständiger Name',
                confirmPassword: 'Passwort Bestätigen',
                username: 'Benutzername',
                pin: 'PIN',
                confirmPin: 'PIN bestätigen',
                language: 'Sprache',
                role: 'Rolle',
                defaultView: 'Standardansicht',
              },

              errors: {
                otherError:
                  'Etwas ist schief gelaufen. Bitte versuchen Sie es später noch einmal.',
                invalidEmailFormat:
                  'Ungültiges E-Mail Format. Sollte user@site.com sein.',
                roleNameRequired:
                  'Der Rollenname ist erforderlich. Kann nicht leer gelassen werden.',
                fullNameRequired:
                  'Der Name ist erforderlich. Kann nicht leer gelassen werden.',
                emailRequired:
                  'Die E-Mail ist erforderlich. Kann nicht leer gelassen werden.',
                passwordRequired:
                  'Das Passwort ist erforderlich. Kann nicht leer gelassen werden.',
                passwordMismatch:
                  'Das Passwort stimmt nicht überein. Bitte versuche es erneut.',
                passwordLength:
                  'Das Passwort muss mindestens 8 Zeichen lang sein.',
                businessidInUse:
                  'Die Geschäftsnutzer-ID wird bereits verwendet. Bitte wählen Sie eine andere.',
                languageRequired:
                  'Die Sprache ist erforderlich. Kann nicht leer gelassen werden.',
                roleRequired:
                  'Die Benutzerrolle ist erforderlich. Kann nicht leer gelassen werden.',
                structuresRequired:
                  'Die Strukturen sind erforderlich. Kann nicht leer gelassen werden.',
                structureViewRequired:
                  'Die Strukturansicht ist erforderlich. Kann nicht leer gelassen werden.',
                structureRequired:
                  'Eine Struktur ist erforderlich. Kann nicht leer gelassen werden.',
                machineViewRequired:
                  'Die Maschinenansicht ist erforderlich. Kann nicht leer gelassen werden.',
                machineRequired:
                  'Eine Maschine ist erforderlich. Kann nicht leer gelassen werden.',
                homeViewRequired:
                  'Die Standardansicht ist erforderlich. Kann nicht leer gelassen werden.',
                adminCannotBeDeleted:
                  'Die Systemadministratorrolle kann nicht gelöscht werden!',
                userAlreadyExists:
                  'Ein Benutzer mit dieser Email existiert bereits.',
              },

              success: {
                roleAdded:
                  'Eine neue Benutzerrolle wurde erfolgreich hinzugefügt.',
                roleUpdated:
                  'Die Benutzerrolle wurde erfolgreich aktualisiert.',
                roleDeleted: 'Die Benutzerrolle wurde erfolgreich gelöscht',
                userAdded: 'Ein neuer Benutzer wurde erfolgreich hinzugefügt.',
                userUpdated: 'Der Benutzer wurde erfolgreich aktualisiert.',
                userDeleted: 'Der Benutzer wurde erfolgreich gelöscht.',
              },
            },

            reason: {
              fields: {
                name: 'Name',
                identifier: 'Kennung',
                description: 'Beschreibung',
                color: 'Farbe',
                category: 'Kategorie',
                machineType: 'Maschinentyp',
                roles: 'Benutzerrollen',
                origin: 'Datenquelle',
                reasonConfiguration: 'Konfiguration Stillstandsgründe',
              },

              misc: {
                staticReasonActive: "Statischer Grund ist aktiv!",
                machineStatus: 'Maschinenstatus',
                reason: 'Stillstandsgrund',
                note: 'Notiz',
                assignReason: 'Stillstandsgrund',
                assignStaticReason: 'Fester Stillstandsgrund',
                changeReason: 'Stillstandsgrund ändern',
                editReason: 'Stillstandsgrund bearbeiten',
                editStaticReason: 'Festen Stillstandsgrund bearbeiten',
                removeReason: 'Stillstandsgrund entfernen',
                loadingReasons: 'Stillstandsgründe werden geladen...',
              },

              errors: {
                staticReasonAdded: 'Ein neuer statischer Stillstandsgrund wurde für diese Maschine gesetzt.',
                staticReasonDeleted: 'Der statische Stillstandsgrund wurde für diese Maschine gelöscht.',
                reasonNameRequired:
                  'Der Name für den Stillstandsgrund ist erforderlich. Kann nicht leer gelassen werden.',
                reasonIdentifierRequired:
                  'Die Kennung des Stillstandsgrund ist erforderlich. Kann nicht leer gelassen werden.',
                reasonColorRequired:
                  'Die Farbe ist erforderlich. Kann nicht leer gelassen werden.',
                reasonCategoryRequired:
                  'Die Kategorie ist erforderlich. Kann nicht leer gelassen werden.',
                reasonOriginRequired:
                  'Die Datenquelle ist erforderlich. Kann nicht leer gelassen werden.',
                reasonRequired:
                  'Der Stillstandsgrund ist erforderlich. Kann nicht leer gelassen werden.',
              },

              success: {
                reasonAdded:
                  'Ein neuer Stillstandsgrund wurde erfolgreich hinzugefügt.',
                reasonUpdated:
                  'Der Stillstandsgrund wurde erfolgreich aktualisiert.',
                reasonDeleted:
                  'Der Stillstandsgrund wurde erfolgreich gelöscht.',
              },
            },

            terminal: {
              fields: {
                username: 'Benutzername',
                pin: 'PIN',
                logout: 'Logout',
              },

              errors: {
                terminalUsernameLength:
                  'Der Benutzername muss mindestens 3 Zeichen lang sein.',
                terminalPinLength:
                  'Ungültige PIN-Länge. Die PIN sollte 4 Nummern lang sein.',
                terminalInvalidPinFormat:
                  'Ungültiges PIN-Format. Die PIN kann nur Zahlen enthalten.',
              },
            },

            machineStatus: {
              fields: {
                name: 'Name',
                identifier: 'Kennung',
                color: 'Farbe',
                category: 'Kategorie',
                machineType: 'Maschinentyp',
                origin: 'Datenquelle',
                machineStatusConfiguration: 'Maschinenstatuskonfiguration',
                activity: 'Aktivität',
                dataLayer: 'Data Layer',
              },

              errors: {
                reasonNameRequired:
                  'Der Name ist erforderlich. Kann nicht leer gelassen werden.',
              },

              success: {
                reasonAdded:
                  'Ein neuer Stillstandsgrund wurde erfolgreich hinzugefügt.',
                reasonUpdated:
                  'Der Stillstandsgrund wurde erfolgreich aktualisiert.',
                reasonDeleted:
                  'Der Stillstandsgrund wurde erfolgreich gelöscht.',
              },
            },

            category: {
              errors: {
                categoryNameRequired:
                  'Der Name ist erforderlich. Kann nicht leer gelassen werden.',
              },

              success: {
                categoryAdded:
                  'Eine neue Kategorie wurde erfolgreich hinzugefügt.',
                categoryUpdated:
                  'Die Kategorie wurde erfolgreich aktualisiert.',
                categoryDeleted: 'Die Kategorie wurde erfolgreich gelöscht.',
              },
            },

            shared: {
              fields: {
                name: 'Name',
                businessID: 'Business ID',
                description: 'Beschreibung',
                city: 'Ort',
                inactive: 'Inaktiv',
                active: 'Aktiv',
                disabled: 'Deaktiviert',
                enabled: 'Aktiviert',
                timezone: 'Zeitzone',
                none: 'Keiner',
                alias: 'Alias',
                config: 'Koniguration',
                type: 'Typ',
                structure: 'Struktur',
                structures: 'Strukturen',
                sensors: 'Sensoren',
                sortIndex: 'Sortierindex',
                startTime: 'Startzeit',
                endTime: 'Endzeit',
                duration: 'Laufzeit',
                jobId: 'Job ID',
                partsProduced: 'Produzierte Teile',
                targetCount: 'Soll Menge',
                realisticCycleTime: 'Realistische Zyklus Zeit',
                targetSetupTime: 'Soll-Setup Zeit',
                actualCount: 'Tatsächliche Menge',
                reason: 'Ausfallgrund',
                status: 'Status',
              },

              buttons: {
                merge: 'Zusammenführen',
                reset: 'Zurücksetzen',
                submit: 'Anwenden',
                save: 'Speichern',
                clear: 'Inhalte löschen',
                close: 'Schließen',
                cancel: 'Abbrechen',
                updatePulse: 'Anwenden',
                goBack: 'Zurück',
                activate: 'Aktivieren',
                back: 'Zurück',
                next: 'Weiter',
                saveChanges: 'ÄNDERUNGEN SPEICHERN',
                add: 'Hinzufügen',
                remove: 'Entfernen',
                edit: 'Bearbeiten',
              },
            },
          },

          tables: {
            shared: {
              active: 'Aktiv',
              machine: 'Maschine',
              machines: 'Maschinen',
              actions: 'Aktionen',
              machineInformation: 'Maschineninformationen',
              product: 'Produkt'
            },
            noData: 'Entschuldigung, es wurden keine passenden Einträge gefunden.',
            sensor: {
              registrationDate: 'Registrierungsdatum',
              lastSeen: 'Zuletzt gesehen',
            },
            textLabels: {
              body: {
                noMatch: "Entschuldigung, es wurden keine passenden Einträge gefunden",
                noData: "Entschuldigung, es liegen noch keine Daten vor",
                toolTip: "Sortieren",
                columnHeaderTooltip: column => `Sortieren nach ${column.label}`
              },
              pagination: {
                next: "Nächste Seite",
                previous: "Vorherige Seite",
                rowsPerPage: "Reihen pro Seite:",
                displayRows: "von",
              },
              toolbar: {
                search: "Suchen",
                downloadCsv: "Download CSV",
                print: "Ausdrucken",
                viewColumns: "Spalten anzeigen",
                filterTable: "Tabelle filtern",
              },
              filter: {
                all: "Alle",
                title: "FILTER",
                reset: "ZURÜCKSETZEN",
              },
              viewColumns: {
                title: "Zeig Spalte ",
                titleAria: "Zeige/Verdecke Tabellenspalten",
              },
              selectedRows: {
                text: "ausgewählt(e) Reihe(n)",
                delete: "Löschen",
                deleteAria: "Lösche ausgewählte Reihen",
              },
            }
          },

          confirmationModal: {
            textDeleteStaticReason: "Sind Sie sicher, dass Sie den statischen Stillstandsgrund beenden wollen? Maschine: ",
            deactivateMachines: 'Maschinen deaktivieren',
            areYouSureDeactivateMachines:
              'Sind Sie sicher, dass Sie die ausgewählten Maschinen deaktivieren wollen?',
            areYouSureDeactivateMachine:
              'Sind Sie sicher, dass Sie die ausgewählte Maschine deaktivieren wollen?',
            confirm: 'Bestätigen',
            cancel: 'Abbrechen',
            deactivateUsers: 'Benutzer deaktivieren',
            areYouSureDeactivateUsers:
              'Sind Sie sicher, dass Sie die ausgewählten Benutzer deaktivieren wollen?',
            areYouSureDeactivateUser:
              'Sind Sie sicher, dass Sie den ausgewählten Benutzer deaktivieren wollen?',
            deactivateStructures: 'Strukturen deaktivieren',
            areYouSureDeactivateStructures:
              'Sind Sie sicher, dass Sie die ausgewählten Strukturen deaktivieren wollen?',
            areYouSureDeactivateStructure:
              'Sind Sie sicher, dass Sie die ausgewählte Struktur deaktivieren wollen?',
            deactivateSensors: 'Sensoren deaktivieren',
            areYouSureDeactivateSensors:
              'Sind Sie sicher, dass Sie die ausgewählten Sensoren deaktivieren wollen?',
            areYouSureDeactivateSensor:
              'Sind Sie sicher, dass Sie den ausgewählten Sensoren deaktivieren wollen?',
            deactivateReasons: 'Stillstandsgründe deaktivieren',
            areYouSureDeactivateReasons:
              'Sind Sie sicher, dass Sie die ausgewählten Stillstandsgründe deaktivieren wollen?',
            areYouSureDeactivateReason:
              'Sind Sie sicher, dass Sie den ausgewählten Stillstandsgrund deaktivieren wollen?',
          },

          machineStatusesText: {
            info: "Info",
            machineStatusColorHeading: "Statusfarbe",
            machineStatusNameHeading: "Titel",
            machineReasonNameHeading: "Ausfallgrund Titel",
            machineReasonColorHeading: "Ausfallgrund Farbe",
            machineStatusDescriptionHeading: "Beschreibung",
            running: 'Produzierend',
            scheduled: 'Geplant produzierend',
            slow_running: 'Langsam produzierend',
            not_running: 'Ungeplanter Stillstand',
            unscheduled: 'Geplanter Stillstand',
            creep: 'Pausenüberzieher',
            unscheduled_PO_presence: 'Unbesetzt',
            not_running_PO_presence: 'Unbesetzt',
            running_PO_presence: 'Besetzt',
            running_PO_EOL: 'Prüfung läuft',
            waiting_PO_EOL: 'Warten auf Teil',
            test_done_PO_EOL: 'Prüfung fertig',
            not_running_PO_EOL: 'Stillstand',
            unscheduled_PO_EOL: 'Ungeplant',
            running_PO_rework: 'Produktiv',
            occupied_PO_rework: 'Besetzt',
            not_running_PO_rework: 'Unbesetzt',
            unscheduled_PO_rework: 'Unbesetzt',
            maintenance: 'Wartung',
            descriptions: {
              machineStatusColorHeading: "Lorem Ipsum",
              machineStatusNameHeading: "Lorem Ipsum",
              machineStatusDescriptionHeading: "Lorem Ipsum",
              running: 'Lorem Ipsum',
              scheduled: 'Lorem Ipsum',
              slow_running: 'Lorem Ipsum',
              not_running: 'Lorem Ipsum',
              unscheduled: 'Lorem Ipsum',
              creep: 'Lorem Ipsum',
              unscheduled_PO_presence: 'Lorem Ipsum',
              not_running_PO_presence: 'Lorem Ipsum',
              running_PO_presence: 'Lorem Ipsum',
              running_PO_EOL: 'Lorem Ipsum',
              waiting_PO_EOL: 'Lorem Ipsum',
              test_done_PO_EOL: 'Lorem Ipsum',
              not_running_PO_EOL: 'Lorem Ipsum',
              unscheduled_PO_EOL: 'Lorem Ipsum',
              running_PO_rework: 'Lorem Ipsum',
              occupied_PO_rework: 'Lorem Ipsum',
              not_running_PO_rework: 'Lorem Ipsum',
              unscheduled_PO_rework: 'Lorem Ipsum',
              maintenance: 'Lorem Ipsum',
            }
          },

          reasonsText: {
            quality: 'Qualitätssicherung',
            toolchange: 'Umrüstung',
            setup: 'Einrichtung',
            tool_breakage: 'Werkzeugschaden',
            lack_of_personnel: 'Personalmangel',
            other_break: 'Andere Unterbrechung',
            repair_pneumatics: 'Reparatur Pneumatik',
            coil_change: 'Coil-/Rollenwechsel',
            defective_material: 'Materialdefekt',
            material_lack: 'Materialmangel',
            repair_hydraulics: 'Reparatur Hydraulik',
            unavailable: 'Nicht verfügbar',
            cleaning: 'Reinigung / Wartung',
            unscheduled: 'Geplanter Stillstand',
            repair_mechanics: 'Reparatur Mechanik',
            no_qc_approval: 'Q.S. Freigabe ausstehend',
            follow_up_process: 'Folgeprozess nicht verfügbar',
            quality_issue: 'Qualitätsmangel',
            welder_adjustments: 'Einstellung Schweißanlage',
            repair_electrical: 'Reparatur Elektrik',
            preventive_maintenance: 'Präventive Wartung',
            break: 'Pause',
            machine_adjustments: 'Justage der Anlage',
            missing_order: 'Fehlender Auftrag',
            601: '601 - Drucker',
            602: '602 - Elektrode',
            603: '603 - Flanschelektrode',
            604: '604 - Platine',
            605: '605 - Längsschweißung',
            606: '606 - Querschweißung',
            607: '607 - Teller',
            608: '608 - Töpfe',
            609: '609 - Spender',
            610: '610 - Lichtschranke',
            611: '611 - Steuerung',
            612: '612 - Spreizung',
            613: '613 - Greifer',
            614: '614 - Glättung',
            615: '615 - Paddel',
            616: '616 - Säule',
            617: '617 - Sockel',
            618: '618 - Entnahme',
            619: '619 - Transport',
            620: '620 - Sicherheit',
            621: '621 - Stempel',
            622: '622 - Farbband',
            623: '623 - allgemein',
            624: '624 - Druckluft',
            401: '401 - Drucker',
            402: '402 - Elektrode',
            403: '403 - Flanschelektrode',
            404: '404 - Platine',
            405: '405 - Längsschweißung',
            406: '406 - Querschweißung',
            407: '407 - Teller',
            408: '408 - Töpfe',
            409: '409 - Spender',
            410: '410 - Lichtschranke',
            411: '411 - Steuerung',
            412: '412 - Spreizung',
            413: '413 - Greifer',
            414: '414 - Glättung',
            415: '415 - Paddel',
            416: '416 - Säule',
            417: '417 - Sockel',
            418: '418 - Entnahme',
            419: '419 - Transport',
            420: '420 - Stempel',
            421: '421 - Farbband',
            422: '422 - allgemein',
            423: '423 - Druckluft',
            301: '301 - Drucker',
            302: '302 - Elektrode',
            303: '303 - Flanschelektrode',
            304: '304 - Platine',
            305: '305 - Längsschweißung',
            306: '306 - Querschweißung',
            307: '307 - Teller',
            308: '308 - Töpfe',
            309: '309 - Spender',
            310: '310 - Lichtschranke',
            311: '311 - Steuerung',
            312: '312 - Spreizung',
            313: '313 - Greifer',
            314: '314 - Glättung',
            315: '315 - Paddel',
            316: '316 - Säule',
            317: '317 - Sockel',
            318: '318 - Entnahme',
            319: '319 - Transport',
            320: '320 - Sicherheit',
            321: '321 - Stempel',
            322: '322 - Farbband',
            323: '323 - allgemein',
            324: '324 - Druckluft',
            201: '201 - Drucker',
            202: '202 - Elektrode',
            203: '203 - Flanschelektrode',
            204: '204 - Platine',
            205: '205 - Längsschweißung',
            206: '206 - Querschweißung',
            207: '207 - Teller',
            208: '208 - Töpfe',
            209: '209 - Spender',
            210: '210 - Lichtschranke',
            211: '211 - Spreizung',
            212: '212 - Greifer',
            213: '213 - Glättung',
            214: '214 - Paddel',
            215: '215 - Säule',
            216: '216 - Sockel',
            217: '217 - Entnahme',
            218: '218 - Transport',
            219: '219 - Sicherheit',
            220: '220 - Stempel',
            221: '221 - Farbband',
            222: '222 - allgemein',
            223: '223 - Druckluft',
            101: '101 - Drucker',
            102: '102 - Elektrode',
            103: '103 - Flanschelektrode',
            104: '104 - Längsschweißung',
            105: '105 - Querschweißung',
            106: '106 - Spender',
            107: '107 - Lichtschranke',
            108: '108 - Steuerung',
            109: '109 - Spreizung',
            110: '110 - Greifer',
            111: '111 - Glättung',
            112: '112 - Paddel',
            113: '113 - Säule',
            114: '114 - Sockel',
            115: '115 - Entnahme',
            116: '116 - Transport',
            117: '117 - Sicherheit',
            not_defined: 'Unbestimmt',
            break_creep: 'Pausenüberzieher',
            not_defined_PO_presence: 'Unbestimmt',
            break_creep_PO_presence: 'Pausenüberzieher',
            micro_stop: 'Kurzunterbrechung'
          },

          scrapTypesText: {
            coil_change: 'Coilwechsel',
            defective_material: 'Materialfehler',
            setup: 'Einrichtung',
            quality_testing: 'Qualitätskontrolle',
          },
        };
    }
  };

  return {
    phrases,
  };
};
export {
  // eslint-disable-next-line import/prefer-default-export
  usePhrases,
};
