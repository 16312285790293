import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Zoom,
} from '@material-ui/core';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import uuid from 'uuid/v4';
import useIcon from '@frontend/hooks/useIcon';
import CustomLink from '@frontend/components/CustomLink';

const closedDrawerWidth = 56;

const useStyles = makeStyles(() => ({
  slidedown: {
    position: 'absolute',
    right: closedDrawerWidth,
    minWidth: '250px'
  },
  list: {
    padding: 0,
  },
}));

const Slidedown = (props) => {
  const classes = useStyles();
  const {
    close,
    open,
    data,
  } = props;
  const renderIcon = useIcon();

  return (
    <ClickAwayListener onClickAway={close}>
      <Zoom
        in={Boolean(open)}
        mountOnEnter
        unmountOnExit
        timeout={100}
      >
        <Paper
          style={{ top: 0 }}
          onMouseLeave={close}
          className={clsx(classes.slidedown)}
          elevation={5}
          square
        >
          <List component="nav" className={classes.list} aria-label="main mailbox folders">
            {data.map((item, index) => (
              <div key={uuid()}>
                <ListItem
                  button
                  component={CustomLink}
                  to={item.to}
                  object={item.object}
                >
                  {item.icon
                    ? (
                      <ListItemIcon>
                        {renderIcon(item.iconType, item.icon)}
                      </ListItemIcon>
                    ) : ''}
                  <ListItemText primary={item.text} />
                </ListItem>
              </div>
            ))}
          </List>
        </Paper>
      </Zoom>
    </ClickAwayListener>
  );
};

Slidedown.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func.isRequired,
  data: PropTypes.instanceOf(Array).isRequired,
};

export default Slidedown;
